// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

let autoComplete;

export const loadScript = (url, callback) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

export function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
  autoComplete.setFields(['address_components', 'formatted_address', 'geometry']);
  autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery));
}

export const handlePlaceSelect = async (updateQuery) => {
  const addressObject = autoComplete.getPlace();
  const coordinates = {
    lat: addressObject.geometry.location.lat(),
    lng: addressObject.geometry.location.lng(),
    address: addressObject.formatted_address,
  };
  updateQuery(coordinates);
};

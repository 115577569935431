import {INITIAL_STATE} from './initialState';
import {UtilsActionTypes} from 'store/types/utils';
import {UtilsState} from './declarations';

const utils = (state = INITIAL_STATE, {type, payload}): UtilsState => {
  switch (type) {
    case UtilsActionTypes.OPEN_MODAL: {
      return {
        ...state,
        modal: payload,
      };
    }
    case UtilsActionTypes.CLOSE_MODAL: {
      return {
        ...state,
        modal: INITIAL_STATE.modal,
      };
    }
    case UtilsActionTypes.OPEN_IMG_VIEWER: {
      return {
        ...state,
        imgViewer: payload,
      };
    }
    case UtilsActionTypes.CLOSE_IMG_VIEWER: {
      return {
        ...state,
        imgViewer: '',
      };
    }

    default:
      return state;
  }
};

export default utils;

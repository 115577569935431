import styled from 'styled-components';
import {AppColors} from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

export const ButtonTag = styled.button`
  font-family: Roboto, serif;
  font-weight: 300;
  background: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  border: 6px solid ${AppColors.grayLight};
  border-radius: 26px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${AppColors.white};
  padding: 8px 20px;
  cursor: pointer;
  display: flex;
`;

import { AppColors } from 'shared/constants/globalStyles';
import styled from 'styled-components';

export const InfoBlock = styled.div`
  display: flex;
  padding: 10px 20px;
  background: ${AppColors.fullDark};
  border-radius: 24px;
  position: absolute;
  transform: translate(-50%, 0);
  left: calc(50vw);
  bottom: 15px;
  margin: auto;
  a {
    text-decoration: none;
    :last-child {
      span {
        border-right: none;
      }
    }
  }
`;

export const LinkItem = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${AppColors.white};
  padding: 0 10px;
  border-right: 1px solid ${AppColors.gray};
  cursor: pointer;
`;

import { AppColors, AppMedia } from "shared/constants/globalStyles";
import styled from "styled-components";

export const LoaderContainer = styled.div`
  border: 7px solid ${AppColors.strokeWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${AppColors.fullDark};
  position: fixed;
  right: 28px;
  bottom: 64px;
  border-radius: 100%;
  cursor: pointer;
  @media (max-width: ${AppMedia.sm}) {
    right: 8px;
  }
`;

export const Loader = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoImg = styled.img`
  width: 40px;
  background-color: ${AppColors.fullDark};
  border: none;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
	position: absolute;
`;

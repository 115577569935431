import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ModalContainer } from "shared/components/Modal";
import { AppState } from "store/reducers/store";
import { NftType } from "store/types/nft";
import {
  Container,
  IconVector,
} from "./NftViewer.style";
import IconVectorPath from 'assets/images/icon-popup-vector.svg';
import { MapActions } from "store/actions/map";
import { NftActions } from "store/actions/nft";
import './style.css';
import NftViewerItem from "./NftViewerItem";
import { AppColors } from "shared/constants/globalStyles";

type NftViewerProps = {}

const NftViewer: FC<NftViewerProps> = ({ }: NftViewerProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedNftCluster: NftType[] = useSelector((
    store: AppState,
  ) => store.nft.selectedClusterOnMap as NftType[]);

  const onCloseNftViewer = () => {
    navigate('/');
    dispatch(NftActions.onSelectNftOnMap(undefined));
    dispatch(NftActions.onSelectClusterOnMap([]));
    dispatch(MapActions.onChangeNftAimState(false));
  }

  return (
    <Container>
      <ModalContainer
        style={{
          padding: '0 0 0 0',
          height: 'fit-content',
          width: '100%',
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: 'auto',
          background: AppColors.fullDark,
        }}
        onCloseModal={onCloseNftViewer}
      >
        <>
          {selectedNftCluster.map((item, index) => {
            return (
              <NftViewerItem
                key={item.nftId + '-nft-item-' + index}
                nftItem={item}
              />
            )
          })}
        </>
      </ModalContainer>
      <IconVector src={IconVectorPath} />
    </Container>
  )
}

export default NftViewer;

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonWrapper, IconVector, List, Text, IconMyNftList } from './Button.style';
import { NftActions } from 'store/actions/nft';
import IconVectorPath from 'assets/images/icon-popup-vector.svg';
import IconMyNftPath from 'assets/images/icon-my-nft.svg';
import { useHeaderTheme } from 'shared/hooks/useHeaderTheme';
import IconArrowDown from 'shared/Icons/IconArrowDown';
import { AppColors } from 'shared/constants/globalStyles';
import { ROUTES } from "router/routes";
import { useNavigate, useLocation } from "react-router";
import { AppState } from 'store/reducers/store';
import { useTranslation } from 'react-i18next';

type ButtonProps = {
  showPopup: boolean;
  onClick: () => void;
};

const Button: FC<ButtonProps> = ({ showPopup, onClick }: ButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLight } = useHeaderTheme();
  const { theme } = useSelector((state: AppState) => state.user);

  const onClickButton = () => {
    onClick();
    dispatch(NftActions.myNft());
    if (location.pathname !== ROUTES.main) navigate(ROUTES.main);
  };

  return (
    <ButtonWrapper isLight={isLight} showPopup={showPopup} style={showPopup ? { background: theme.mainColor } : {}} onClick={onClickButton}>
      <IconMyNftList src={IconMyNftPath} />
      <Text isLight={isLight} style={isLight && showPopup ? { color: AppColors.white } : {}}>
        {t('btn_list_my_nft')} <List>{t('main_bottom_navigation_list')}</List>{' '}
      </Text>
      <IconArrowDown
        style={showPopup ? { transform: 'rotate(180deg)', fill: `${AppColors.white}` } : { fill: `${theme.mainColor}` }}
      />
      {showPopup && <IconVector src={IconVectorPath} />}
    </ButtonWrapper>
  );
};

export default Button;

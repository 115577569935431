import {AppColors, AppMedia} from 'shared/constants/globalStyles';
import styled from 'styled-components';
import logo from 'assets/images/logo.png';
import { ThemeObjType } from 'store/types/user';

type PropsHeader = {isLight: boolean};

export const StyledHeader = styled.header.attrs((props: PropsHeader) => {
  isLight: props.isLight;
})`
  background: ${(props) =>
    props.isLight ? `${AppColors.white}` : 'linear-gradient(180deg, #000000 42.71%, rgba(0, 0, 0, 0) 100%)'};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 78px;
  display: flex;
  align-items: center;
  z-index: 101;
`;

export const Logo = styled.div`
  background: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  height: 43px;
  width: 80px;
  margin-left: 17px;
  @media (max-width: ${AppMedia.sm}) {
    margin-left: 10px;
  }
`;

export const AuthBlock = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 20px;
  @media (max-width: ${AppMedia.sm}) {
    margin-right: 18px;
  }
`;

export const UserBlock = styled.div`
  display: flex;
`;

export const Container = styled.div`
  display: flex;
`;
export const RegistrationContainer = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.15);
  border-radius: 30px;
`;

export const LoginButton = styled.button.attrs((props: PropsHeader) => {
  isLight: props.isLight;
})`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${({isLight}) => isLight ? `${(props: { theme: ThemeObjType}) => props.theme.mainColor}` : `${AppColors.white}`};
  background-color: transparent;
  border: none;
  margin-right: 27px;
  cursor: pointer;
`;

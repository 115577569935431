import {FC} from 'react';
import {
  ImgContainer,
  Img,
  ImgSubstrate,
  Container,
  NameContainer,
  Name,
  Alias,
  Icon,
  FlexContainer,
  ImgBorder,
  DefaultImg
} from './Buttton.style';
import {useSelector} from 'react-redux';
import {AppState} from 'store/reducers/store';
import {useHeaderTheme} from 'shared/hooks/useHeaderTheme';
import { IconArrowDown } from 'shared/Icons';
import defaultAvatar from 'assets/images/img-profile-default.svg';

type ButtonProps = {
  onClick: () => void;
};

const Button: FC<ButtonProps> = ({onClick}: ButtonProps) => {
  const user = useSelector((state: AppState) => state.user.user);
  const isOpen = useSelector((state: AppState) => state.utils.modal?.content);
  const { theme } = useSelector((state: AppState) => state.user);
  const {isLight} = useHeaderTheme();

  return (
    <Container onClick={onClick}>
      <ImgContainer>
        <ImgSubstrate isLight={isLight} />
        <ImgBorder isLight={isLight} />
        {user.imageUrl ? <Img isLight={isLight} src={user.imageUrl} /> : <DefaultImg src={defaultAvatar} />}
      </ImgContainer>
      <NameContainer>
        <FlexContainer>
          <Name>{user.name}</Name>
          <Icon>
            <IconArrowDown
              style={{ fill: theme.mainColor, transform: isOpen ? 'rotate(180deg)' : 'none' }}
            />
          </Icon>
        </FlexContainer>
        <Alias isLight={isLight}>@{user.login}</Alias>
      </NameContainer>
    </Container>
  );
};

export default Button;

import {FC, useEffect} from 'react';
import Button from './Button';
import Popup from './Popup/ProfilePopup';
import {useDispatch} from 'react-redux';
import {UserActions} from 'store/actions/user';
import {UtilsActions} from 'store/actions/utils';

const Profile: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserActions.getUser());
  }, []);

  const handleClickProfile = () => {
    dispatch(UtilsActions.openModal({content: <Popup />}));
  };

  return (
    <>
      <Button onClick={handleClickProfile} />
    </>
  );
};
export default Profile;

import {useEffect, MutableRefObject, useRef} from 'react';

export default function useOutsideClick<T extends Node>(
  ref: MutableRefObject<T> | T,
  callback: (e: MouseEvent | TouchEvent) => void,
  capture = false,
) {
  const cb = useRef(callback);
  cb.current = callback;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      const element: T = ref instanceof Node ? ref : ref.current;
      if (typeof cb.current === 'function' && element && !element.contains(event.target as T)) {
        if (event instanceof MouseEvent) {
          // handle only left mouse click
          if (event.button !== 0) return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const clickByImgViewer = event.target.closest('#img-viewer-container');
        if (clickByImgViewer) {
          return;
        }
        const clickByModal = (event.target as any).parentNode.closest('#modal');
        if (!clickByModal) {
          cb.current(event);
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside, {capture});
    document.addEventListener('touchstart', handleClickOutside, {capture});
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside, {capture});
      document.removeEventListener('touchstart', handleClickOutside, {capture});
    };
  }, [ref, capture]);
}

import { Release } from './ProfilePopup.style';
import Header from './Header';
import Wallet from './Wallet';
import PopupItem from './PopupItem';
import changePasswordIcon from 'assets/images/icon-change-password.svg';
import privacyIcon from 'assets/images/icon-privacy.svg';
import rateIcon from 'assets/images/icon-rate.svg';
import logoutIcon from 'assets/images/icon-logout.svg';
import appearanceIcon from 'assets/images/icon-appearance.svg';
import { useDispatch } from 'react-redux';
import { AuthActions } from 'store/actions/auth';
import StyledPopup from '../Popup/Popup';
import { UtilsActions } from "store/actions/utils";
import ChangePassword from "../ChangePassword";
import { useNavigate } from 'react-router';
import AddAccount from '../AddAccount';
import Appearance from '../Appearance';
import { useTranslation } from 'react-i18next';

const ProfilePopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(AuthActions.logout());
    dispatch(UtilsActions.closeModal());
  };

  const onChangePassword = () => {
    dispatch(UtilsActions.openModal({ content: <ChangePassword /> }));
  }

  const onPrivacy = () => {
    dispatch(UtilsActions.closeModal());
    navigate('/privacy');
  }

  const onAppearance = () => {
    dispatch(UtilsActions.openModal({ content: <Appearance /> }));
  }

  const handleClickAddAccount = () => {
    dispatch(UtilsActions.openModal({ content: <AddAccount /> }));
  }

  return (
    <StyledPopup>
      <>
        <Header />
        <Wallet />
        <PopupItem text={t('title_change_pass')} iconSrc={changePasswordIcon} onClick={onChangePassword} />
        <PopupItem text={t('btn_profile_appearance')} iconSrc={appearanceIcon} onClick={onAppearance} />
        <PopupItem text={t('btn_profile_privacy')} iconSrc={privacyIcon} onClick={onPrivacy} />
        <PopupItem text={t('btn_profile_add_account')} iconSrc={rateIcon} onClick={handleClickAddAccount} />
        <PopupItem text={t('btn_profile_log_out')} iconSrc={logoutIcon} onClick={onLogout} />
        <Release>release 1.0.0</Release>
      </>
    </StyledPopup>
  );
};

export default ProfilePopup;

import {INITIAL_STATE} from './initialState';
import {NftActionTypes} from 'store/types/nft';
import {NftState} from './declarations';

const nft = (state = INITIAL_STATE, {type, payload}): NftState => {
  switch (type) {
    case NftActionTypes.SEARCH_NFT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case NftActionTypes.SEARCH_NFT_SUCCESS: {
      return {
        ...state,
        nftList: payload,
        loading: false,
      };
    }
    case NftActionTypes.SEARCH_NFT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case NftActionTypes.MY_NFT_REQUEST: {
      return {
        ...state,
        myNftLoading: true,
      };
    }
    case NftActionTypes.MY_NFT_SUCCESS: {
      return {
        ...state,
        myNftList: payload,
        myNftLoading: false,
      };
    }
    case NftActionTypes.FIND_NFT_REQUEST: {
      return {
        ...state,
        find: {...state.find, ...payload, loading: true},
      };
    }
    case NftActionTypes.FIND_NFT_SUCCESS: {
      return {
        ...state,
        find: {...state.find, nftList: payload.list, found: payload.found, loading: false},
      };
    }
    case NftActionTypes.HIDE_NFT_REQUEST: {
      return {
        ...state,
        idNftSetHideLoading: payload.nftId,
      };
    }
    case NftActionTypes.HIDE_NFT_SUCCESS: {
      return {
        ...state,
        idNftSetHideLoading: '',
        myNftList: [...state.myNftList.map((nft) => (nft.nftId === payload.nftId ? payload : nft))],
      };
    }
    case NftActionTypes.HIDE_NFT_ERROR: {
      return {
        ...state,
        idNftSetHideLoading: '',
      };
    }
    case NftActionTypes.PUBLISH_NFT_REQUEST: {
      return {
        ...state,
        publishNftLoading: true,
      };
    }
    case NftActionTypes.PUBLISH_NFT_RESPONSE: {
      return {
        ...state,
        publishedNft: payload,
      };
    }
    case NftActionTypes.PUBLISH_NFT_SUCCESS: {
      return {
        ...state,
        myNftList: [
          ...state.myNftList.map((nft) => (nft.nftId === payload.nftId ? payload : nft)),
        ],
        publishNftLoading: false,
      };
    }
    case NftActionTypes.UPLOAD_FILE_REQUEST: {
      return {
        ...state,
        loadingFile: true,
      };
    }
    case NftActionTypes.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        lastUploadedFileData: payload,
        loadingFile: false,
      };
    }
    case NftActionTypes.UPLOAD_FILE_ERROR: {
      return {
        ...state,
        loadingFile: false,
      };
    }
    case NftActionTypes.CREATE_NFT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case NftActionTypes.CREATE_NFT_RESPONSE: {
      return {
        ...state,
        myNftList: [...state.myNftList.filter(nft => Number(nft.nftId) !== Number(payload.nftId)), payload],
        loading: false,
      };
    }
    case NftActionTypes.CREATE_NFT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case NftActionTypes.ON_SELECT_NFT_ON_MAP: {
      return {
        ...state,
        selectedNftOnMap: payload,
      };
    }
    case NftActionTypes.EDIT_NFT_REQUEST: {
      return {
        ...state,
        editLoading: true,
      };
    }
    case NftActionTypes.EDIT_NFT_SUCCESS: {
      return {
        ...state,
        myNftList: [...state.myNftList.filter(nft => Number(nft.nftId) !== Number(payload.nftId)), payload],
        editLoading: false,
      };
    }
    case NftActionTypes.EDIT_NFT_ERROR: {
      return {
        ...state,
        editLoading: false,
      };
    }
    case NftActionTypes.ON_SELECT_CLUSTER_ON_MAP: {
      return {
        ...state,
        selectedClusterOnMap: payload,
      };
    }
    case NftActionTypes.GET_NFT: {
      return {
        ...state,
        loading: true,
      };
    }
    case NftActionTypes.GET_NFT_RESPONSE: {
      return {
        ...state,
        loading: false,
      };
    }
    case NftActionTypes.GET_NFT_ERROR: {
      return {
        ...state,
        loading: false,
        errorLoadedNft: true,
      };
    }
    case NftActionTypes.RESET_ERROR_LOADED_NFT: {
      return {
        ...state,
        errorLoadedNft: false,
      };
    }

    default:
      return state;
  }
};

export default nft;

import { FC } from "react";
import { PageTitle } from "../CreateWallet/CreateWallet.style";
import Popup from "../Popup";
import { BackButtonRow } from "../Popup/Popup.style";
import {ReactComponent as BackButtonIcon} from 'assets/images/icon-popup-back.svg';
import { useDispatch, useSelector } from "react-redux";
import { UtilsActions } from "store/actions/utils";
import ProfilePopup from "../ProfilePopup";
import { AppState } from "store/reducers/store";
import { Border, ColorBorder, ColorCheckContainer, ColorItem, ColorsContainer, SubTitle } from "./Appearance.style";
import { UserActions } from "store/actions/user";
import {ReactComponent as IconCheck} from "assets/images/icon-check.svg";
import { ThemeObjType } from "store/types/user";
import { themeObgects } from "shared/theme/themes";
import { useTranslation } from 'react-i18next';

const Appearance: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { theme } = useSelector((state: AppState) => state.user);

  const handleClickBack = () => {
    dispatch(UtilsActions.openModal({content: <ProfilePopup />}));
  }

  const handleSelectMainColor = (theme: ThemeObjType) => {
    dispatch(UserActions.changeThemeColor(theme));
  }

  return (
    <Popup>
      <BackButtonRow onClick={handleClickBack}>
        <BackButtonIcon />
      </BackButtonRow>
      <PageTitle>{t('txt_appearance_title')}</PageTitle>
      <SubTitle>{t('txt_profile_color_map_marker_type')}</SubTitle>
      <ColorsContainer>
        {themeObgects.map((item, index) => {
          const isSelected = theme.mainColor === item.theme.mainColor ? true : false;
          return (
            <ColorBorder
              key={`${item.name}-${index}`}
              color={item.theme.mainColor}
              isSelected={isSelected}
              onClick={() => handleSelectMainColor(item.theme)}
            >
              <ColorItem color={item.color} />
              {isSelected ? <ColorCheckContainer><IconCheck /></ColorCheckContainer> : <div />}
            </ColorBorder>
          )
        })}
      </ColorsContainer>
      <Border />
    </Popup>
  )
}

export default Appearance;

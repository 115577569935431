import {AuthActionTypes, LoginRequestType, SignupRequestType, SignupErrorType} from 'store/types/auth';
import {Action, createAction, ActionsUnion} from 'store/helpers/redux';

export const AuthActions = {
  signup: (payload: SignupRequestType): Action<AuthActionTypes.SIGNUP_REQUEST, SignupRequestType> =>
    createAction(AuthActionTypes.SIGNUP_REQUEST, payload),
  signupError: (payload: SignupErrorType): Action<AuthActionTypes.SIGNUP_ERROR, SignupErrorType> =>
    createAction(AuthActionTypes.SIGNUP_ERROR, payload),
  login: (payload: LoginRequestType): Action<AuthActionTypes.LOGIN_REQUEST, LoginRequestType> =>
    createAction(AuthActionTypes.LOGIN_REQUEST, payload),
  logout: (): Action<AuthActionTypes.LOGOUT, LoginRequestType> =>
    createAction(AuthActionTypes.LOGOUT),
  setIsAuth: (payload: boolean): Action<AuthActionTypes.SET_IS_AUTH, boolean> =>
    createAction(AuthActionTypes.SET_IS_AUTH, payload),
};

export type AuthActionsUnion = ActionsUnion<typeof AuthActions>;

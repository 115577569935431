import { redTheme } from "shared/theme/themes";

const userAccounts = JSON.parse(localStorage.getItem('users'));
const defaultTheme = JSON.parse(localStorage.getItem('appTheme'));

export const INITIAL_STATE = {
  user: {
    email: '',
    name: '',
    imageUrl: '',
    login: '',
    id: 0,
  },
  nameLoading: false,
  changePasswordLoading: false,
  changePasswordSuccess: false,
  avatarLoading: false,
  userLoading: false,
  userAccounts: userAccounts || [],
  theme: defaultTheme || redTheme,
};

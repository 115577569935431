import {INITIAL_STATE} from './initialState';
import {MapState} from './declarations';
import { MapActionTypes } from 'store/types/map';

const map = (state = INITIAL_STATE, action): MapState => {
  switch (action.type) {
    case MapActionTypes.ON_LOAD_MAP: {
      return {
        ...state,
        map: action.payload.map,
        maps: action.payload.maps,
      };
    }
    case MapActionTypes.ON_CHANGE_MAP_ZOOM: {
      return {
        ...state,
        zoom: action.payload.zoom,
      };
    }
    case MapActionTypes.ON_CHANGE_COORDINATES: {
      return {
        ...state,
        topLeftLat: action.payload.topLeftLat,
        topLeftLng: action.payload.topLeftLng,
        botRightLat: action.payload.botRightLat,
        botRightLng: action.payload.botRightLng,
      };
    }
    case MapActionTypes.ON_CHANGE_NFT_AIM_STATE: {
      return {
        ...state,
        isNftAimState: action.payload,
      };
    }
    case MapActionTypes.ON_CHANGE_MAP_CENTER: {
      return {
        ...state,
        center: action.payload,
      };
    }
    case MapActionTypes.ON_CHANGE_MAP_TYPE: {
      return {
        ...state,
        selectedMapType: action.payload,
      };
    }
    case MapActionTypes.ADD_MAP_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case MapActionTypes.DELETE_MAP_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    default:
      return state;
  }
};

export default map;

import {useLocation} from 'react-router';
import {ROUTES} from 'router/routes';
import {HeaderTheme} from 'shared/constants/constants';

export const useHeaderTheme = () => {
  const location = useLocation();
  const {search, terms} = ROUTES;

  const isLight = [search, terms].includes(location.pathname);
  const isDark = !isLight;
  return {isLight, isDark}

};

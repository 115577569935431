import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

type PropsType = {
  isLight: boolean;
  showPopup: boolean;
};

export const ButtonWrapper = styled.button.attrs((props: PropsType) => {
  isLight: props.isLight;
  showPopup: props.showPopup;
})`
  display: flex;
  align-items: center;
  background: ${({isLight}) => (isLight ? `${AppColors.grayLight}` : `${AppColors.fullDark}`)};
  border: ${({isLight}) => (isLight ? 'none' : `1px solid ${AppColors.textDefault}`)};
  box-sizing: border-box;
  border-radius: 25px;
  padding: 12px 15px;
  position: absolute;
  top: 19px;
  right: 265px;
  cursor: pointer;
  height: 40px;
  :hover {
    border: 1px solid ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  }
  :hover > span {
    color: ${({showPopup}) => showPopup ? AppColors.white : (props: { theme: ThemeObjType}) => props.theme.mainColor};
  }

  @media (max-width: ${AppMedia.md}) {
    right: 95px;
  }
`;

export const Text = styled.span.attrs((props: PropsType) => {
  isLight: props.isLight;
})`
  font-size: 14px;
  line-height: 16px;
  margin: 0 30px 0 10px;
  color: ${AppColors.white};
  @media (max-width: ${AppMedia.md}) {
    margin: 0 10px 0 10px;
  }
`;

export const List = styled.span`
  @media (max-width: ${AppMedia.md}) {
    display: none;
  }
`;

export const IconMyNftList = styled.img`
  width: 16px;
  height: 16px;
  stroke: ${AppColors.textDefault};
`;

export const IconArrowDown = styled.img.attrs((props: PropsType) => {
  isLight: props.isLight;
})`
  fill: ${({isLight}) => (isLight ? `${AppColors.textDefault}` : `${(props: { theme: ThemeObjType}) => props.theme.mainColor}`)};
  width: 8px;
  height: 8px;
`;

export const IconVector = styled.img`
  width: 12px;
  height: 8px;
  position: absolute;
  top: 48px;
  right: 78px;
  @media (max-width: ${AppMedia.md}) {
    right: 56px;
  }
`;

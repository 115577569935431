import {CSSProperties, FC} from 'react';
import {AppColors} from '../constants/globalStyles';

type IconArrowDownProps = {
  fill?: string;
  stroke?: string;
  isLight: boolean;
  style?: CSSProperties;
};

const IconMyNftList: FC<IconArrowDownProps> = ({
  fill,
  stroke,
  isLight,
  style,
}: IconArrowDownProps) => {
  console.log(stroke,' stroke')
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9473 6.68408H6.89463"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9473 10.4736H4.36832"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 9C1 13.4211 4.57895 17 9 17C13.4211 17 17 13.4211 17 9C17 4.57895 13.4211 1 9 1"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMyNftList;

import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {RoutesMap} from 'router';
import configureStore from './store';
import Header from 'modules/Header';
import {Modal} from './shared/components/Modal';
import {NotificationContainer} from 'react-notifications';
import ImgViewer from 'shared/components/ImgViewModal';
import 'react-notifications/lib/notifications.css';
import AppThemeProvider from 'shared/theme/AppThemeProvider';
import './shared/constants/customNotify.css';
import 'shared/i18n/config';

const storeState = configureStore({});


const App = () => {


  return (
    <BrowserRouter>
      <Provider store={storeState}>
        <AppThemeProvider>
          <>
            <Header />
            <RoutesMap />
            <Modal />
            <ImgViewer />
          </>
        </AppThemeProvider>
      </Provider>
      <NotificationContainer />
    </BrowserRouter>
  );
};
export default App;

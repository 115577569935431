import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';

type PropsType = {
  isLight: boolean;
};

export const Container = styled.div.attrs((props: PropsType) => {
  isLight: props.isLight;
})`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 370px;
  padding: 0 15px;
  background-color: ${({isLight}) => (isLight ? `${AppColors.grayLight}` : `${AppColors.white}`)};
  border-radius: 30px;
  box-sizing: border-box;
  margin-left: 20px;
  margin-right: 5px;
  z-index: 2;
  @media(max-width: ${AppMedia.md}) {
    position: absolute;
    transform: translateX(-50%);
    margin: 0;
    left: 50%;
    top: 83px;
    width: 364px;
  }
`;

export const MarkerIcon = styled.img``;

export const CustomInput = styled.input.attrs((props: PropsType) => {
  isLight: props.isLight;
})`
  background-color: ${({isLight}) => (isLight ? `${AppColors.grayLight}` : `${AppColors.white}`)};
  width: 300px;
  height: 40px;
  max-height: 40px;
  border: none;
  padding: 2px 2px 2px 15px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.darkGrey};
  :focus-visible {
    outline: none;
  }
`;

export const SearchIcon = styled.img`
  cursor: pointer;
`;

export const CloseIcon = styled.img`
  width: 12px;
  height: 12px;
  padding-left: 4px;
  cursor: pointer;
`;
export const Substrate = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: ${AppColors.white};
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.5s;
`;

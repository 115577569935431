import { FC, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapActions } from 'store/actions/map';
import { NftActions } from 'store/actions/nft';
import { UtilsActions } from 'store/actions/utils';
import { AppState } from 'store/reducers/store';
import { NftType } from 'store/types/nft';
import Map from './Map';
import CreateNft from 'modules/CreateNft';
import NftViewer from 'modules/NftViewer';
import { useNavigate } from 'react-router';
import { ROUTES } from 'router/routes';

const MapContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mapRef = useRef < HTMLDivElement > (null);
  const aimRef = useRef < HTMLDivElement > (null);

  const [mergedNftList, setMergedNftList] = useState < NftType[] > ([]);

  const { isAuth } = useSelector((state: AppState) => state.auth);
  const {
    topLeftLat, topLeftLng, botRightLat, botRightLng, zoom, isNftAimState, center, selectedMapType,
  } = useSelector((store: AppState) => store.map);
  const nftList: NftType[] = useSelector((store: AppState) => store.nft.nftList as NftType[]);
  const myNftList: NftType[] = useSelector((store: AppState) => store.nft.myNftList as NftType[]);
  const selectedClusterOnMap: NftType[] = useSelector((store: AppState) => store.nft.selectedClusterOnMap as NftType[]);
  const isDisableScroll: boolean = useSelector((store: AppState) =>
    store.nft.selectedNftOnMap || store.nft.selectedClusterOnMap.length ? true : false
  );

  useEffect(() => {
    dispatch(NftActions.searchNft({ topLeftLat, topLeftLng, botRightLat, botRightLng }));
  }, [topLeftLat, topLeftLng, botRightLat, botRightLng])

  useEffect(() => {
    if (!myNftList.length && isAuth) {
      dispatch(NftActions.myNft());
    }
  }, [center])

  useEffect(() => {
    const mergedArray = [...Array.from(new Set([...nftList, ...myNftList]))];
    setMergedNftList(mergedArray);
  }, [nftList, myNftList, setMergedNftList, isAuth])

  useEffect(() => {
    if (selectedClusterOnMap.length === 1) {
      dispatch(MapActions.onChangeNftAimState(true));
      const { lat, lng } = selectedClusterOnMap[0];
      const marker = new google.maps.Marker({
        position: { lat: Number(lat), lng: Number(lng) },
        map: mapRef.current as any,
        title: "",
        icon: "",
      });
      const overlay = new google.maps.OverlayView();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      overlay.draw = function () { };
      overlay.setMap(mapRef?.current as any);

      const proj = overlay.getProjection();
      const pos = marker.getPosition();
      const markerPosition = proj?.fromLatLngToContainerPixel(pos);
      const { x, y } = (aimRef?.current as any).getBoundingClientRect();
      (mapRef.current as any)?.panBy(markerPosition.x - x, markerPosition.y - y);
      marker.setMap(null);
    }
  }, [selectedClusterOnMap])

  const onChange = (e: { zoom: number, bounds: any, center: { lat: number, lng: number } }) => {
    dispatch(MapActions.onChangeMapCenter(e.center));
    dispatch(MapActions.onChangeMapZoom({ zoom: e.zoom }));
    dispatch(MapActions.onChangeCoordinates({
      topLeftLat: e.bounds.nw.lat,
      topLeftLng: e.bounds.nw.lng,
      botRightLat: e.bounds.se.lat,
      botRightLng: e.bounds.se.lng,
    }));
  }

  const onChangeZoom = (zoom: number) => {
    dispatch(MapActions.onChangeMapZoom({ zoom: zoom }));
  }

  const onChangeMapCenter = (lat: number, lng: number) => {
    dispatch(MapActions.onChangeMapCenter({ lat: lat, lng: lng }));
  }

  const handleAddNft = () => {
    dispatch(UtilsActions.openModal({ content: <CreateNft /> }));
    dispatch(MapActions.onChangeNftAimState(true));
  }

  const handleClickNft = (nftId: number) => {
    const searchedNft: NftType = mergedNftList.find((item) => item.nftId.toString() === nftId.toString());
    dispatch(NftActions.onSelectClusterOnMap([searchedNft]));
    dispatch(UtilsActions.openModal({ content: <NftViewer /> }));
    dispatch(MapActions.onChangeNftAimState(true));
  }

  const handleClickMapLoader = () => {
    navigate(ROUTES.presentation);
  }

  return (
    <Map
      isAuth={isAuth}
      mapRef={mapRef}
      aimRef={aimRef}
      onChange={onChange}
      onChangeMapZoom={onChangeZoom}
      nftList={mergedNftList}
      bounds={[topLeftLng, botRightLat, botRightLng, topLeftLat]}
      zoom={zoom}
      center={center}
      selectedMapType={selectedMapType}
      handleAddNft={handleAddNft}
      isNftAimState={isNftAimState}
      isDisableScroll={isDisableScroll}
      handleClickNft={handleClickNft}
      onChangeMapCenter={onChangeMapCenter}
      handleClickMapLoader={handleClickMapLoader}
    />
  )
}

export default MapContainer;

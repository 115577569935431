import {call, put, select, takeLatest} from 'redux-saga/effects';
import {UserActionTypes, GetUserResponse, UserData} from '../types/user';
import {UserActions, UserActionsUnion} from '../actions/user';
import {PickAction} from '../helpers/redux';
import {createNotification, Notification} from 'shared/components/Notify';
import {TOKEN} from 'shared/constants/constants';
import {AppState} from "../reducers/store";
import { WalletActions } from 'store/actions/wallet';
import adapter from 'store/helpers/adapter';

function* getUserSaga(action: PickAction<UserActionsUnion, UserActionTypes.GET_USER_REQUEST>): Generator {
  const token = localStorage.getItem(TOKEN);
  try {
    const response = yield call(
      adapter,
      'https://api.geonftapp.com/api/v1/users/me',
      {method: 'get', withHeaders: true}
    );

    const {data} = response as GetUserResponse;
    data.token = token;
    const currentAccounts = JSON.parse(localStorage.getItem('users')) as UserData[] || undefined;
    if (!currentAccounts || !currentAccounts.find((item) => Number(item.id) === data.id)) {
      localStorage.setItem('users', JSON.stringify(currentAccounts ? [...currentAccounts, data] : [data]));
    }
    yield put(UserActions.setUser(data));
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* setUserToken(action: PickAction<UserActionsUnion, UserActionTypes.GET_USER_SUCCESS>): Generator {
  const {payload} = action;
  localStorage.setItem(TOKEN, payload.token);
  yield put(WalletActions.getWallets());
}

function* renameUserSaga(action: PickAction<UserActionsUnion, UserActionTypes.RENAME_USER_REQUEST>): Generator {
  const {payload} = action;
  const {email, imageUrl} = (yield select((state: AppState) => state.user.user)) as UserData;
  try {
    const response = yield call(
      adapter,
      'https://api.geonftapp.com/api/v1/users/profile',
      {method: 'put', withHeaders: true},
      {name: payload.name, email, imageUrl}
    );

    const {data} = response as {data: {message: string}};
    yield put(UserActions.renameUserSuccess({name: payload.name, email, imageUrl}));
    createNotification(Notification.success, data.message);
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* changePasswordSaga(action: PickAction<UserActionsUnion, UserActionTypes.RENAME_USER_REQUEST>): Generator {
  const {payload} = action;
  try {
    yield call(
      adapter,
      'https://api.geonftapp.com/api/v1/users/password',
      {method: 'put', withHeaders: true},
      payload
    );

    yield put(UserActions.changePasswordSuccess());
    createNotification(Notification.success, 'Password updated');
  } catch (err) {
    yield put({type: UserActionTypes.CHANGE_PASSWORD_ERROR});
    createNotification(Notification.error, err.message);
  }
}

function* uploadAvatarSaga(action: PickAction<UserActionsUnion, UserActionTypes.UPLOAD_AVATAR_REQUEST>): Generator {
  const {payload} = action;
  const {email, name} = (yield select((state: AppState) => state.user.user)) as UserData;
  const formData = new FormData();
  formData.append('file', payload);
  try {
    const response = yield call(
      adapter,
      'https://api.geonftapp.com/api/v1/files/streetartnft',
      {method: 'post', withHeaders: true},
      formData
    );

    const {
      data: {fullName},
    } = response as {data: {fullName: string}};
    const imageUrl = `https://api.geonftapp.com/api/v1/files/${fullName}`;

    yield call(
      adapter,
      'https://api.geonftapp.com/api/v1/users/profile',
      {method: 'put', withHeaders: true},
      {name, email, imageUrl}
    );

    yield put(UserActions.uploadAvatarSuccess({imageUrl}));
    createNotification(Notification.success, 'Avatar uploaded!');
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* changeTheme(action: PickAction<UserActionsUnion, UserActionTypes.CHANGE_THEME_COLOR>): Generator {
  const {payload} = action;
  localStorage.setItem('appTheme', JSON.stringify(payload));
}

function* watch(): Generator {
  yield takeLatest(UserActionTypes.GET_USER_REQUEST, getUserSaga);
  yield takeLatest(UserActionTypes.RENAME_USER_REQUEST, renameUserSaga);
  yield takeLatest(UserActionTypes.UPLOAD_AVATAR_REQUEST, uploadAvatarSaga);
  yield takeLatest(UserActionTypes.CHANGE_PASSWORD_REQUEST, changePasswordSaga);
  yield takeLatest(UserActionTypes.GET_USER_SUCCESS, setUserToken);
  yield takeLatest(UserActionTypes.CHANGE_THEME_COLOR, changeTheme);
}

export default watch;

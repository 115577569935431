import {FC, useEffect} from 'react';
import {AuthBlock, LoginButton, Logo, RegistrationContainer, StyledHeader, UserBlock} from './Header.style';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'shared/components';
import {AppState} from 'store/reducers/store';
import {UtilsActions} from 'store/actions/utils';
import Greeting from '../Greeting';
import Login from '../Login';
import Profile from '../Profile';
import MyNFT from '../MyNFT';
import SearchInput from '../SearchInput';
import {useHeaderTheme} from 'shared/hooks/useHeaderTheme';
import { Link } from 'react-router-dom';
import DownloadApp from "../DownloadApp";

const Header: FC = () => {
  const dispatch = useDispatch();
  const {isAuth} = useSelector((state: AppState) => state.auth);
  const {isLight} = useHeaderTheme();

  const onLogin = () => {
    dispatch(UtilsActions.openModal({content: <Login />}));
  };

  const onGreeting = () => {
    dispatch(UtilsActions.openModal({content: <Greeting />}));
  };

  return (
    <StyledHeader isLight={isLight}>
      <Link to="/">
        <Logo />
      </Link>
      <SearchInput />
      {isAuth ? (
        <UserBlock>
          <MyNFT />
          <Profile />
        </UserBlock>
      ) : (
        <AuthBlock>
          <LoginButton isLight={isLight} onClick={onLogin}>Log In</LoginButton>
          <RegistrationContainer>
            <Button text="Registration" onClick={onGreeting} style={{border: 'none', fontWeight: 400}} />
          </RegistrationContainer>
        </AuthBlock>
      )}
      <DownloadApp />
    </StyledHeader>
  );
};

export default Header;

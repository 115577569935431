import { FC } from "react";
import {NavLink} from 'react-router-dom';
import {
  Description,
  Button,
  ButtonIcon,
  ButtonText,
  FooterDescription,
  TextLink,
  Border,
  HaveAccountText,
  Link,
  AuthLink,
  GreetingContainer
} from './Greeting.style';
import iconBody from 'assets/images/social/icon-phone.svg';
import iconGoogle from 'assets/images/social/icon-google.svg';
import iconVk from 'assets/images/social/icon-vk.svg';
import iconFacebook from 'assets/images/social/icon-facebook.svg';
import iconInstagram from 'assets/images/social/icon-instagram.svg';
import iconGithub from 'assets/images/social/icon-github.png';
import {UtilsActions} from 'store/actions/utils';
import Signup from '../Signup';
import {useDispatch} from 'react-redux';
import Login from '../Login';
import { useTranslation } from 'react-i18next';

const GreetingContent: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onLogin = () => {
    dispatch(UtilsActions.openModal({content: <Login />}));
  };

  const onSignup = () => {
    dispatch(UtilsActions.openModal({content: <Signup />}));
  };
  return (
    <GreetingContainer>

      <Description>
        {t('txt_registration_create_a_profile')}
      </Description>
      <Button onClick={onSignup}>
        <ButtonIcon src={iconBody} />
        <ButtonText>{t('btn_registration_email_phone')}</ButtonText>
      </Button>
      <AuthLink
        href={`${process.env.REACT_APP_GOOGLE_AUTH_URL}?redirect_uri=${process.env.REACT_APP_OAUTH2_REDIRECT_URI}`}
      >
        <Button>
          <ButtonIcon src={iconGoogle} />
          <ButtonText>{t('btn_registration_google')}</ButtonText>
        </Button>
      </AuthLink>
      {/* <AuthLink
        href={`${process.env.REACT_APP_VK_AUTH_URL}?redirect_uri=${process.env.REACT_APP_OAUTH2_REDIRECT_URI}`}
      >
        <Button>
          <ButtonIcon src={iconVk} />
          <ButtonText>Continue with VK</ButtonText>
        </Button>
      </AuthLink> */}
      <AuthLink
        href={`${process.env.REACT_APP_FACEBOOK_AUTH_URL}?redirect_uri=${process.env.REACT_APP_OAUTH2_REDIRECT_URI}`}
      >
        <Button>
          <ButtonIcon src={iconFacebook} />
          <ButtonText>{t('btn_registration_facebook')}</ButtonText>
        </Button>
      </AuthLink>
      <AuthLink
        href={`${process.env.REACT_APP_GITHUB_AUTH_URL}?redirect_uri=${process.env.REACT_APP_OAUTH2_REDIRECT_URI}`}
      >
        <Button>
          <ButtonIcon src={iconGithub} />
          <ButtonText>{t('btn_registration_github')}</ButtonText>
        </Button>
      </AuthLink>
      <AuthLink
        href={`${process.env.REACT_APP_INSTAGRAM_AUTH_URL}?redirect_uri=${process.env.REACT_APP_OAUTH2_REDIRECT_URI}`}
      >
        <Button>
          <ButtonIcon src={iconInstagram} />
          <ButtonText>{t('btn_registration_instagram')}</ButtonText>
        </Button>
      </AuthLink>
      <FooterDescription>
        {t('txt_registration_description_1')}
        <NavLink to={'#'} style={{textDecoration: 'none'}}>
          <TextLink>&nbsp;{t('txt_registration_description_2')}&nbsp;</TextLink>
        </NavLink>
        {t('txt_registration_description_3')}
        <NavLink to={'#'} style={{textDecoration: 'none'}}>
          <TextLink>&nbsp;{t('txt_registration_description_4')}</TextLink>
        </NavLink>
        {t('txt_registration_description_5')}
      </FooterDescription>
      <Border />
      <HaveAccountText>
        <Link onClick={onLogin}>&nbsp;{t('txt_auth_already_have_account')}</Link>
      </HaveAccountText>
    </GreetingContainer>
  )
}

export default GreetingContent;

import GreetingContent from "modules/Greeting/GreetingContent"
import { FC } from "react"
import {ReactComponent as BackButtonIcon} from 'assets/images/icon-popup-back.svg';
import Popup from "../Popup"
import { BackButtonRow } from "../Popup/Popup.style"
import { useDispatch } from "react-redux";
import { UtilsActions } from "store/actions/utils";
import AddAccount from ".";

const AddAccountModal: FC = () => {
  const dispatch = useDispatch();

  const handleClickBack = () => {
    dispatch(UtilsActions.openModal({content: <AddAccount />}));
  }

  return (
    <Popup>
      <BackButtonRow onClick={handleClickBack}>
        <BackButtonIcon />
      </BackButtonRow>
      <GreetingContent />
    </Popup>
  )
}

export default AddAccountModal;

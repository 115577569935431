import { AppColors } from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

export const redTheme: ThemeObjType = {
  mainColor: AppColors.red,
}
export const pinkTheme = {
  mainColor: AppColors.pink,
}
export const violettTheme = {
  mainColor: AppColors.violett,
}
export const cyanTheme = {
  mainColor: AppColors.cyan,
}
export const blueTheme = {
  mainColor: AppColors.blue,
}
export const lightBlueTheme = {
  mainColor: AppColors.lightBlue,
}
export const greenTheme = {
  mainColor: AppColors.green,
}
export const lightGreenTheme = {
  mainColor: AppColors.lightGreen,
}
export const yellowTheme = {
  mainColor: AppColors.yellow,
}
export const orangeTheme = {
  mainColor: AppColors.orange,
}


export const themeObgects = [{
  name: "red",
  theme: redTheme,
  color: AppColors.red,
},{
  name: "pink",
  theme: pinkTheme,
  color: AppColors.pink,
},{
  name: "violett",
  theme: violettTheme,
  color: AppColors.violett,
},{
  name: "cyan",
  theme: cyanTheme,
  color: AppColors.cyan,
},{
  name: "blue",
  theme: blueTheme,
  color: AppColors.blue,
},{
  name: "lightBlue",
  theme: lightBlueTheme,
  color: AppColors.lightBlue,
},{
  name: "green",
  theme: greenTheme,
  color: AppColors.green,
},{
  name: "lightGreen",
  theme: lightGreenTheme,
  color: AppColors.lightGreen,
},{
  name: "yellow",
  theme: yellowTheme,
  color: AppColors.yellow,
},{
  name: "orange",
  theme: orangeTheme,
  color: AppColors.orange,
}];

import { AppColors } from 'shared/constants/globalStyles';
import styled from 'styled-components';

export const PageTitle = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${AppColors.textDefault};
  margin: 20px 0 22px 0;
  text-align: center;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  line-height: 3;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  max-width: 70%;
  margin-top: 22px;
`;

import { Carousel } from 'react-responsive-carousel';
import { FC, useState } from 'react';
import { imagesMap } from './imagesMap';
import { PresentationContainer, PresentationContainerMobile } from './Presentation.style';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SlideItem from './SlideItem';
import { Document, Page, pdfjs } from 'react-pdf';
import Loading from 'shared/components/Loading';

const Presentation = () => {
  const windowInnerWidth = window.innerWidth;
  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(0);
  const [loadedImgIndexes, setLoadedImgIndexes] = useState([]);

  const addLoadedNumber = (value) => {
    setLoadedImgIndexes([...loadedImgIndexes, value]);
  }

  if (windowInnerWidth < 600) {
    return <PresentationContainerMobile>
      <Document
        file={'/NFTStreet.pdf'}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {Array.from({ length: numPages }).map((_, index) => (
          <Page
            key={`${index}-page`}
            pageNumber={index}
            width={windowInnerWidth}
            loading={<Loading
              style={{
                // position: 'absolute',
                // left: 'calc(50% - 40px',
                // top: '50%',
                zIndex: 1,
              }}
            />}
          />
        ))}
      </Document>
    </PresentationContainerMobile>
  } else {
    return (
      <PresentationContainer className="carousel-wrapper">
        <Carousel
          axis='horizontal'
          autoFocus={true}
          infiniteLoop={true}
          autoPlay={loadedImgIndexes.length >= imagesMap.length ? true : false}
          interval={5000}
        >
          {imagesMap.map((item, index) =>
            <div key={`image-${index}`}>
              <SlideItem
                item={item}
                currentImageIndex={index}
                setLoadedImgIndexes={addLoadedNumber}
              />
            </div>
          )}
        </Carousel>
      </PresentationContainer>
    )
  }
}

export default Presentation;

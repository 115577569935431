import styled from 'styled-components';
import {AppColors} from 'shared/constants/globalStyles';

export const MainContainer = styled.div`
  top: 15px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  display: flex;
  justify-content: center;
	position: absolute;
  padding: 45px 20px 20px;
	z-index: 10;
`;

export const Container = styled.div`
  display: flex;
	flex-direction: column;
	text-align: center;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.textDefault};
`;

export const DownloadImg = styled.img`
	width: 150px;
`;
export const DownloadButton = styled.button`
	background-color: transparent;
	border: none;
	padding: 0;
`;

export const LinkContainer = styled.div`
	display: flex;
`

import {INITIAL_STATE} from './initialState';
import {UserActionTypes} from 'store/types/user';
import {UserState} from './declarations';

const user = (state = INITIAL_STATE, {type, payload}): UserState => {
  switch (type) {
    case UserActionTypes.GET_USER_REQUEST: {
      return {
        ...state,
        userLoading: true,
      };
    }
    case UserActionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        user: payload,
        userAccounts: [payload, ...state.userAccounts.filter((item) => item.id !== payload.id)],
        userLoading: false,
      };
    }
    case UserActionTypes.RENAME_USER_REQUEST: {
      return {
        ...state,
        nameLoading: true,
      };
    }
    case UserActionTypes.RENAME_USER_SUCCESS: {
      return {
        ...state,
        user: {...state.user, ...payload},
        nameLoading: false,
      };
    }
    case UserActionTypes.UPLOAD_AVATAR_REQUEST: {
      return {
        ...state,
        avatarLoading: true,
      };
    }
    case UserActionTypes.UPLOAD_AVATAR_SUCCESS: {
      return {
        ...state,
        user: {...state.user, ...payload},
        avatarLoading: false,
      };
    }
    case UserActionTypes.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        changePasswordSuccess: false,
        changePasswordLoading: true,
      };
    }
    case UserActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordSuccess: true,
        changePasswordLoading: false,
      };
    }
    case UserActionTypes.CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        changePasswordSuccess: false,
        changePasswordLoading: false,
      };
    }
    case UserActionTypes.USER_INFO_DELETE: {
      return {
        ...state,
        user: {
          email: '',
          name: '',
          imageUrl: '',
          login: '',
          id: 0,
        },
        userAccounts: [],
      };
    }
    case UserActionTypes.CHANGE_THEME_COLOR: {
      return {
        ...state,
        theme: payload,
      };
    }

    default:
      return state;
  }
};

export default user;

import { AppColors } from "shared/constants/globalStyles";
import { ThemeObjType } from "store/types/user";
import styled from "styled-components";


export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorCode = styled.span`
  font-size: 78px;
  text-align: center;
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  margin: 32px auto;
`;

export const Text = styled.span`
  font-size: 24px;
  text-align: center;
  color: ${AppColors.textDefault};
  margin: 0 0 32px 0;
`;

import { FC, useEffect, useState } from "react";
import FileUploader from "modules/CreateNft/FileUploader";
import Input from "shared/components/Input";
import Label from "shared/components/Label";
import { ModalContainer } from "shared/components/Modal";
import Loading from 'shared/components/Loading';
import { AddPhotoButton, AddPhotoButtonIcon, BuyNowButton, Description, CreateNftModalContainer, InputContainer, PreviewNft, PreviewNftContainer, NftImage, Row, Col } from "./CreateNft.style";
import { useDispatch, useSelector } from "react-redux";
import { NftActions } from "store/actions/nft";
import { AppState } from "store/reducers/store";
import { CreateNftRequestType } from "store/types/nft";
import Address from "./Address";
import { MapActions } from "store/actions/map";
import { useTranslation } from 'react-i18next';

type CreateNftProps = {
  selectedNftId?: number;
};

const CreateNft: FC<CreateNftProps> = ({ selectedNftId }: CreateNftProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    topLeftLat, topLeftLng, botRightLat, botRightLng,
  } = useSelector((store: AppState) => store.map);

  const {
    lastUploadedFileData, loadingFile, selectedNftOnMap: selectedNft
  } = useSelector((store: AppState) => store.nft);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');


  useEffect(() => {
    if (selectedNftId) {
      setName(selectedNft.name);
      setDescription(selectedNft.description);
    }
  }, [setName, setDescription, selectedNftId, selectedNft])

  const handleClickBuyNowNft = () => {
    if (selectedNftId) {
      const editedNft = {
        nftId: selectedNftId,
        lat: Number(selectedNft.lat),
        lng: Number(selectedNft.lng),
        fileUrl: lastUploadedFileData.fullName ? `https://api.geonftapp.com/api/v1/files/${lastUploadedFileData.fullName}` : selectedNft?.fileUrl,
        fileHash: lastUploadedFileData.hash ? lastUploadedFileData.hash : "",
        fileIpfs: "",
        name: name,
        description: description,
        attributes: "",
        blockchain: "ETH",
        userId: null,
        userAvatar: "",
        publishTime: "",
      };
      dispatch(NftActions.createNft({...editedNft} as CreateNftRequestType));
    } else {
      const createdNft = {
        lat: topLeftLat + (botRightLat - topLeftLat) / 3,
        lng: topLeftLng + (botRightLng - topLeftLng) / 2,
        fileUrl: `https://api.geonftapp.com/api/v1/files/${lastUploadedFileData.fullName}`,
        fileHash:lastUploadedFileData.hash,
        fileIpfs: "",
        name: name,
        description: description,
        attributes: "",
        blockchain: "ETH",
        userId: null,
        userAvatar: "",
        publishTime: "",
      }
      dispatch(NftActions.createNft({...createdNft} as CreateNftRequestType));
    }
  }

  const onCloseAddNftModal = () => {
    dispatch(NftActions.onSelectNftOnMap(undefined));
    dispatch(NftActions.uploadFileResponse({
      bucket: '',
      fullName: '',
      hash: '',
      name: '',
    }));
    dispatch(MapActions.onChangeNftAimState(false));
  }

  return (
    <CreateNftModalContainer>
      <ModalContainer
        isIconClose
        isCloseOutsideClick={false}
        onCloseModal={onCloseAddNftModal}
        style={{ width: '100%', margin: 'auto' }}
      >
        <Row>
          <Col>
            <Address
              lat={topLeftLat + (botRightLat - topLeftLat) / 3}
              lng={topLeftLng + (botRightLng - topLeftLng) / 2}
            />
            <PreviewNftContainer>
              <PreviewNft>
                <NftImage src={
                  lastUploadedFileData.fullName
                    ? `https://api.geonftapp.com/api/v1/files/${lastUploadedFileData.fullName}`
                    : selectedNft?.fileUrl
                } />
                {loadingFile
                  ? <Loading style={{ position: 'absolute', zIndex: 10 }}/>
                  : <FileUploader isOpacity={lastUploadedFileData.fullName || selectedNft?.fileUrl ? true : false}>
                    <AddPhotoButton><AddPhotoButtonIcon />{t('btn_map_add_nft')}</AddPhotoButton>
                  </FileUploader>
                }
              </PreviewNft>
            </PreviewNftContainer>
          </Col>

          <Col>
            <InputContainer>
              <Label>{t('txt_add_nft_name')}</Label>
              <Input onChange={(e) => setName(e.target.value)} value={name} />
            </InputContainer>

            <InputContainer>
              <Label>{t('txt_add_nft_description')}</Label>
              <Description onChange={(e) => setDescription(e.target.value)} value={description} />
            </InputContainer>

            <BuyNowButton
              text={selectedNftId ? t('btn_add_nft_update') : t('btn_add_nft_save')}
              style={{ margin: '12px auto 0 auto', fontWeight: 500 }}
              onClick={handleClickBuyNowNft}
              disabled={!name || !description || !lastUploadedFileData.fullName && !selectedNft?.fileUrl}
            />
          </Col>

        </Row>
      </ModalContainer>
    </CreateNftModalContainer>
  )
}

export default CreateNft;

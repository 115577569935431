export const INITIAL_STATE = {
  loading: false,
  find: {
    loading: false,
    nftList: [],
    text: '',
    limit: 12,
    offset: 0,
    found: 0,
  },
  editLoading: false,
  myNftLoading: false,
  idNftSetHideLoading: '',
  publishNftLoading: false,
  myNftList: [],
  nftList: [],
  lastUploadedFileData: {
    bucket: '',
    fullName: '',
    hash: '',
    name: '',
  },
  loadingFile: false,
  selectedNftOnMap: undefined,
  selectedClusterOnMap: [],
  errorLoadedNft: false,
  publishedNft: null,
};

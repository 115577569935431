import { FC } from 'react';
import {
  NftContainer,
  Container,
  NftImg,
  TitleContainer,
  Description,
  Title,
  Address,
  Coordinates,
  Flex,
  Border,
  LoadingContainer,
  AttentionMiniIcon,
  PublishTime,
  Time,
  ButtonContainer,
  Button,
  Icon,
  LoupeIcon,
  EditIcon,
} from './NFTItem.style';
import AttentionMiniIconPath from 'assets/images/icon-attention-mini.svg';
import HideNftIconPath from 'assets/images/icon-hide-nft.svg';
import LupeIconPath from 'assets/images/icon-loupe.svg';
import EditIconPath from 'assets/images/icon-edit.svg';
import { NftType } from 'store/types/nft';
import { AppColors } from 'shared/constants/globalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { NftActions } from 'store/actions/nft';
import { AppState } from 'store/reducers/store';
import Loading from 'shared/components/Loading';
import dayjs from 'dayjs';
import { UtilsActions } from 'store/actions/utils';
import EditNft from '../EditNft';
import NftViewer from '../../NftViewer';
import { MapActions } from 'store/actions/map';
import { ROUTES } from "router/routes";
import { useNavigate, useLocation } from "react-router";
import { WalletPlatforms } from 'shared/constants/constants';
import MnemonicPhraseModal from 'modules/MnemonicPhraseModal';
import { WalletActions } from 'store/actions/wallet';
import { useTranslation } from 'react-i18next';


type NFTItemProps = {
  nft: NftType;
  onClose: () => void;
};

const FORMAT_PUBLISH_TIME = 'DD MMM YYYY hh:mm a';

const NFTItem: FC<NFTItemProps> = ({ nft, onClose }: NFTItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const idLoadingNft = useSelector((state: AppState) => state.nft.idNftSetHideLoading);
  const walletId = useSelector((state: AppState) => state.wallet.activeWalletId);
  const mnemonic = useSelector((state: AppState) => state.wallet.mnemonic);
  const activeWallet = useSelector((state: AppState) =>
    state.wallet.wallets.find((item) => item.id === state.wallet.activeWalletId)
  )
  const { theme } = useSelector((state: AppState) => state.user);

  const onEdit = () => {
    dispatch(UtilsActions.openModal({ content: <EditNft nft={nft} /> }));
  };

  const onHide = () => {
    dispatch(NftActions.setHideNft({ ...nft, isHide: true }));
  };

  const onShow = () => {
    dispatch(NftActions.setHideNft({ ...nft, isHide: false }));
  };

  const onPublish = async () => {
    const PublishWithLocalWallet = () => {
      dispatch(NftActions.publishNft({
        nftId: nft.nftId,
        walletId,
        isLocalWallet: true,
        mnemonic: mnemonic
      }));
      dispatch(UtilsActions.closeModal());
      dispatch(WalletActions.deleteMnemonic());
    }

    if (activeWallet.platform === WalletPlatforms.local) {
      dispatch(UtilsActions.openModal({ content: <MnemonicPhraseModal onOk={PublishWithLocalWallet} /> }))
    } else {
      dispatch(NftActions.publishNft({
        nftId: nft.nftId,
        walletId,
        isLocalWallet: false,
      }));
    }
  };

  const onClickLupe = () => {
    dispatch(UtilsActions.openImgViewer(nft.fileUrl));
  };

  const formatDate = (time: string) => {
    return dayjs(time).format(FORMAT_PUBLISH_TIME);
  };

  const onGoToNft = () => {
    onClose();
    if (location.pathname !== ROUTES.main) {
      navigate(ROUTES.main);
    }
    dispatch(NftActions.onSelectClusterOnMap([nft]));
    dispatch(UtilsActions.openModal({ content: <NftViewer /> }));
    dispatch(MapActions.onChangeNftAimState(true));
  };

  return (
    <NftContainer>
      <Container>
        <LoadingContainer>
          <NftImg src={nft.fileUrl} style={nft.isHide || !nft?.publishTime ? { filter: 'grayscale(1)' } : {}} />
          <LoupeIcon src={LupeIconPath} onClick={onClickLupe} />
        </LoadingContainer>
        <TitleContainer>
          <Flex>
            <Title>{nft.name}</Title>
            {!nft.publishTime && <EditIcon src={EditIconPath} onClick={onEdit} />}
          </Flex>
          {/* <Address>London Baker street 221b</Address> */}
          <Flex>
            <Coordinates>
              {nft.lat}, {nft.lng}
            </Coordinates>
          </Flex>
          {!nft.isHide && nft.publishTime ? (
            <PublishTime>
              <AttentionMiniIcon src={AttentionMiniIconPath} />
              Publish time:&nbsp;<Time>{formatDate(nft.publishTime)}</Time>
            </PublishTime>
          ) : (
            <PublishTime />
          )}
          <Flex>
            <ButtonContainer>
              {idLoadingNft === nft.nftId ? (
                <div style={{ width: 124 }}>
                  <Loading style={{ transform: 'scale(.4)' }} />
                </div>
              ) : (
                <>
                  {nft.isHide && (
                    <Button onClick={onShow}>
                      <Icon src={HideNftIconPath} />
                      {t('btn_web_my_nft_show')}
                    </Button>
                  )}
                  {!nft.isHide && nft.publishTime && (
                    <Button onClick={onHide}>
                      <Icon src={HideNftIconPath} />
                      {t('btn_web_my_nft_hide')}
                    </Button>
                  )}
                  {!nft.isHide && !nft?.publishTime && (
                    <Button style={{ backgroundColor: theme.mainColor, color: AppColors.white }} onClick={onPublish}>
                      {t('txt_nft_publish_title')}
                    </Button>
                  )}
                </>
              )}
              <Button onClick={onGoToNft}>
                <Icon src={HideNftIconPath} />
                {t('btn_web_my_nft_go_to_map')}
              </Button>
            </ButtonContainer>
          </Flex>
        </TitleContainer>
      </Container>
      <Description>{nft.description}</Description>
      <Border />
    </NftContainer>
  );
};
export default NFTItem;

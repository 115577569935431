import Input from "shared/components/Input";
import { AppColors, AppMedia, AppShadows } from "shared/constants/globalStyles";
import { ThemeObjType } from "store/types/user";
import styled from "styled-components";

export const StyledModal = styled.div`
  border-radius: 20px;
  border: 1px solid ${AppColors.gray};
  width: auto;
  max-width: 400px;
  background: ${AppColors.white};
  box-shadow: ${AppShadows.darkShadow};
  @media (max-width: ${AppMedia.sm}) {
    width: calc(100vw - 10px);
    right: calc(-100vw + 10px);
  }
`;

export const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-top: 34px;
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
`;

export const WalletName = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.textDefault};
`;

export const StyledInput = styled(Input)`
  width: 100%;
  margin-top: 12px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
`;

import { AppBorders, AppColors, AppMedia, AppShadows } from 'shared/constants/globalStyles';
import styled from 'styled-components';
import IconMapPlus from 'assets/images/icon-map-plus.svg';
import { ThemeObjType } from 'store/types/user';


interface MarkerOwnerAvatarProps {
  readonly fileUrl?: string;
}

type MarkerImgProps = {
  isMonochrome?: boolean;
}

type ClusterCircleProps = {
  width?: number;
}

export const MapContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const ClusterContainer = styled.div`
  background: ${AppColors.fullDark};
  box-shadow: ${AppShadows.darkShadow};
  border-radius: 10px;
  padding: 4px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  font-size: 15px;
  line-height: 18px;
  color: ${AppColors.white};
  transform: translate(calc(-50% + 6px), calc(-100% - 8px));
  z-index: 5;
`;

export const ClusterSubContainer = styled.div`
  height: 29px;
`;

export const PreviewMarkerImg = styled.img<MarkerImgProps>`
  background-size: contain;
  background-position: center;
  width: 36px;
  height: 27px;
  border-radius: 4px;
  margin: 1px;
  z-index: 1;
  -webkit-filter: ${props => props.isMonochrome ? 'grayscale(100%)' : 'none'}; /* Safari 6.0 - 9.0 */
  filter: ${props => props.isMonochrome ? 'grayscale(100%)' : 'none'};
`;

export const ClusterCounts = styled.span`
  margin: auto 9px;
  font-family: RobotoMedium;
`;

export const ClusterPoint = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  border-radius: 14px;
`;

export const ClusterCircleOne = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor}66;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClusterCircleTwo = styled.div<ClusterCircleProps>`
  width: ${props => props.width ? props.width : 120}px;
  height: ${props => props.width ? props.width : 120}px;
  background-color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor}26;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClusterCircleThree = styled.div`
  background-color: none;
  padding: 6px;
  border: 1px solid ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  border-radius: 100%;
  position: absolute;
  transform: translate(calc(-50% + 6px), calc(-50% - 37px));
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MarkerContainer = styled.div`
  background: ${AppColors.fullDark};
  min-width: 79px;
  border-radius: 6px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  transform: translate(calc(-50% + 6px), calc(-100% - 8px));
  z-index: 3;
`;

export const MarkerImg = styled.img<MarkerImgProps>`
  position: relative;
  background-size: contain;
  background-position: center;
  height: 52px;
  border-radius: 4px;
  box-shadow: ${AppShadows.darkShadow};
  z-index: 2;
  -webkit-filter: ${props => props.isMonochrome ? 'grayscale(100%)' : 'none'}; /* Safari 6.0 - 9.0 */
  filter: ${props => props.isMonochrome ? 'grayscale(100%)' : 'none'};
  max-width: 100% !important;
`;

export const MarkerName = styled.span`
  position: absolute;
  bottom: 0;
  right: -10px;
  color: ${AppColors.white};
  background: ${AppColors.fullDark};
  border-radius: 4px;
  padding: 3px 5px;
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 3;
`;

export const MarkerOwnerAvatar = styled.div<MarkerOwnerAvatarProps>`
  position: absolute;
  top: -5px;
  right: -10px;
  background: url(${props => props.fileUrl});
  background-size: contain;
  border: 2px solid ${AppColors.fullDark};
  border-radius: 14px;
  width: 26px;
  height: 26px;
  z-index: 3;
  & svg {
    fill: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  }
`;

export const MarkerOwnerAvatarDefaultWrap = styled.div<MarkerOwnerAvatarProps>`
  position: absolute;
  top: -5px;
  right: -10px;
  background: #fff;
  border: 2px solid ${AppColors.fullDark};
  border-radius: 14px;
  width: 26px;
  height: 26px;
  z-index: 3;
`;

export const MarkerOwnerAvatarDefault = styled.img<MarkerOwnerAvatarProps>`
  border-radius: 14px;
  width: 18px;
  height: 18px;
  z-index: 3;
  padding: 4px 0 0 4px;
`;

export const AddNftButtonContainer = styled.div`
  position: absolute;
  bottom: 64px;
  left: calc(50vw - 67px);
  border: ${AppBorders.strokeWhite};
  border-radius: 30px;
`;

export const AddNftButtonIcon = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${IconMapPlus});
  width: 14px;
  height: 14px;
  margin: auto 12px auto 0;
`;

export const MarkerArrow = styled.img`
  width: 12px;
  height: 8px;
  position: absolute;
  top: -8px;
  z-index: 1;
`;

export const NftAim = styled.div`
  width: 1px;
  height: 1px;
  border-radius: 10px;
  position: absolute;
  top: calc(100vh / 3);
  left: 50vw;
  @media (max-width: ${AppMedia.sm}) {
		top: calc(100vh / 3);
  }
`;

export const VerticalLine = styled.div`
  width: 1px;
  position: absolute;
  top: calc(-100vh / 3);
  left: 4px;
  height: 100vh;
  border-left: 1px dashed ${AppColors.gray};
  z-index: 2;
  @media (max-width: ${AppMedia.sm}) {
		top: calc(-100vh / 3);
  }
`;

export const HorisontalLine = styled.div`
  width: 100vw;
  position: absolute;
  left: -50vw;
  height: 1px;
  border-bottom: 1px dashed ${AppColors.gray};
  z-index: 2;
`;

export const NftAimPoint = styled.div`
  width: 11px;
  height: 11px;
  background: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  border-radius: 10px;
  transform: translate(-1px, calc(-50% + 2px));
  position: absolute;
  z-index: 5;
`;

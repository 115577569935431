import img0001 from 'assets/images/presentation/NFTStreet_page-0001.jpg';
import img0002 from 'assets/images/presentation/NFTStreet_page-0002.jpg';
import img0003 from 'assets/images/presentation/NFTStreet_page-0003.jpg';
import img0004 from 'assets/images/presentation/NFTStreet_page-0004.jpg';
import img0005 from 'assets/images/presentation/NFTStreet_page-0005.jpg';
import img0006 from 'assets/images/presentation/NFTStreet_page-0006.jpg';
import img0007 from 'assets/images/presentation/NFTStreet_page-0007.jpg';
import img0008 from 'assets/images/presentation/NFTStreet_page-0008.jpg';
import img0009 from 'assets/images/presentation/NFTStreet_page-0009.jpg';
import img0010 from 'assets/images/presentation/NFTStreet_page-0010.jpg';
import img0011 from 'assets/images/presentation/NFTStreet_page-0011.jpg';
import img0012 from 'assets/images/presentation/NFTStreet_page-0012.jpg';
import img0013 from 'assets/images/presentation/NFTStreet_page-0013.jpg';
import img0014 from 'assets/images/presentation/NFTStreet_page-0014.jpg';
import img0015 from 'assets/images/presentation/NFTStreet_page-0015.jpg';

export const imagesMap = [
  img0001,
  img0002,
  img0003,
  img0004,
  img0005,
  img0006,
  img0007,
  img0008,
  img0009,
  img0010,
  img0011,
  img0012,
  img0013,
  img0014,
  img0015,
];

import {FC} from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContainer } from 'shared/components/Modal';
import { GreetingContainer, Title, TitleBold } from './Greeting.style';
import GreetingContent from './GreetingContent';

type GreetingProps = {};

const Greeting: FC<GreetingProps> = () => {
  const { t } = useTranslation();

  return (
    <ModalContainer isIconClose>
      <>
        <GreetingContainer>
          <Title>
            {t('txt_registration_title_hello')}&nbsp;<TitleBold>{t('txt_registration_title_greeting_world')}</TitleBold>
          </Title>
        </GreetingContainer>
        <GreetingContent />
      </>

    </ModalContainer>
  );
};

export default Greeting;

import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ModalContainer } from "shared/components/Modal";
import { MapActions } from "store/actions/map";
import { NftActions } from "store/actions/nft";
import { Container } from "../NftViewer.style";
import { Col, ErrorCode, Text } from "./NotResponseNft.style";

const NotResponseNft: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCloseNftViewer = () => {
    dispatch(NftActions.onSelectNftOnMap(undefined));
    dispatch(NftActions.onSelectClusterOnMap([]));
    dispatch(MapActions.onChangeNftAimState(false));
    dispatch(NftActions.resetErrorLoadedNft());
    navigate('/');
  }


  return (
    <Container>
      <ModalContainer
        isIconClose
        style={{ padding: '13px 14px 10px 14px' }}
        onCloseModal={onCloseNftViewer}
      >
        <Col>
          <ErrorCode>404</ErrorCode>
          <Text>
            No NFT with this ID was found :(
          </Text>
        </Col>
      </ModalContainer>
    </Container>
  )
}

export default NotResponseNft;

import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';

export const NftContainer = styled.div`
  position: relative;
  width: 315px;
  height: 134px;
  margin: 0 30px 15px 0;
	cursor: pointer;
  @media (max-width: ${AppMedia.sm}) {
    margin: 0 0 15px 0;
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 3px;
  background: ${AppColors.grayLight};
  border-radius: 3px;
  margin: 15px 0 0 0;
`;

export const Container = styled.div`
  display: flex;
`;
export const LoadingContainer = styled.div`
  position: relative;
  width: 127px;
  height: 78px;
  background: #f0f0f0;
  border-radius: 10px;
`;

export const NftImg = styled.img`
  width: 123px;
  height: 74px;
  border-radius: 10px;
  padding: 2px;
  object-fit: cover;
  position: relative;
  z-index: 2;
`;

export const LoupeIcon = styled.img`
  width: 52px;
  height: 52px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 3;
	cursor: pointer;
`;

export const TitleContainer = styled.div`
  margin-left: 8px;
`;

export const Title = styled.h3`
	width: 156px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: ${AppColors.darkGrey};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Address = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: ${AppColors.textDefault};
  margin: 15px 0 5px 0;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Coordinates = styled.p`
  width: 130px;
  overflow: hidden;
  font-size: 13px;
  line-height: 15px;
  color: ${AppColors.darkGrey};
  margin: 0 0 0 0;
  white-space: nowrap;
`;

export const Description = styled.p`
	display: flex;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 15px;
  color: ${AppColors.textDefault};
  margin: 10px 20px 0 0;
`;

export const EllipsisIcon = styled.img`
  margin-left: 10px;
`;

export const ArrowRightIcon = styled.img`
  position: absolute;
  top: 45%;
  right: 0;
  @media (max-width: ${AppMedia.sm}) {
    right: -5px;
  }
`;

import {CSSProperties, FC} from 'react';

type IconArrowDownProps = {
  fill?: string;
  stroke?: string;
  isLight?: boolean;
  style?: CSSProperties;
};

const IconLikeButton: FC<IconArrowDownProps> = ({
  fill,
  stroke,
  isLight,
  style,
}: IconArrowDownProps) => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.7007 2.67995C18.4913 3.56934 18.9674 4.73722 18.9674 6.02189C18.9674 12.3105 13.146 16.0208 10.5407 16.9191C10.2353 17.027 9.73216 17.027 9.42672 16.9191C6.82144 16.0208 1 12.3105 1 6.02189C1 3.24593 3.23694 1 5.99494 1C7.62998 1 9.07635 1.79057 9.98371 3.01235C10.8911 1.79057 12.3464 1 13.9725 1" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default IconLikeButton;

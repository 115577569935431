import { AppColors } from 'shared/constants/globalStyles';
import styled from 'styled-components';

const Label = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.textDefault};
  margin-left: 20px;
  margin-bottom: 2px;
`;

export default Label;

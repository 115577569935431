export enum MapActionTypes {
  ON_LOAD_MAP = 'ON_LOAD_MAP',
  ON_CHANGE_MAP_ZOOM = 'ON_CHANGE_MAP_ZOOM',
  ON_CHANGE_COORDINATES = 'ON_CHANGE_COORDINATES',
  ON_CHANGE_NFT_AIM_STATE = 'ON_CHANGE_NFT_AIM_STATE',
  ON_CHANGE_MAP_CENTER = 'ON_CHANGE_MAP_CENTER',
  ON_CHANGE_MAP_TYPE = 'ON_CHANGE_MAP_TYPE',
  ADD_MAP_LOADER = 'ADD_MAP_LOADER',
  DELETE_MAP_LOADER = 'DELETE_MAP_LOADER'
}
export type OnLoadMap = {
  map: any;
  maps: any;
}

export type OnChangeMapZoom = {
  zoom: number;
};

export type OnChangeCoordinates = {
  topLeftLat: number;
  topLeftLng: number;
  botRightLat: number;
  botRightLng: number;
};

export type OnChangeMapCenter = {
  lat: number;
  lng: number;
}

import { MapActionTypes } from 'store/types/map';
import {takeLatest} from 'redux-saga/effects';
import {} from './../types/map';
import {PickAction} from '../helpers/redux';
import { MapActionsUnion } from 'store/actions/map';

// need add type for action
function* onChangeMapZoom(action: PickAction<MapActionsUnion, MapActionTypes.ON_CHANGE_MAP_ZOOM>): Generator {
  const {payload} = action;
  localStorage.setItem('map_zoom', payload.zoom.toString());
}

function* onChangeMapCoordinates(action: PickAction<MapActionsUnion, MapActionTypes.ON_CHANGE_COORDINATES>): Generator {
  const {payload} = action;
  localStorage.setItem('map_coordinates_topLeftLat', payload.topLeftLat.toString());
  localStorage.setItem('map_coordinates_topLeftLng', payload.topLeftLng.toString());
  localStorage.setItem('map_coordinates_botRightLat', payload.botRightLat.toString());
  localStorage.setItem('map_coordinates_botRightLng', payload.botRightLng.toString());
}

function* onChangeMapCenter(action: PickAction<MapActionsUnion, MapActionTypes.ON_CHANGE_MAP_CENTER>): Generator {
  const {payload} = action;
  localStorage.setItem('map_coordinates_center_lat', payload.lat.toString());
  localStorage.setItem('map_coordinates_center_lng', payload.lng.toString());
}

function* watch(): Generator {
  yield takeLatest(MapActionTypes.ON_CHANGE_MAP_ZOOM, onChangeMapZoom);
  yield takeLatest(MapActionTypes.ON_CHANGE_COORDINATES, onChangeMapCoordinates);
  yield takeLatest(MapActionTypes.ON_CHANGE_MAP_CENTER, onChangeMapCenter);
}

export default watch;

// import './style.css';
import {CSSProperties, FC} from 'react';
import { LoadingSpinner } from './Loading.style';

type LoadingProps = {
  style?: CSSProperties;
};

const Loading: FC<LoadingProps> = ({style}: LoadingProps) => {
  return (
    <LoadingSpinner className="lds-spinner" style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoadingSpinner>
  );
};
export default Loading;

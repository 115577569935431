import {INITIAL_STATE} from './initialState';
import {AuthActionTypes} from 'store/types/auth';
import {AuthState} from './declarations';

const auth = (state = INITIAL_STATE, {type, payload}): AuthState => {
  switch (type) {
    case AuthActionTypes.SIGNUP_REQUEST: {
      return {
        ...state,
        signup: {message: ''},
        loading: true,
      };
    }
    case AuthActionTypes.SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.SIGNUP_ERROR: {
      return {
        ...state,
        signup: {message: payload.message},
        loading: false,
      };
    }
    case AuthActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.LOGIN_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthActionTypes.SET_IS_AUTH: {
      return {
        ...state,
        isAuth: payload,
      };
    }
    case AuthActionTypes.LOGOUT: {
      return {
        ...state,
        isAuth: false,
      };
    }
    default:
      return state;
  }
};

export default auth;

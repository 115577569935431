import { FC } from 'react';
import MapContainer from './Map/MapContainer';
import Nft from './Nft';

const Main: FC = () => {
  return (
    <>
      <MapContainer />
      <Nft />
    </>

  );
};

export default Main;

import { AppColors, AppMedia } from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${AppColors.white};
  margin-top: 87px;
  padding: 0 30px 30px 30px;
  max-height: calc(100vh - 87px);
  overflow: auto;
	@media(max-width: ${AppMedia.md}) {
    margin-top: 138px;
    padding: 0 25px 30px 25px;
		text-align: center;
    max-height: calc(100vh - 137px);
  }
  @media(max-width: ${AppMedia.sm}) {
    padding: 0 16px 30px 16px;
		text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 28px;
  font-weight: 400;
  line-height: 33px;
  color: ${AppColors.gray};
  margin-bottom: 15px;
`;

export const TitleBold = styled.p`
  margin: 0;
  font-size: 28px;
  font-weight: 500;
  color: ${AppColors.darkGrey};
  margin-bottom: 15px;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.textDefault};
  text-align: left;
  margin: 0;
`;

export const BoldText = styled(Text)`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background: ${AppColors.grayLight};
  margin: 31px 0 5px 0;
`;

export const ListItem = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 4px solid ${AppColors.grayLight};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: RobotoMedium;
  font-size: 15px;
  line-height: 18px;
  color: ${AppColors.textDefault};
  margin: 10px 10px 6px 0;
`;

export const StyledList = styled.ul`
  margin-top: 30px;
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  font-size: 14px;
  line-height: 16px;
`;

export const StyledListItem = styled.li`
  margin-bottom: 17px;
  width: fit-content;
`;

import { FC, useState } from "react";
import LikeButton from "./LikeButton";

type LikeButtonContainerProps = {

}

const LikeButtonContainer: FC<LikeButtonContainerProps> = ({ }: LikeButtonContainerProps) => {
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [likedCount, setLikedCount] = useState<number>(0);

  const handleClickLikeButton = () => {
    if (isLiked) {
      setLikedCount(likedCount - 1);
    } else {
      setLikedCount(likedCount + 1);
    }
    setIsLiked(!isLiked);
  }

  return (
    <LikeButton
      handleClickLikeButton={handleClickLikeButton}
      isLiked={isLiked}
      likedCount={likedCount}
    />
  )
}

export default LikeButtonContainer;

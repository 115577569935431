import styled from 'styled-components';
import {AppColors} from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

export const Title = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${AppColors.textDefault};
  text-align: center;
  margin: 0 0 20px 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.textDefault};
  margin-left: 20px;
  margin-bottom: 2px;
`;

export const Input = styled.input`
  background-color: ${AppColors.grayLight};
  border-radius: 30px;
  height: 48px;
  border: none;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 14px;
  box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
`;

export const Footer = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${AppColors.darkGrey};
  margin: 60px 0 0 0;
`;

export const TextLink = styled.p`
  > a {
    text-decoration: none;
    font-weight: 400;
    color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  }
`;

import styled from 'styled-components';
import MarkerIconSvg from 'assets/images/icon-marker.svg';
import { AppColors, AppMedia } from 'shared/constants/globalStyles';
import iconShare from 'assets/images/icon-share.svg';
import iconEdit from 'assets/images/icon-edit.svg';
import { ThemeObjType } from 'store/types/user';

interface ButtonProps {
  readonly variant?: string;
}

interface UserAvatarProps {
  readonly fileUrl: string;
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  overflow: auto;
  @media (max-width: ${AppMedia.sm}) {
    max-height: 100%;
		overflow-y: auto;
  }
`;

export const Row = styled.div`
  display: flex;
  @media (max-width: ${AppMedia.sm}) {
    flex-wrap: wrap;
  }
`;

export const UserInfoRow = styled.div`
  display: flex;
  @media (max-width: ${AppMedia.sm}) {
    border-bottom: 1px solid ${AppColors.gray};
    margin-bottom: 22px;
    padding-bottom: 22px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 5px;
  @media (max-width: ${AppMedia.sm}) {
    width: 100%;
  }
`;

export const NftViewerRelativeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  display: flex;
  background-color: ${AppColors.fullDark};
  max-height: 100vh;
  max-width: 100vw;
  img {
    border-radius: 20px;
  }
  :first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  :last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 175px;
  max-width: 800px;
  margin: auto auto 0 auto;
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 100%;
  background: linear-gradient(0deg, #000000 -20%, rgba(0, 0, 0, 0) 100%);
  @media (max-width: ${AppMedia.sm}) {
    width: 100%;
    max-width: calc(100vw - 50px);
  }
`;

export const MarkerIcon = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${MarkerIconSvg});
  width: 11px;
  height: 16px;
  margin-right: -8px;
`;

export const ViewerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 198px;
  border-radius: 10px;
  background-color: ${AppColors.grayLight};
  padding: 4px;
  margin: 6px 0 5px -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const NftImage = styled.img`
  object-fit: contain;
  z-index: 2;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 100vw;
  width: -webkit-fill-available;
`;

export const HelperText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: ${AppColors.white};
  max-height: 375px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${AppMedia.sm}) {
    height: auto;
  }
`;

export const ButtonsRow = styled.div`
  display: flex;
  margin: 0 -3px 12px -3px;
  @media (max-width: ${AppMedia.sm}) {
    padding-bottom: 12px;
  }
`;

export const StyledButton = styled.button<ButtonProps>`
  margin: 0 3px;
  width: calc(100% - 3px);
  font-family: Roboto, serif;
  font-weight: 400;
  background: ${props => props.variant === 'outlined' ? AppColors.grayLight : (props: { theme: ThemeObjType}) => props.theme.mainColor};
  border-radius: 26px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${props => props.variant === 'outlined' ? AppColors.darkGrey : AppColors.white};
  padding: 8px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: none;
`;

export const TooltipText = styled.span`

`;

const ButtonIcon = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background: none;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ShareIcon = styled(ButtonIcon)`
  background-image: url(${iconShare});
`;

export const EditIcon = styled(ButtonIcon)`
  background-image: url(${iconEdit});
`;

export const UserAvatar = styled.div<UserAvatarProps>`
  width: 38px;
  height: 38px;
  border-radius: 38px;
  background: url(${props => props.fileUrl});
  background-size: contain;
  border: 4px solid ${AppColors.grayLight};
`;

export const UserInfoCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const UserName = styled.h4`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  margin: 3px 0 5px 0;
`;

export const BuyDate = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.darkGrey};
  & .light-BuyDate {
    color: ${AppColors.textDefault};
  }
`;

export const NftNameCol = styled.div`
  display: flex;
  flex-direction: column;
  margin: 17px 0 10px 0;
  max-width: 100%;
`;

export const NftName = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: start;
  color: ${AppColors.white};
  margin: 0 0 9px 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconVector = styled.img`
  width: 12px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 50%;
`;

export const LoupeIcon = styled.div`
  width: 52px;
  height: 52px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
  z-index: 3;
`;

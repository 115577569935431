import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

type ThemeProps = {isLight: boolean};

export const Container = styled.div`
  font-family: Roboto, serif;
  display: flex;
  position: absolute;
  right: 20px;
  top: 12px;
  cursor: pointer;
  @media (max-width: ${AppMedia.md}) {
    right: 10px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const NameContainer = styled.div``;

export const Name = styled.div`
  width: 149px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  margin-top: 12px;
  margin-left: 6px;
  @media (max-width: ${AppMedia.md}) {
    display: none;
  }
`;

export const Icon = styled.div`
  width: 7px;
  height: 6px;
  padding: 10px 10px 5px 5px;
  @media (max-width: ${AppMedia.md}) {
    margin: 6px 0 0 0;
    padding-right: 10px;
  }
`;

export const Alias = styled.p.attrs((props: ThemeProps) => {
  isLight: props.isLight;
})`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({isLight}) => (isLight ? `${AppColors.textDefault}` : `${AppColors.grayLight}`)};
  margin-top: 2px;
  margin-left: 6px;
  text-overflow: ellipsis;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  @media (max-width: ${AppMedia.md}) {
    display: none;
  }
`;

export const ImgContainer = styled.div`
  position: relative;
`;

export const ImgBorder = styled.div.attrs((props: ThemeProps) => {
  isLight: props.isLight;
})`
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 50%;
  right: 0px;
  border: ${({isLight}) => (isLight ? '8px solid rgba(0, 0, 0, 0)' : '8px solid rgba(0, 0, 0, 0.26)')};
`;
export const ImgSubstrate = styled.div.attrs((props: ThemeProps) => {
  isLight: props.isLight;
})`
  background: linear-gradient(215.93deg, #b02ad3 15.49%, #366ef9 83.29%);
  width: 40px;
  height: 40px;
  border-radius: 80%;
  z-index: 2;
  position: absolute;
  top: 8px;
  right: 8px;
  box-shadow: ${({isLight}) => (isLight ? '0px 4px 4px rgba(60, 109, 245, 0.2)' : 'none')};
`;

export const Img = styled.img.attrs((props: ThemeProps) => {
  isLight: props.isLight;
})`
  position: relative;
  background-repeat: no-repeat;
  background-color: #fff;
  width: 38px;
  height: 38px;
  top: 8px;
  right: 9px;
  border-radius: 50%;
  z-index: 3;
  margin: 1px 0 0 1px;
`;

export const DefaultImg = styled.img`
  position: relative;
  width: 38px;
  height: 38px;
  top: 1px;
  right: 2px;
  background-color: #fff;
  border-radius: 50%;
  margin: 1px 0 0 1px;
  z-index: 3;
  transform: scale(0.72);
  padding: 7px;
`;
import NftViewer from "modules/NftViewer";
import NotResponseNft from "modules/NftViewer/NotResponseNft";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { NftActions } from "store/actions/nft";
import { UtilsActions } from "store/actions/utils";
import { AppState } from "store/reducers/store";

const Nft: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const id: string = params.id;

  if (!id) {
    return <div />
  }

  const selectedNftArray = useSelector((store: AppState) => store.nft.selectedClusterOnMap);

  const { errorLoadedNft } = useSelector((store: AppState) => store.nft);

  useEffect(() => {
    if (!errorLoadedNft) {
      if (selectedNftArray.length) {
        dispatch(UtilsActions.openModal({ content: <NftViewer /> }));
      } else {
        dispatch(NftActions.getNft(Number(id)));
        dispatch(UtilsActions.openModal({ content: <NftViewer /> }));
      }
    }
  }, [selectedNftArray, id, errorLoadedNft])

  useEffect(() => {
    if (errorLoadedNft) {
      dispatch(UtilsActions.openModal({ content: <NotResponseNft /> }))
    }
  }, [errorLoadedNft])

  return (
    <div />
  )
}

export default Nft;

import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "shared/components";
import Input from "shared/components/Input";
import Label from "shared/components/Label";
import { UtilsActions } from "store/actions/utils";
import { WalletActions } from "store/actions/wallet";
import { AppState } from "store/reducers/store";
import Popup from "../Popup/Popup";
import { BackButtonRow } from "../Popup/Popup.style";
import ProfilePopup from "../ProfilePopup";
import { ButtonsContainer, Description, InputContainer, PageTitle } from "./CreateWallet.style";
import {ReactComponent as BackButtonIcon} from 'assets/images/icon-popup-back.svg';
import { useTranslation } from 'react-i18next';


const CreateWallet: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [nameWallet, setNameWallet] = useState<string>('');
  const [addressWallet, setAddressWallet] = useState<string>('');

  const { mnemonic } = useSelector((state: AppState) => state.wallet);

  const handleClickBack = () => {
    dispatch(UtilsActions.openModal({content: <ProfilePopup />}));
    dispatch(WalletActions.deleteMnemonic());
  }

  const handleClickCreateWallet = () => {
    dispatch(WalletActions.generateWallet());
  }

  const handleClickImportWallet = () => {
    if (!nameWallet || !addressWallet || addressWallet.length !== 42) {
      return;
    }
    dispatch(WalletActions.imoprtWallet({ name: nameWallet, address: addressWallet, walletPlatform: 'ETHEREUM' }));
    dispatch(UtilsActions.openModal({content: <ProfilePopup />}));
  }

  return (
    <Popup>
      <BackButtonRow onClick={handleClickBack}>
        <BackButtonIcon />
      </BackButtonRow>
      <PageTitle>{t('txt_add_wallet_title')}</PageTitle>
      {mnemonic
        ? <ButtonsContainer>
          <Description>
            <i>{mnemonic}</i>
            <br />
            <br />
            {t('txt_add_wallet_save_mnemonic')}
          </Description>

        </ButtonsContainer>
        : <>
          <InputContainer>
            <Label>{t('txt_add_wallet_name')}</Label>
            <Input onChange={(e) => setNameWallet(e.target.value)} value={nameWallet} />
          </InputContainer>
          <InputContainer>
            <Label>{t('txt_add_wallet_address')}</Label>
            <Input onChange={(e) => setAddressWallet(e.target.value)} value={addressWallet} />
          </InputContainer>

          <ButtonsContainer>
            <Button text={t('btn_add_wallet_import')} onClick={handleClickImportWallet} />
            {t('txt_add_wallet_or')}
            <Button text={t('btn_add_wallet_generate')} onClick={handleClickCreateWallet} />
            <Description>
              {t('txt_add_wallet_description')}
            </Description>
          </ButtonsContainer>
        </>
      }
    </Popup>
  )
}

export default CreateWallet;

import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store/reducers/store';
import Button from './Button';
import {
  Container,
  NFTs,
  PaginationContainer,
  LoadingContainer,
  AttentionIcon,
  DescriptionEmptyList,
  EmptyList,
  Title,
} from './MyNFT.style';
import NFTItem from './NFTItem';
import {useOutsideClick} from 'shared/hooks';
import Loading from 'shared/components/Loading';
import {useHeaderTheme} from 'shared/hooks/useHeaderTheme';
import Pagination from 'shared/components/Pagination';
import {PaginationConfigType} from 'shared/components/Pagination/utils';
import {WalletActions} from 'store/actions/wallet';
import AttentionPath from 'assets/images/icon-attention.svg';

const QUANTITY_ITEMS_ON_PAGE = 9;

const MyNFT = () => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>();
  const [showPopup, setShowPopup] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<PaginationConfigType>({
    currentPage: 0,
    limit: QUANTITY_ITEMS_ON_PAGE,
    quantityPages: 0,
  });
  const [slicedNftList, setSlicedNftList] = useState([]);
  const loading = useSelector((state: AppState) => state.nft.myNftLoading);
  const nftList = useSelector((state: AppState) => state.nft.myNftList);
  const {isLight} = useHeaderTheme();
  const showPagination = nftList.length > QUANTITY_ITEMS_ON_PAGE;
  const { wallets } = useSelector((state: AppState) => state.wallet);

  const updateQuantityPage = () => {
    const quantityPages = Math.ceil(nftList.length / QUANTITY_ITEMS_ON_PAGE);
    setPaginationConfig({...paginationConfig, quantityPages});
  };

  useEffect(() => {
    updateQuantityPage();
    const start = paginationConfig.currentPage * QUANTITY_ITEMS_ON_PAGE;
    const end = (paginationConfig.currentPage + 1) * QUANTITY_ITEMS_ON_PAGE;
    const list = nftList.slice(start, end);
    setSlicedNftList(list);
  }, [nftList, paginationConfig.currentPage]);

  useEffect(() => {
    if (!wallets.length) {
      dispatch(WalletActions.getWallets());
    }
  }, []);

  const onClose = useCallback(() => {
    setShowPopup(false);
  }, [showPopup]);

  useOutsideClick(ref, () => {
    onClose()
  });

  return (
    <div ref={ref}>
      <Button showPopup={showPopup} onClick={() => setShowPopup((prev) => !prev)} />
      {showPopup && (
        <Container isLight={isLight}>
          <NFTs>
            {slicedNftList && !loading && slicedNftList?.map((nft) => <NFTItem key={nft.nftId} nft={nft} onClose={onClose} />)}
            {loading && (
              <LoadingContainer>
                <Loading style={{margin: 'auto'}} />
              </LoadingContainer>
            )}
            {!slicedNftList.length && !loading && (
              <EmptyList>
                <AttentionIcon src={AttentionPath} />
                <Title>Пока у Вас нет NFT:(</Title>
                <DescriptionEmptyList>
                  Перейдите на карту, нажмите кнопку Добавить NFT в интересующем Вас месте и создайте свой первый NFT!
                </DescriptionEmptyList>
              </EmptyList>
            )}
          </NFTs>
          {slicedNftList && !loading && showPagination && (
            <PaginationContainer>
              <Pagination paginationConfig={paginationConfig} updatePagination={setPaginationConfig} />
            </PaginationContainer>
          )}
        </Container>
      )}
    </div>
  );
};
export default MyNFT;

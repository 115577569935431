import {applyMiddleware, createStore, PreloadedState, Store} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from '@redux-saga/core';
import rootReducer from './reducers/store';
import staticSagas from './sagas';

const configureStore = (preloadedState?: PreloadedState<Record<string, unknown>>): Store => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  sagaMiddleware.run(staticSagas);

  return store;
};

export default configureStore;

import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';

export const NftContainer = styled.div`
  position: relative;
  width: 476px;
  height: 200px;
  margin: 0 0 15px 15px;
  @media (max-width: ${AppMedia.lg}) {
    width: calc(50% - 15px);
  }
  @media (max-width: ${AppMedia.md}) {
    width: calc(100% - 30px);
  }
  @media (max-width: ${AppMedia.sm}) {
    width: 330px;
    height: 188px;
		margin: 0 0 15px 0;
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 3px;
  background: ${AppColors.grayLight};
  border-radius: 3px;
  margin: 15px 0 0 0;
  @media (max-width: ${AppMedia.sm}) {
		margin: 0;
	}
`;

export const Container = styled.div`
  display: flex;
`;

export const LoadingContainer = styled.div`
  position: relative;
  width: 219px;
  height: 133px;
  background: #f0f0f0;
  border-radius: 10px;
  @media (max-width: ${AppMedia.sm}) {
    width: 160px;
    height: 94px;
  }
`;

export const EnlargedImg = styled.img`
  max-width: calc(100vw - 140px);
`;

export const NftImg = styled.img`
  width: 215px;
  height: 129px;
  border-radius: 10px;
  padding: 2px;
  object-fit: cover;
  position: relative;
  z-index: 2;
  @media (max-width: ${AppMedia.sm}) {
    width: 156px;
    height: 90px;
  }
`;

export const LoupeIcon = styled.img`
  width: 52px;
  height: 52px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 3;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  margin-left: 8px;
`;

export const Title = styled.h3`
  width: 215px;
  font-size: 15px;
  line-height: 18px;
  color: ${AppColors.darkGrey};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: ${AppMedia.sm}) {
  	width: 145px;
	}
`;

export const EditIcon = styled.img`
  cursor: pointer;
`;

export const Address = styled.p`
  font-size: 13px;
  line-height: 15px;
  color: ${AppColors.textDefault};
  margin: 11px 0 3px 0;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Coordinates = styled.p`
  width: 130px;
  overflow: hidden;
  font-size: 13px;
  line-height: 15px;
  color: ${AppColors.darkGrey};
  margin: 0 0 8px 0;
  white-space: nowrap;
`;

export const Description = styled.p`
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 15px;
  color: ${AppColors.textDefault};
  margin: 10px 20px 0 0;
  @media (max-width: ${AppMedia.sm}) {
  	margin-top: 55px;
	}
`;

export const AttentionMiniIcon = styled.img`
  margin-right: 5px;
`;

export const PublishTime = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.textDefault};
  display: flex;
  align-items: center;
  width: 242px;
  height: 16px;
  overflow-x: hidden;
  white-space: nowrap;
  margin: 0 0 9px 0;
  @media (max-width: ${AppMedia.sm}) {
  	width: 160px;
	}
`;

export const Time = styled.p`
  color: ${AppColors.darkGrey};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  :nth-last-child {
    margin-right: 0;
  }
  @media (max-width: ${AppMedia.sm}) {
    position: absolute;
    left: 0;
		margin-top: 50px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  background-color: ${AppColors.grayLight};
  color: ${AppColors.darkGrey};
  outline-style: none;
  border: none;
  border-radius: 30px;
  height: 40px;
  width: 120px;
  cursor: pointer;
  margin-right: 4px;
  @media (max-width: ${AppMedia.sm}) {
    width: 160px;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
`;

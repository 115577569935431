import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';

export const ClickDisabler = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Container = styled.div`
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  max-width: calc(100vw - 10px);
  height: auto;
  max-height: calc(100vh - 120px);
  box-sizing: border-box;
  padding: 56px 30px 18px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${AppColors.fullDark};
  border-radius: 20px;
  z-index: 100;
  @media (max-width: ${AppMedia.sm}) {
    width: calc(100vw - 10px);
    padding: 56px 12px 12px 12px;
  }
`;

export const StyledImg = styled.img`
  border-radius: 20px;
  min-width: 300px;
  min-height: 200px;
  max-width: 100%;
  max-height: calc(100vh - 120px - 56px);
`;

export const CloseIcon = styled.img`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
`;

import {call, put, select, takeLatest} from 'redux-saga/effects';
import {PickAction} from '../helpers/redux';
import {createNotification, Notification} from 'shared/components/Notify';
import { WalletActions, WalletActionsUnion } from 'store/actions/wallet';
import { CreateWattetResponseType, WalletActionTypes, WalletType } from 'store/types/wallet';
import { UserData } from 'store/types/user';
import { AppState } from 'store/reducers/store';
import { onDisconnect } from 'shared/utils/walletConnector';
import adapter from 'store/helpers/adapter';

function* getWalletsSaga(action: PickAction<WalletActionsUnion, WalletActionTypes.GET_WALLET_REQUEST>): Generator {

  try {
    const response: any = yield call(
      adapter,
      `https://api.geonftapp.com/api/v1/wallets`,
      {method: 'get', withHeaders: true}
    );
    const data: WalletType [] = response.data;
    const lastUsedWalletId = localStorage.getItem('activeWalletId');
    if (!data.length) return;
    if(lastUsedWalletId && data.find((item) => item.id === Number(lastUsedWalletId))) {
      yield put(WalletActions.setActiveWalletId(Number(lastUsedWalletId)));
    } else {
      yield put(WalletActions.setActiveWalletId(data[0].id));
    }
    yield put(WalletActions.getWalletsResponse(data));
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* generateWalletSaga(action: PickAction<WalletActionsUnion, WalletActionTypes.CREATE_WALLET_REQUEST>): Generator {

  try {
    const response: any = yield call(
      adapter,
      `https://api.geonftapp.com/api/v1/wallets/create`,
      {method: 'post', withHeaders: true}
    );
    const data: CreateWattetResponseType = response.data;
    yield put(WalletActions.generateWalletResponse(data));
  } catch (err) {
    createNotification(Notification.error, err.message);
    yield put(WalletActions.generateWalletError());
  }
}

function* importWalletSaga(action: PickAction<WalletActionsUnion, WalletActionTypes.ADD_WALLET_REQUEST>): Generator {
  const {payload} = action;

  try {
    const response: any = yield call(
      adapter,
      `https://api.geonftapp.com/api/v1/wallets/add`,
      {method: 'post', withHeaders: true},
      {...payload}
    );
    const data: WalletType = response.data;
    yield put(WalletActions.imoprtWalletSuccess(data));
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* setActiveWalletIdSaga(action: PickAction<WalletActionsUnion, WalletActionTypes.SET_ACTIVE_WALLET_ID>): Generator {
  const walletId = action.payload;
  const { id } = (yield select((state: AppState) => state.user.user)) as UserData;
  const currentAccounts = JSON.parse(localStorage.getItem('users')) as UserData[] || undefined;

  localStorage.setItem('users', JSON.stringify(currentAccounts.map((item) =>
    item.id === id
      ? { ...item, activeValletId: walletId }
      : item
  )));
  localStorage.setItem('activeWalletId', walletId.toString());
  onDisconnect();
}

function* watch(): Generator {
  yield takeLatest(WalletActionTypes.GET_WALLET_REQUEST, getWalletsSaga);
  yield takeLatest(WalletActionTypes.CREATE_WALLET_REQUEST, generateWalletSaga);
  yield takeLatest(WalletActionTypes.ADD_WALLET_REQUEST, importWalletSaga);
  yield takeLatest(WalletActionTypes.SET_ACTIVE_WALLET_ID, setActiveWalletIdSaga);
}

export default watch;

import { FC } from "react";
import Label from "shared/components/Label";
import { MarkerIcon, Row } from "./Address.style";

export interface AddressProps {
  lat: number;
  lng: number;
}

const Address: FC<AddressProps> = ({lat, lng}) => {

  return (
    <Row>
      <MarkerIcon />
      <Label>{lat.toFixed(6)}, {lng.toFixed(6)}</Label>
    </Row>
  )
}

export default Address;

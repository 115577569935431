import {FC, useEffect, useState} from 'react';
import {Title, HelpText, InputContainer, ButtonContainer, ShowPassword, LockIcon, Flex} from './ChangePassword.style';
import Label from 'shared/components/Label';
import Input from 'shared/components/Input';
import {BackButtonRow} from '../Popup/Popup.style';
import {ReactComponent as BackButtonIcon} from 'assets/images/icon-popup-back.svg';
import LockIconPath from 'assets/images/icon-lock.svg';
import Popup from '../Popup';
import {UtilsActions} from 'store/actions/utils';
import ProfilePopup from '../ProfilePopup';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'shared/components';
import {UserActions} from 'store/actions/user';
import {AppState} from 'store/reducers/store';
import Loading from 'shared/components/Loading';
import { useTranslation } from 'react-i18next';

type ChangePasswordProps = {};

const ChangePassword: FC<ChangePasswordProps> = ({}: ChangePasswordProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const showHelpText = newPassword !== confirmNewPassword && !!newPassword && !!confirmNewPassword;
  const loading = useSelector((state: AppState) => state.user.changePasswordLoading);
  const changeSuccess = useSelector((state: AppState) => state.user.changePasswordSuccess);

  useEffect(() => {
    if (changeSuccess) {
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    }
  }, [changeSuccess]);

  const onBack = () => {
    dispatch(UtilsActions.openModal({content: <ProfilePopup />}));
  };

  const onUpdate = () => {
    dispatch(UserActions.changePassword({password: newPassword, oldPassword: currentPassword}));
  };

  return (
    <Popup>
      <>
        <BackButtonRow onClick={onBack}>
          <BackButtonIcon />
        </BackButtonRow>
        <Title>{t('title_change_pass')}</Title>
        <InputContainer>
          <Label>{t('txt_change_pass_cur')}</Label>
          <Flex>
            <LockIcon src={LockIconPath} />
            <Input
              type={showCurrentPassword ? 'text' : 'password'}
              onChange={(e) => setCurrentPassword(e.target.value)}
              value={currentPassword}
              style={{padding: '20px 20px 20px 40px', width: '100%', marginBottom: 0}}
            />
            <ShowPassword onClick={() => setShowCurrentPassword((prev) => !prev)}>
              {t('btn_change_pass_show')}
            </ShowPassword>
          </Flex>
        </InputContainer>
        <InputContainer>
          <Label>{t('txt_change_pass_new')}</Label>
          <Flex>
            <LockIcon src={LockIconPath} />
            <Input
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              style={{padding: '20px 20px 20px 40px', width: '100%', marginBottom: 0}}
            />
          </Flex>
        </InputContainer>
        <InputContainer>
          <Label>{t('txt_change_pass_confirm')}</Label>
          <Flex>
            <LockIcon src={LockIconPath} />
            <Input
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              value={confirmNewPassword}
              style={{padding: '20px 20px 20px 40px', width: '100%', marginBottom: 0}}
            />
            <ShowPassword onClick={() => setShowConfirmPassword((prev) => !prev)}>
              {t('btn_change_pass_show')}
            </ShowPassword>
          </Flex>
          {showHelpText && <HelpText>{t('err_change_pass_not_identical')}</HelpText>}
        </InputContainer>
        <ButtonContainer>
          {loading ? (
            <Loading style={{transform: 'scale(0.4)', transformOrigin: 'top center'}} />
          ) : (
            <Button
              text={t('btn_change_pass_change')}
              onClick={onUpdate}
              disabled={!currentPassword || !newPassword || !confirmNewPassword}
            />
          )}
        </ButtonContainer>
      </>
    </Popup>
  );
};
export default ChangePassword;

import {UtilsActions} from './../actions/utils';
import {NftActions, NftActionsUnion} from 'store/actions/nft';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  FindNftResponse,
  FindType,
  NftActionTypes,
  NftType,
  PublishNftResponseType,
  SearchNftResponseType,
  UploadFileApiResponseType,
} from 'store/types/nft';
import {ITxData} from '@walletconnect/types';
import {PickAction} from '../helpers/redux';
import {createNotification, Notification} from 'shared/components/Notify';
import {TOKEN} from 'shared/constants/constants';
import {MapActions} from 'store/actions/map';
import {AppState} from '../reducers/store';
import {onSendTransaction} from 'shared/utils/walletConnector';
import {WalletType} from 'store/types/wallet';
import adapter from '../helpers/adapter';

function* searchNftRequest(action: PickAction<NftActionsUnion, NftActionTypes.SEARCH_NFT_REQUEST>): Generator {
  const {payload} = action;
  yield put(MapActions.addMapLoader());
  try {
    const response: any = yield call(
      adapter,
      `https://api.geonftapp.com/api/v1/nft/search?topLeftLat=${payload.topLeftLat}&topLeftLng=${payload.topLeftLng}&botRightLat=${payload.botRightLat}&botRightLng=${payload.botRightLng}`,
      {method: 'get'},
    );
    // const response: any = yield axios.get(
    //   `https://api.geonftapp.com/api/v1/nft/search?topLeftLat=${payload.topLeftLat}&topLeftLng=${payload.topLeftLng}&botRightLat=${payload.botRightLat}&botRightLng=${payload.botRightLng}`,
    // );
    const data: SearchNftResponseType = response.data;

    yield put(NftActions.searchNftResponse(data));
    yield put(MapActions.deleteMapLoader());
  } catch (err) {
    createNotification(Notification.error, err.message);
    yield put(NftActions.searchNftError());
    yield put(MapActions.deleteMapLoader());
  }
}

function* getMyNft(): Generator {
  try {
    const response: any = yield call(
      adapter,
      'https://api.geonftapp.com/api/v1/nft/me',
      {method: 'get', withHeaders: true}
    );
    // const response: any = yield axios.get('https://api.geonftapp.com/api/v1/nft/me', {
    //   headers: {
    //     Authorization: token,
    //   },
    // });
    const data: NftType[] = response.data;
    yield put(NftActions.myNftResponse(data));
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* uploadFile(action: PickAction<NftActionsUnion, NftActionTypes.UPLOAD_FILE_REQUEST>): Generator {
  const {payload} = action;
  const formData = new FormData();
  formData.append('file', payload.file);
  try {
    const response = yield call(adapter,'https://api.geonftapp.com/api/v1/files/streetartnft', {
      withHeaders: true,
      method: 'post',
      headers: {
        accept: 'application/json',
        'Accept-Language': 'ru-RU',
        'Content-Type': 'multipart/form-data;',
      },
    }, formData);
    // const response = yield axios.post('https://api.geonftapp.com/api/v1/files/streetartnft', formData, {
    //   headers: {
    //     accept: 'application/json',
    //       'Accept-Language': 'ru-RU',
    //       'Content-Type': 'multipart/form-data;',
    //   },
    // });
    const {
      data: {bucket, fullName, hash, name},
    } = response as UploadFileApiResponseType;
    yield put(
      NftActions.uploadFileResponse({
        bucket: bucket,
        fullName: fullName,
        hash: hash,
        name: name,
      }),
    );
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* findNft(): Generator {
  const {limit, text, offset} = (yield select((state: AppState) => state.nft.find)) as FindType;

  if (!text) {
    yield put(NftActions.findNftResponse({list: [], found: 0}));
    return;
  }
  try {
    const response: any = yield call(
      adapter,
      `https://api.geonftapp.com/api/v1/nft/find/${text}/${limit}/${offset}`,
      {method: 'get', withHeaders: true}
    );
    const data: FindNftResponse = response.data;
    yield put(NftActions.findNftResponse(data));
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* createNft(action: PickAction<NftActionsUnion, NftActionTypes.CREATE_NFT_REQUEST>): Generator {

  const {payload: data} = action;

  try {
    const response: any = yield call(
      adapter,
      'https://api.geonftapp.com/api/v1/nft',
      {method: 'post', withHeaders: true},
      data
    );
    const resData: NftType = response.data;
    yield put(NftActions.createNftResponse(resData));
    yield put(MapActions.onChangeNftAimState(false));
    yield put(UtilsActions.closeModal());
    yield put(
      NftActions.uploadFileResponse({
        bucket: '',
        fullName: '',
        hash: '',
        name: '',
      }),
    );
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* setHideNft(action: PickAction<NftActionsUnion, NftActionTypes.HIDE_NFT_REQUEST>): Generator {
  const {payload: data} = action;

  try {
    const response: any = yield call(
      adapter,
      `https://api.geonftapp.com/api/v1/nft/hide`,
      {method: 'post', withHeaders: true},
      data
    );
    const resData: NftType = response.data;
    yield put(NftActions.setHideNftResponse(resData));
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* getNft(nftId: string): Generator {
  const token = localStorage.getItem(TOKEN);

  try {
    const response: any = yield call(
      adapter,
      `https://api.geonftapp.com/api/v1/nft/${nftId}`,
      {method: 'get', withHeaders: true}
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

function* publishNft(action: PickAction<NftActionsUnion, NftActionTypes.PUBLISH_NFT_REQUEST>): Generator {
  const {payload: data} = action;

  try {
    const response: any = yield call(
      adapter,
      `https://api.geonftapp.com/api/v1/nft/publish`,
      {method: 'post', withHeaders: true},
      data
    );
    const resData: PublishNftResponseType = response.data;
    if (resData.txHash) {
      const updatedNft = (yield call(getNft, data.nftId)) as NftType;
      yield put(NftActions.publishNftResponse(updatedNft));
      createNotification(Notification.success, 'Nft published success!');
    } else {
      yield put(NftActions.savePublishedNft(resData));

      const lastUsedWalletId = localStorage.getItem('activeWalletId');
      const wallets = (yield select((state: AppState) => state.wallet.wallets)) as WalletType[];
      const activeWallet = wallets.find((item) => item.id === Number(lastUsedWalletId));

      const tx: ITxData = {
        from: activeWallet.walletAddress, // Required
        to: resData.addressContract, // Required (for non contract deployments)
        data: resData.txData, // Required
      };

      onSendTransaction(tx);
    }
  } catch (err) {
    createNotification(Notification.error, JSON.parse(err?.request?.response)?.message || err?.message);
  }
}

function* editNft(action: PickAction<NftActionsUnion, NftActionTypes.CREATE_NFT_REQUEST>): Generator {
  const {payload: data} = action;

  try {
    const response: any = yield call(
      adapter,
      'https://api.geonftapp.com/api/v1/nft',
      {method: 'post', withHeaders: true},
      data
    );
    const resData: NftType = response.data;
    yield put(NftActions.editNftResponse(resData));
    yield put(UtilsActions.closeModal());
  } catch (err) {
    yield put({type: NftActionTypes.EDIT_NFT_ERROR});
    createNotification(Notification.error, err.message);
  }
}

function* getNftSaga(action: PickAction<NftActionsUnion, NftActionTypes.GET_NFT>): Generator {
  const {payload} = action;

  try {
    const response: any = yield call(
      adapter,
      `https://api.geonftapp.com/api/v1/nft/${payload}`,
      {method: 'get'},
    );
    const data = response.data as NftType;
    yield put(NftActions.onSelectClusterOnMap([data]));
    yield put(NftActions.getNftResponse());
  } catch (err) {
    yield put(NftActions.getNftError());
    console.error(err);
  }
}

function* watch(): Generator {
  yield takeLatest(NftActionTypes.SEARCH_NFT_REQUEST, searchNftRequest);
  yield takeLatest(NftActionTypes.UPLOAD_FILE_REQUEST, uploadFile);
  yield takeLatest(NftActionTypes.CREATE_NFT_REQUEST, createNft);
  yield takeLatest(NftActionTypes.MY_NFT_REQUEST, getMyNft);
  yield takeLatest(NftActionTypes.FIND_NFT_REQUEST, findNft);
  yield takeLatest(NftActionTypes.HIDE_NFT_REQUEST, setHideNft);
  yield takeLatest(NftActionTypes.PUBLISH_NFT_REQUEST, publishNft);
  yield takeLatest(NftActionTypes.EDIT_NFT_REQUEST, editNft);
  yield takeLatest(NftActionTypes.GET_NFT, getNftSaga);
}

export default watch;

import React, { FC, useState } from 'react';
import Loading from 'shared/components/Loading';
import { StyledSlideItem } from './Presentation.style';

type SlideItemProps = {
  item: any;
  currentImageIndex: number;
  setLoadedImgIndexes: (value: number) => void;
};

const SlideItem: FC<SlideItemProps> = ({
  item,
  currentImageIndex,
  setLoadedImgIndexes,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const onLoadedImg = () => {
    setIsLoading(false);
    setLoadedImgIndexes(currentImageIndex);
  }

  return (
    <>
      {isLoading
        ? <Loading
          style={{
            position: 'absolute',
            left: 'calc(50% - 40px',
            top: '50%',
            zIndex: 1,
          }}
        />
        : <div />
      }
      <StyledSlideItem src={item} alt="" onLoad={onLoadedImg} />
    </>

  )
}

export default SlideItem;

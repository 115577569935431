import {CSSProperties, FC} from 'react';

type IconMapPlusProps = {
  fill?: string;
  style?: CSSProperties;
};

const IconMapPlus: FC<IconMapPlusProps> = ({fill, style}: IconMapPlusProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} style={style} xmlns="http://www.w3.org/2000/svg">
      <path d="M1 8H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 15V1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default IconMapPlus;

import styled from 'styled-components';
import {AppColors} from 'shared/constants/globalStyles';

export const WalletContainer = styled.div`
  margin-top: 23px;
	margin-bottom: 20px;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${AppColors.darkGrey};
`;

export const Icon = styled.img`
  width: 44px;
  margin-right: 15px;
`;

export const DotDisabled = styled.div`
  width: 25px;
  height: 22px;
  background: ${AppColors.gray1};
  border: 5px solid ${AppColors.grayLight};
  border-radius: 20px;
	margin-left: 6px;
  cursor: pointer;
`;
export const DotLinearActive = styled.div`
  background: ${AppColors.white};
  width: 14px;
  height: 14px;
  border-radius: 20px;
`;

export const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 22px;
  background: linear-gradient(215.93deg, #b02ad3 15.49%, #366ef9 83.29%);
  border: 5px solid ${AppColors.grayLight};
  border-radius: 20px;
	margin-left: 6px;
  cursor: pointer;
`;

export const Hash = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.gray};
  margin: 0;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DescriptionText = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${AppColors.fullDark};
	margin: 0 0 5px 0;
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 40px;
	margin-left: 21px;
`

export const BillIcon = styled.img`
	align-self: end;
  cursor: pointer;
`

export const HelperText = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin: 22px;
  color: ${AppColors.textDefault};
`;

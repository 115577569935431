import axios from 'axios';
import {put, select} from 'redux-saga/effects';
import {AuthActions} from 'store/actions/auth';
import {AppState} from 'store/reducers/store';
import {TOKEN} from 'shared/constants/constants';

const UNAUTHORIZED_STATUS = 401;
type Config = {method: 'get' | 'post' | 'delete' | 'put'; withHeaders?: boolean; headers?: Record<string, string | number | boolean>};

function* adapter(url: string, config: Config, data?: any, params?: any) {
  const {method = 'get', withHeaders} = config;
  const token = localStorage.getItem(TOKEN);
  const headers = withHeaders ? {...config.headers, Authorization: token} : {};
  const isAuth = select((state: AppState) => state.auth.isAuth);
  try {
    const response = yield axios.request({
      headers,
      url,
      method,
      data: data || {},
      params: params || {},
    });
    return response;
  } catch (err) {
    if (err?.response?.status === UNAUTHORIZED_STATUS && isAuth) {
      yield put(AuthActions.logout());
      window.location.replace('');
    }
    return Promise.reject(err);
  }
}

export default adapter;

import styled from 'styled-components';
import {AppColors} from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

export const Container = styled.div`
  width: 380px;
  height: auto;
  box-sizing: border-box;
  padding: 56px 30px 18px 30px;
  background-color: ${AppColors.white};
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
`;

export const GreetingContainer = styled.div`
	max-width: 370px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CloseIcon = styled.img`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 28px;
  font-weight: 400;
  line-height: 33px;
  color: ${AppColors.gray};
`;

export const TitleBold = styled.p`
  margin: 0;
  font-size: 28px;
  font-weight: 500;
  color: ${AppColors.darkGrey};
`;

export const Description = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  line-height: 16px;
  color: ${AppColors.textDefault};
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  width: 320px;
  height: 48px;
  border-radius: 30px;
  background-color: ${AppColors.grayLight};
  padding-left: 20px;
  border-style: none;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.darkGrey};
  margin: auto;
`;

export const ButtonIcon = styled.img`
  position: absolute;
  width: 20px;
`;

export const FooterDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  color: ${AppColors.textDefault};
  margin-bottom: 20px;
`;

export const TextLink = styled.span`
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  text-decoration: none;
	cursor: pointer;
`;

export const Link = styled.button`
	font-family: Roboto, serif;
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  text-decoration: none;
	border: none;
	font-size: 15px;
	line-height: 18px;
	font-weight: 500;
	background-color: transparent;
	cursor: pointer;
`;

export const Border = styled.div`
  width: 300px;
  height: 3px;
  margin: 0 auto 15px auto;
  background: ${AppColors.grayLight};
  border-radius: 3px;
`;

export const HaveAccountText = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${AppColors.darkGrey};
  margin: 0;
`;

export const AuthLink = styled.a`
  text-decoration: none;
`;

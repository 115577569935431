import {CSSProperties, FC, ReactNode} from 'react';
import {ButtonTag} from './Button.style';

type ButtonProps = {
  text: string;
  onClick?: () => void;
  style?: CSSProperties;
  icon?: ReactNode;
  disabled?: boolean;
};

const Button: FC<ButtonProps> = ({text, onClick, style, icon, disabled}: ButtonProps) => {
  return (
    <ButtonTag onClick={onClick} style={style} disabled={disabled}>
      {icon}
      {text}
    </ButtonTag>
  );
};
export default Button;

import styled from 'styled-components';
import {AppColors} from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${AppColors.textDefault};
  text-align: center;
`;

export const HelpText = styled.p`
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  font-size: 11px;
  line-height: 13px;
  margin: 0 0 0 16px;
  text-align: center;
  position: absolute;
	top: 70px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
	margin-bottom: 10px;
	position: relative;
`;

export const ShowPassword = styled.span`
  position: absolute;
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  font-size: 13px;
  cursor: pointer;
  right: 15px;
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

export const LockIcon = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  left: 15px;
`;

export const Flex = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
	align-items: center;
`;

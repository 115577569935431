import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UtilsActions } from "store/actions/utils";
import { AppState } from "store/reducers/store";
import { ZoomButtonMinus, ZoomButtonPlus, ButtonsContainer, GeoButton, MapTypeButton } from "./MapOptions.style";
import SelectMapTypeModal from "./SelectMapTypeModal";
import {ReactComponent as IconMapGeolocation} from 'assets/images/icon-map-geolocation.svg';
import Loading from "shared/components/Loading";


type ZoomButtonProps = {
  onChangeMapZoom: (zoom: number) => void;
  zoom: number;
};

type GeolocationButtonProps = {
  setCenter: (lat: number, lng: number) => void;
}

export const ZoomButton: FC<ZoomButtonProps> = ({onChangeMapZoom, zoom}: ZoomButtonProps) => {
  const handleClickPlusZoom = () => {
    onChangeMapZoom(zoom + 1);
  }

  const handleClickMinusZoom = () => {
    onChangeMapZoom(zoom - 1);
  }

  return(
    <ButtonsContainer>
      <ZoomButtonPlus onClick={handleClickPlusZoom} />
      <ZoomButtonMinus onClick={handleClickMinusZoom} />
    </ButtonsContainer>
  )
}

export const GeolocationButton: FC<GeolocationButtonProps> = ({setCenter}: GeolocationButtonProps) => {
  const [geoLoader, setGeoLoader] = useState(false);
  const {loader} = useSelector((state: AppState) => state.map);

  useEffect(() => {
    if (!loader) {
      setGeoLoader(false);
    }
  }, [loader, setGeoLoader])

  const getGeolocation = () => {
    setGeoLoader(true);
    navigator.geolocation.getCurrentPosition(function(position) {
      setCenter(position.coords.latitude, position.coords.longitude);
    });
  }

  return(
    <ButtonsContainer>
      <GeoButton onClick={getGeolocation}>
        {geoLoader
          ? <Loading style={{ transform: 'scale(.35)', position: 'absolute' }} />
          : <IconMapGeolocation />
        }
      </GeoButton>
    </ButtonsContainer>
  )
}


export const SelectedMapTypeButton: FC = () => {
  const dispatch = useDispatch();
  const [isActiveButton, setIsActiveButton] = useState<boolean>(false);

  const onCloseSelectModal = () => {
    setIsActiveButton(false);
  }

  const onClickSelectMapTypeButton = () => {
    setIsActiveButton(!isActiveButton);
    dispatch(UtilsActions.openModal({content: <SelectMapTypeModal onCloseSelectModal={onCloseSelectModal} />}));
  }

  return(
    <ButtonsContainer onClick={onClickSelectMapTypeButton}>
      <MapTypeButton isActiveButton={isActiveButton} />
    </ButtonsContainer>
  )
}


export const hasOneCapitalLatter = (password: string) => {
  const withoutNumber = password.replace(/[^a-zA-ZА]/g, '');
  return withoutNumber.split('').some((symbol: any) => symbol.toUpperCase() === symbol);
};

export const hasOneLowerCaseLatter = (password: string) => {
  const withoutNumber = password.replace(/[^a-zA-ZА]/g, '');
  return withoutNumber.split('').some((symbol: any) => symbol.toLowerCase() === symbol);
};

export const hasOneNumber = (password: string) => {
  return password.replace(/[^0-9]/g, '').length;
};

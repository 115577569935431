import { AppColors } from "shared/constants/globalStyles";
import styled from "styled-components";

type ColorItemProps = {
  color: string;
  isSelected?: boolean;
}

export const SubTitle = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${AppColors.textDefault};
`;

export const ColorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 11px -14px;
`;

export const ColorBorder = styled.div<ColorItemProps>`
  margin: 9px 14px;
  padding: 6px;
  background: ${props => props.isSelected ? `${props.color}33` : props.color};
  border-radius: 100%;
  cursor: pointer;
  position: relative;
`;

export const ColorItem = styled.div<ColorItemProps>`
  width: 30px;
  min-width: 30px;
  height: 30px;
  background: ${props => props.color};
  border-radius: 100%;
`;

export const ColorCheckContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

export const Border = styled.span`
  display: block;
  width: 100%;
  height: 3px;
  background: ${AppColors.grayLight};
  border-radius: 3px;
  margin-top: 10px;
`;

import {FC, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AuthActions} from 'store/actions/auth';
import {AppState} from 'store/reducers/store';
import {ModalContainer} from 'shared/components/Modal';
import {Input, InputContainer, Label, Title, ButtonContainer, Footer} from './Login.style';
import Button from 'components/Button';
import {TextLink} from '../Greeting/Greeting.style';
import {UtilsActions} from 'store/actions/utils';
import Greeting from '../Greeting';
import { useTranslation } from 'react-i18next';

type LogInProps = {};

const Login: FC<LogInProps> = ({}: LogInProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const isValid = useMemo(() => {
    return login && password;
  }, [login, password]);

  const onSignup = () => {
    dispatch(UtilsActions.openModal({content: <Greeting />}));
  };

  const onLogin = () => {
    if (!isValid) return;
    dispatch(AuthActions.login({login, password}));
  };

  const onChangeLogin = (e) => {
    setLogin(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <ModalContainer isIconClose style={{width: '370px'}}>
      <>
        <Title>{t('txt_login_title')}</Title>
        <InputContainer>
          <Label>{t('txt_login_caption_login')}</Label>
          <Input value={login} onChange={onChangeLogin} />
        </InputContainer>
        <InputContainer>
          <Label>{t('txt_login_caption_password')}</Label>
          <Input value={password} onChange={onChangePassword} type={'password'} />
        </InputContainer>
        <ButtonContainer>
          <Button
            variant="contained"
            onClick={onLogin}
            style={{padding: '11px 55px', margin: 'auto', border: '8px solid #F0F0F0'}}
            disabled={!login || !password}
          >
            {t('btn_login_login')}
          </Button>
        </ButtonContainer>
        <Footer>
          {t('txt_login_dont_have_account')}&nbsp;
          <TextLink onClick={onSignup}>{t('btn_login_register_for_free')}</TextLink>
        </Footer>
      </>
    </ModalContainer>
  );
};

export default Login;

import {FC, useRef, useState} from 'react';
import {
  ImgContainer,
  Img,
  ImgSubstrate,
  NameContainer,
  Name,
  Alias,
  FlexContainer,
  ImgSubstrateBorder,
  IconCamera,
  Div,
  IconEdit,
  SpaceBetween,
  Input,
  CheckIcon,
  InputUpload,
  LoadingContainer,
  LoadingNameContainer,
  DefaultImg,
} from './Header.style';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store/reducers/store';
import {UserActions} from 'store/actions/user';
import CheckIconPath from 'assets/images/icon-check.png';
import Loading from 'shared/components/Loading';
import defaultAvatar from 'assets/images/img-profile-default.svg';

const ENTER_CODE = 'Enter';

const Header: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user.user);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(user.name);
  const inputRef = useRef<HTMLInputElement>();
  const nameLoading = useSelector((state: AppState) => state.user.nameLoading);
  const avatarLoading = useSelector((state: AppState) => state.user.avatarLoading);

  const onEdit = () => {
    setIsEdit(true);
    setTimeout(() => inputRef.current?.select(), 0);
  };

  const onSave = () => {
    if (value) {
      dispatch(UserActions.renameUser({name: value}));
    }
    setIsEdit(false);
  };

  const onSaveByEnter = (e) => {
    if (e.code === ENTER_CODE) onSave();
  };

  const onUploadAvatar = (e) => {
    const file = e.target.files[0];
    if (file) dispatch(UserActions.uploadAvatar(file));
  };

  return (
    <SpaceBetween>
      <FlexContainer>
        <ImgContainer>
          <ImgSubstrateBorder />
          <ImgSubstrate />
          {user.imageUrl ? <Img src={user.imageUrl} /> : <DefaultImg src={defaultAvatar} />}
          {avatarLoading ? (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          ) : (
            <InputUpload type="file" onChange={onUploadAvatar} />
          )}
        </ImgContainer>
        <IconCamera />
        <NameContainer>
          {isEdit ? (
            <Input ref={inputRef} value={value} onKeyPress={onSaveByEnter} onChange={(e) => setValue(e.target.value)} />
          ) : nameLoading ? (
            <LoadingNameContainer>
              <Loading />
            </LoadingNameContainer>
          ) : (
            <Name>{user.name}</Name>
          )}
          <Alias>@{user.login}</Alias>
        </NameContainer>
      </FlexContainer>
      <Div>{isEdit ? <CheckIcon src={CheckIconPath} onClick={onSave} /> : <IconEdit onClick={onEdit} />}</Div>
    </SpaceBetween>
  );
};

export default Header;

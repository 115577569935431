import {ChangePasswordRequest, ChangeUser, ThemeObjType, UserActionTypes, UserData} from 'store/types/user';
import {Action, createAction, ActionsUnion} from 'store/helpers/redux';

export const UserActions = {
  getUser: (): Action<UserActionTypes.GET_USER_REQUEST> =>
    createAction(UserActionTypes.GET_USER_REQUEST),
  setUser: (payload: UserData): Action<UserActionTypes.GET_USER_SUCCESS, UserData> =>
    createAction(UserActionTypes.GET_USER_SUCCESS, payload),
  renameUser: (payload: ChangeUser): Action<UserActionTypes.RENAME_USER_REQUEST, ChangeUser> =>
    createAction(UserActionTypes.RENAME_USER_REQUEST, payload),
  renameUserSuccess: (payload: ChangeUser): Action<UserActionTypes.RENAME_USER_SUCCESS, ChangeUser> =>
    createAction(UserActionTypes.RENAME_USER_SUCCESS, payload),
  uploadAvatar: (payload: File): Action<UserActionTypes.UPLOAD_AVATAR_REQUEST, File> =>
    createAction(UserActionTypes.UPLOAD_AVATAR_REQUEST, payload),
  uploadAvatarSuccess: (payload: ChangeUser): Action<UserActionTypes.UPLOAD_AVATAR_SUCCESS, ChangeUser> =>
    createAction(UserActionTypes.UPLOAD_AVATAR_SUCCESS, payload),
  changePassword: (payload: ChangePasswordRequest): Action<UserActionTypes.CHANGE_PASSWORD_REQUEST, ChangePasswordRequest> =>
    createAction(UserActionTypes.CHANGE_PASSWORD_REQUEST, payload),
  changePasswordSuccess: (): Action<UserActionTypes.CHANGE_PASSWORD_SUCCESS> =>
    createAction(UserActionTypes.CHANGE_PASSWORD_SUCCESS),
  logout: (): Action<UserActionTypes.USER_INFO_DELETE> =>
    createAction(UserActionTypes.USER_INFO_DELETE),
  changeThemeColor: (payload: ThemeObjType): Action<UserActionTypes.CHANGE_THEME_COLOR, ThemeObjType> =>
    createAction(UserActionTypes.CHANGE_THEME_COLOR, payload),
};

export type UserActionsUnion = ActionsUnion<typeof UserActions>;

import { createNotification, Notification } from 'shared/components/Notify';
import WalletConnect from "@walletconnect/browser";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";
import { ITxData } from "@walletconnect/types";

// Create a walletConnector
const walletConnector = new WalletConnect({
  bridge: "https://bridge.walletconnect.org", // Required
  qrcodeModal: WalletConnectQRCodeModal,
});

// Re-wrap for export to feed in Dispatch callback
const onConnect = (fn?: any) => {
  // Subscribe to connection events
  walletConnector.on("connect", (error, payload) => {
    if (error) {
      throw error;
    }
    console.log("connected!");
    // Close QR Code Modal
    WalletConnectQRCodeModal.close();
    // Get provided accounts and chainId
    const { accounts, chainId } = payload.params[0];
    fn(accounts, chainId);
  });
}

// Re-wrap for export to feed in Dispatch callback
const onUpdate = (fn?: any) => {
  walletConnector.on("session_update", (error, payload) => {
    if (error) {
      throw error;
    }
    console.log("updated!");
    // Get updated accounts and chainId
    const { accounts, chainId } = payload.params[0];
    fn(accounts, chainId);
  });
}

// Re-wrap for export to feed in Dispatch callback
const onDisconnect = (fn?: any) => {
  walletConnector.killSession();
}

const onSendTransaction = (tx: ITxData) => {
  const sendTransaction = () => {
    walletConnector.sendTransaction(tx)
      .then((result) => {
        // Returns transaction id (hash)
        console.log(result);
      })
      .catch((error) => {
        console.error(error);
        createNotification(Notification.error, error.message);
      })
  }

  if (!walletConnector.connected) {
    walletConnector.createSession()
      .then(() => {
        walletConnector.connect()
          .then(() => {
            sendTransaction();
          });
      })
  } else {
    sendTransaction();
  }
}


export { onConnect, onUpdate, onDisconnect, onSendTransaction };

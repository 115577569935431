import styled from 'styled-components';
import MarkerIconSvg from 'assets/images/icon-marker-filled.svg';

export const Row = styled.div`
  display: flex;
`;

export const MarkerIcon = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${MarkerIconSvg});
  width: 11px;
  height: 16px;
  margin-right: -8px;
`;

import {put, call, takeLatest} from 'redux-saga/effects';
import {AuthActionTypes, LoginResponse, SignupResponse} from '../types/auth';
import {AuthActions, AuthActionsUnion} from '../actions/auth';
import {PickAction} from '../helpers/redux';
import {createNotification, Notification} from 'shared/components/Notify';
import {UtilsActions} from '../actions/utils';
import Login from 'modules/Login';
import {TOKEN} from 'shared/constants/constants';
import {NftActions} from 'store/actions/nft';
import {UserActions} from 'store/actions/user';
import {onDisconnect} from 'shared/utils/walletConnector';
import adapter from '../helpers/adapter';

function* signupRequest(action: PickAction<AuthActionsUnion, AuthActionTypes.SIGNUP_REQUEST>): Generator {
  const {payload: data} = action;
  try {
    const response = yield call(adapter,'https://api.geonftapp.com/api/v1/auth/signup', {method: 'post'}, data);
    // const response = yield axios.post('https://api.geonftapp.com/api/v1/auth/signup', payload);
    const {
      data: {message},
    } = response as SignupResponse;
    yield put(UtilsActions.openModal({content: <Login />}));
    createNotification(Notification.success, message);
  } catch (err) {
    if (err?.response?.data?.message) {
      yield put(AuthActions.signupError({message: err.response.data.message}));
    } else {
      createNotification(Notification.error, err.message);
    }
  }
}

function* loginRequest(action: PickAction<AuthActionsUnion, AuthActionTypes.LOGIN_REQUEST>): Generator {
  const {payload: data} = action;
  try {
    // const response = yield axios.post('https://api.geonftapp.com/api/v1/auth/login', payload);
    const response = yield call(adapter,'https://api.geonftapp.com/api/v1/auth/login', {method: 'post'}, data);
    const {
      data: {jwtToken, tokenType},
    } = response as LoginResponse;
    localStorage.setItem(TOKEN, `${tokenType} ${jwtToken}`);
    yield put(AuthActions.setIsAuth(true));
    yield put(UserActions.getUser());
    yield put(UtilsActions.closeModal());
    createNotification(Notification.success);
  } catch (err) {
    createNotification(Notification.error, err.message);
  }
}

function* logout() {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem('users');
  yield put(NftActions.myNftResponse([]));
  yield put(UserActions.logout());
  onDisconnect();
}

function* watch(): Generator {
  yield takeLatest(AuthActionTypes.SIGNUP_REQUEST, signupRequest);
  yield takeLatest(AuthActionTypes.LOGIN_REQUEST, loginRequest);
  yield takeLatest(AuthActionTypes.LOGOUT, logout);
}

export default watch;

import {FC, ReactNode} from 'react';
import {ModalContainer} from 'shared/components/Modal';
import {BackButtonRow, IconRectangleVector, IconVector, IconVectorWrapper, StyledPopup} from './Popup.style';
import IconVectorPath from 'assets/images/icon-popup-vector.svg';
import IconVectorOutlinePath from 'assets/images/icon-popup-vector-outline.svg';
import IconRectanglePath from 'assets/images/icon-popup-rectangle-for-outline.svg';
import {useHeaderTheme} from 'shared/hooks/useHeaderTheme';
import {ReactComponent as BackButtonIcon} from 'assets/images/icon-popup-back.svg';
import {useDispatch} from 'react-redux';
import {UtilsActions} from 'store/actions/utils';
import ProfilePopup from 'modules/Profile/Popup/ProfilePopup';
import {AppColors} from "../../../../shared/constants/globalStyles";

type PopupProps = {
  children: ReactNode;
  isBackButton?: boolean;
};

const Popup: FC<PopupProps> = ({children, isBackButton}: PopupProps) => {
  const dispatch = useDispatch();
  const {isLight} = useHeaderTheme();

  const handleClickBack = () => {
    dispatch(UtilsActions.openModal({content: <ProfilePopup />}));
  };

  return (
    <StyledPopup style={isLight ? {border: `1px solid ${AppColors.gray}`} : {}}>
      <ModalContainer isIconClose={false} isCloseOutsideClick={true} style={{padding: 0, width: '100%'}}>
        <>
          {isBackButton ? (
            <BackButtonRow onClick={handleClickBack}>
              <BackButtonIcon />
            </BackButtonRow>
          ) : (
            <div />
          )}
          {children}
        </>
      </ModalContainer>
      {isLight ? (
        <IconVectorWrapper>
          <IconVector src={IconVectorOutlinePath} />
          <IconRectangleVector src={IconRectanglePath} />
        </IconVectorWrapper>
      ) : (
        <IconVector src={IconVectorPath} />
      )}
    </StyledPopup>
  );
};

export default Popup;

import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { InfoBlock, LinkItem } from "./Info.styles";

const Info: FC = () => {
  const { t } = useTranslation();

  const infoLinks = [{
    title: t('btn_map_help'),
    href: "/help",
  },{
    title: t('btn_map_faq'),
    href: "/faq",
  },{
    title: t('btn_map_privacy'),
    href: "/privacy",
  }];

  return (
    <InfoBlock>
      {infoLinks.map((item, index) =>
        <Link key={item.title + index} to={item.href}>
          <LinkItem>{item.title}</LinkItem>
        </Link>
      )}
    </InfoBlock>
  )
}

export default Info;

import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import * as UAParser from 'ua-parser-js';
import {ModalContainer} from 'shared/components/Modal';
import {Container, Description, DownloadButton, DownloadImg, LinkContainer, MainContainer} from './DownloadApp.style';
import AppStoreIcon from 'assets/images/Download/icon-app-store.svg';
import GooglePlayIcon from 'assets/images/Download/icon-google-play.svg';

const HIDE_DOWNLOAD_APP = 'hideDownloadApp';

const DownloadApp = () => {
  const userAgent = UAParser.UAParser(window.navigator.userAgent);
  const initialHideDownloadApp = JSON.parse(localStorage.getItem('hideDownloadApp'));
  const [hideDownloadApp, setHideDownloadApp] = useState<boolean>(initialHideDownloadApp);

  const onClose = () => {
    setHideDownloadApp(true);
    localStorage.setItem(HIDE_DOWNLOAD_APP, JSON.stringify(true));
  };

  if (userAgent.device.type !== 'mobile' || (userAgent.device.type === 'mobile' && hideDownloadApp)) {
    return null;
  }

  return (
    <MainContainer>
      <ModalContainer
        isIconClose
        onCloseModal={onClose}
        isCloseOutsideClick={false}
        style={{padding: '45px 20px 20px 20px', top: '-50px'}}
      >
        <Container>
          <Description>Для удобство использования скачайте наше мобильное приложение</Description>
          <LinkContainer>
            <DownloadButton>
              <DownloadImg src={AppStoreIcon} style={{marginRight: 15}} />
            </DownloadButton>
            <DownloadButton>
              <DownloadImg src={GooglePlayIcon} />
            </DownloadButton>
          </LinkContainer>
        </Container>
      </ModalContainer>
    </MainContainer>
  );
};

const container = window.document.body;
ReactDOM.createPortal(DownloadApp, container);

export default DownloadApp;

export enum WalletActionTypes {
  CREATE_WALLET_REQUEST = 'CREATE_WALLET_REQUEST',
  CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS',
  CREATE_WALLET_ERROR = 'CREATE_WALLET_ERROR',
  ADD_WALLET_REQUEST = 'ADD_WALLET_REQUEST',
  ADD_WALLET_SUCCESS = 'ADD_WALLET_SUCCESS',
  ADD_WALLET_ERROR = 'ADD_WALLET_ERROR',
  DELETE_WALLET_REQUEST = 'DELETE_WALLET_REQUEST',
  DELETE_WALLET_SUCCESS = 'DELETE_WALLET_SUCCESS',
  DELETE_WALLET_ERROR = 'DELETE_WALLET_ERROR',
  GET_WALLET_REQUEST = 'GET_WALLET_REQUEST',
  GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS',
  GET_WALLET_ERROR = 'GET_WALLET_ERROR',
  SET_ACTIVE_WALLET_ID = 'SET_ACTIVE_WALLET_ID',
  DELETE_MNEMONIC_FROM_STORE = 'DELETE_MNEMONIC_FROM_STORE',
  SET_MNEMONIC_PHRASE = 'SET_MNEMONIC_PHRASE',
  SET_WALLET_CONNECT_ACCOUNTS = 'SET_WALLET_CONNECT_ACCOUNTS',
  SET_WALLET_CONNECT_CHAIN_ID = 'SET_WALLET_CONNECT_CHAIN_ID',
}


export type WalletType = {
  id: number;
  platform: string;
  walletName: string;
  walletAddress: string;
};

export type GetWalletsResponse = WalletType[];

export type CreateWattetResponseType = {
  wallet: WalletType;
  mnemonic: string;
};

export type ImportWalletRequest = {
  name: string;
  address: string;
  walletPlatform?: string;
};

export enum AuthActionTypes {
  SIGNUP_REQUEST = 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
  SIGNUP_ERROR = 'SIGNUP_ERROR',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  SET_IS_AUTH = 'SET_IS_AUTH',
  LOGOUT = 'LOGOUT',
}

export type SignupRequestType = {
  name: string;
  login: string;
  password: string;
};

export type SignupErrorType = {
  message: string;
};

export type LoginRequestType = Omit<SignupRequestType, 'name'>;

export type SignupResponse = {
  data: {
    success: boolean;
    message: string;
  };
};
export type LoginResponse = {
  data: {
    jwtToken: string;
    tokenType: string;
  };
};

import { PublishNftResponseType } from './../types/nft';
import {
  CreateNftRequestType,
  SearchNftRequestType,
  NftActionTypes,
  SearchNftResponseType,
  NftType,
  FindNftRequest,
  FindNftResponse,
  PublishNftType,
  UploadFileRequestType,
  UploadFileResponseType,
} from 'store/types/nft';
import {Action, createAction, ActionsUnion} from 'store/helpers/redux';

export const NftActions = {
  searchNft: (payload: SearchNftRequestType): Action<NftActionTypes.SEARCH_NFT_REQUEST, SearchNftRequestType> =>
    createAction(NftActionTypes.SEARCH_NFT_REQUEST, payload),
  searchNftResponse: (
    payload: SearchNftResponseType,
  ): Action<NftActionTypes.SEARCH_NFT_SUCCESS, SearchNftResponseType> =>
    createAction(NftActionTypes.SEARCH_NFT_SUCCESS, payload),
  searchNftError: (): Action<NftActionTypes.SEARCH_NFT_ERROR> =>
    createAction(NftActionTypes.SEARCH_NFT_ERROR),
  myNft: (): Action<NftActionTypes.MY_NFT_REQUEST> => createAction(NftActionTypes.MY_NFT_REQUEST),
  myNftResponse: (payload: NftType[]): Action<NftActionTypes.MY_NFT_SUCCESS, NftType[]> =>
    createAction(NftActionTypes.MY_NFT_SUCCESS, payload),
  uploadFile: (payload: UploadFileRequestType): Action<NftActionTypes.UPLOAD_FILE_REQUEST, UploadFileRequestType> =>
    createAction(NftActionTypes.UPLOAD_FILE_REQUEST, payload),
  uploadFileResponse: (payload: UploadFileResponseType): Action<NftActionTypes.UPLOAD_FILE_SUCCESS, UploadFileResponseType> =>
    createAction(NftActionTypes.UPLOAD_FILE_SUCCESS, payload),
  createNft: (payload: CreateNftRequestType): Action<NftActionTypes.CREATE_NFT_REQUEST, CreateNftRequestType> =>
    createAction(NftActionTypes.CREATE_NFT_REQUEST, payload),
  createNftResponse: (payload: NftType): Action<NftActionTypes.CREATE_NFT_RESPONSE, NftType> =>
    createAction(NftActionTypes.CREATE_NFT_RESPONSE, payload),
  onSelectNftOnMap: (payload: NftType): Action<NftActionTypes.ON_SELECT_NFT_ON_MAP, NftType> =>
    createAction(NftActionTypes.ON_SELECT_NFT_ON_MAP, payload),
  findNft: (payload: FindNftRequest): Action<NftActionTypes.FIND_NFT_REQUEST, FindNftRequest> =>
    createAction(NftActionTypes.FIND_NFT_REQUEST, payload),
  findNftResponse: (payload: FindNftResponse): Action<NftActionTypes.FIND_NFT_SUCCESS, FindNftResponse> =>
    createAction(NftActionTypes.FIND_NFT_SUCCESS, payload),
  setHideNft: (payload: NftType): Action<NftActionTypes.HIDE_NFT_REQUEST, NftType> =>
    createAction(NftActionTypes.HIDE_NFT_REQUEST, payload),
  setHideNftResponse: (payload: NftType): Action<NftActionTypes.HIDE_NFT_SUCCESS, NftType> =>
    createAction(NftActionTypes.HIDE_NFT_SUCCESS, payload),
  publishNft: (payload: PublishNftType): Action<NftActionTypes.PUBLISH_NFT_REQUEST, PublishNftType> =>
    createAction(NftActionTypes.PUBLISH_NFT_REQUEST, payload),
  publishNftResponse: (payload: NftType): Action<NftActionTypes.PUBLISH_NFT_SUCCESS, NftType> =>
    createAction(NftActionTypes.PUBLISH_NFT_SUCCESS, payload),
  savePublishedNft: (payload: PublishNftResponseType): Action<NftActionTypes.PUBLISH_NFT_RESPONSE, PublishNftResponseType> =>
    createAction(NftActionTypes.PUBLISH_NFT_RESPONSE, payload),
  editNft: (payload: NftType): Action<NftActionTypes.EDIT_NFT_REQUEST, NftType> =>
    createAction(NftActionTypes.EDIT_NFT_REQUEST, payload),
  editNftResponse: (payload: NftType): Action<NftActionTypes.EDIT_NFT_SUCCESS, NftType> =>
    createAction(NftActionTypes.EDIT_NFT_SUCCESS, payload),
  onSelectClusterOnMap: (payload: NftType[]): Action<NftActionTypes.ON_SELECT_CLUSTER_ON_MAP, NftType[]> =>
    createAction(NftActionTypes.ON_SELECT_CLUSTER_ON_MAP, payload),
  getNft: (payload: number): Action<NftActionTypes.GET_NFT, number> =>
    createAction(NftActionTypes.GET_NFT, payload),
  getNftResponse: (): Action<NftActionTypes.GET_NFT_RESPONSE> =>
    createAction(NftActionTypes.GET_NFT_RESPONSE),
  getNftError: (): Action<NftActionTypes.GET_NFT_ERROR> =>
    createAction(NftActionTypes.GET_NFT_ERROR),
  resetErrorLoadedNft: (): Action<NftActionTypes.RESET_ERROR_LOADED_NFT> =>
    createAction(NftActionTypes.RESET_ERROR_LOADED_NFT),
};

export type NftActionsUnion = ActionsUnion<typeof NftActions>;

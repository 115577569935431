import {CSSProperties, FC, ReactElement, useRef} from 'react';
import {Container, CloseIcon} from './ModalContainer.style';
import {useOutsideClick} from 'shared/hooks';
import {ReactComponent as IconClose} from 'assets/images/icon-close.svg';
import {useDispatch, useSelector} from 'react-redux';
import {UtilsActions} from 'store/actions/utils';
import { AppState } from 'store/reducers/store';

type ModalContainerProps = {
  children?: ReactElement;
  isIconClose?: boolean;
  style?: CSSProperties;
  isCloseOutsideClick?: boolean;
  onCloseModal?: (e?: any) => void;
};

const ModalContainer: FC<ModalContainerProps> = ({
  children,
  isIconClose,
  style,
  isCloseOutsideClick = true,
  onCloseModal = () => { return },
}: ModalContainerProps) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const isOpenModal: boolean = useSelector((state: AppState) => state.utils?.modal?.content ? true : false);

  const onClose = () => {
    onCloseModal();
    dispatch(UtilsActions.closeModal());
  };

  useOutsideClick(ref, () => {
    if (isCloseOutsideClick) {
      onClose();
    }
  });

  onpopstate = function(event) {
    if (isOpenModal) {
      event.preventDefault();
      event.stopPropagation();
      onClose();
    }
  }

  return (
    <Container ref={ref} style={style}>
      {isIconClose && <CloseIcon onClick={onClose}><IconClose /></CloseIcon>}
      {children}
    </Container>
  );
};
export default ModalContainer;

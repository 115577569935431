import { Button } from 'shared/components';
import { AppBorders, AppColors, AppMedia } from 'shared/constants/globalStyles';
import styled from 'styled-components';
import AttachIcon from 'assets/images/icon-attach.svg';
import { ThemeObjType } from 'store/types/user';

export const CreateNftModalContainer = styled.div`
  width: 700px;
  max-width: 100vw;
  position: absolute;
  transform: translate(-50%, -5vh);
  bottom: -50vh;
  max-height: 55vh;
  overflow-y: auto;
  border-radius: 20px;
  @media (max-width: ${AppMedia.sm}) {
    transform: translate(-50%, -10vh);
    max-width: calc(100vw - 10px);
    max-height: 60vh;
    bottom: auto;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  width: calc(50% - 10px);
  @media (max-width: ${AppMedia.sm}) {
    width: 100%;
  }
`;

export const PreviewNftContainer = styled.div`
  background: ${AppColors.grayLight};
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
`;

export const PreviewNft = styled.div`
  background-color: ${AppColors.white};
  border: 1px dashed ${AppColors.gray};
  border-radius: 10px;
  height: 225px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & img[src=""] {
    display: none;
  }
`;

export const NftImage = styled.img`
  max-height: 225px;
  max-width: 100%;
  border-radius: 10px;
  background-color: ${AppColors.white};
  position: relative;
  z-index: 1;
`;

export const AddPhotoButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  border: ${AppBorders.grayLight};
  color: ${AppColors.white};
  white-space: nowrap;
  font-family: Roboto, serif;
  font-weight: 500;
  border-radius: 26px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 8px 20px;
  cursor: pointer;
  z-index: 10;
`;

export const AddPhotoButtonIcon = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${AttachIcon});
  width: 20px;
  height: 18px;
  margin-right: 11px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.textarea`
  background-color: ${AppColors.grayLight};
  border-radius: 30px;
  min-height: 90px;
  border: none;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 14px;
  box-sizing: border-box;
  resize: none;
`;

export const BuyNowButton = styled(Button)``;

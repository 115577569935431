import { FC, useState } from 'react';
import Loading from 'shared/components/Loading';
import {
  MarkerArrow,
  MarkerContainer,
  MarkerImg,
  MarkerName,
  MarkerOwnerAvatar,
  MarkerOwnerAvatarDefault,
  MarkerOwnerAvatarDefaultWrap,
} from './Map.style';
import IconVectorPath from 'assets/images/icon-marker-vector.svg';
import defaultProfileImg from 'assets/images/img-profile-default.svg';
import { useNavigate } from 'react-router';
import { IconEditNft } from 'shared/Icons';

type MarkerProps = {
  lat: number;
  lng: number;
  handleClickNft?: (nftId: number) => void;
  cluster: any;
};

const getAvatar = (cluster) => {
  if (cluster.publishId && cluster.userAvatar) {
    return <MarkerOwnerAvatar fileUrl={cluster.userAvatar} />;
  } else if (!cluster.publishId) {
    return (
      <MarkerOwnerAvatar>
        <IconEditNft />
      </MarkerOwnerAvatar>
    );
  } else {
    return (
      <MarkerOwnerAvatarDefaultWrap>
        <MarkerOwnerAvatarDefault src={defaultProfileImg} />
      </MarkerOwnerAvatarDefaultWrap>
    );
  }
};

const Marker: FC<MarkerProps> = ({ cluster, handleClickNft }: MarkerProps) => {
  const navigate = useNavigate();
  const [loadingImg, setLoadingImg] = useState(true);

  const handleClickMarker = (selectedNftId: number) => {
    handleClickNft(selectedNftId);
    navigate(`/nft/${selectedNftId}`);
  };

  const handleImageLoaded = () => {
    setLoadingImg(false);
  };

  return (
    <>
      <MarkerContainer onClick={() => handleClickMarker(cluster.nftId)}>
        {loadingImg && (
          <Loading
            style={{
              transform: 'scale(0.3)',
              position: 'absolute',
              left: '0',
              top: '-10px',
              zIndex: 1,
            }}
          />
        )}
        <MarkerImg
          src={`${cluster.fileUrl}-prev`}
          isMonochrome={!cluster.publishId}
          onLoad={handleImageLoaded}
        />
        {getAvatar(cluster)}
        {cluster.name ? <MarkerName>{cluster.name}</MarkerName> : <div />}
      </MarkerContainer>
      <MarkerArrow src={IconVectorPath} />
    </>
  );
};

export default Marker;

import {FC} from 'react';
import {Container, Description, Flex, Icon, ArrowRightIcon} from './PopupItem.style';
import arrowRight from 'assets/images/icon-arrow-right.svg';

type PopupItemProps = {
  text: string;
  iconSrc: string;
  onClick?: () => void;
};

const PopupItem: FC<PopupItemProps> = ({text, iconSrc, onClick}: PopupItemProps) => {
  return (
    <Container onClick={onClick}>
      <Icon src={iconSrc} />
      <Flex>
        <Description onClick={onClick}>{text}</Description>
        <ArrowRightIcon src={arrowRight} />
      </Flex>
    </Container>
  );
};
export default PopupItem;

import { AppBorders, AppColors, AppMedia } from 'shared/constants/globalStyles';
import styled from 'styled-components';

export const LikeButtonStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  margin-bottom: -14px;
  position: absolute;
  right: 100px;
  @media (max-width: ${AppMedia.sm}) {
    right: 28px;
  }
`;

export const LikeButtonStyledBorder = styled.div`
  display: flex;
  background: ${AppColors.darkGrey};
  border: ${AppBorders.strokeWhite};
  border-radius: 30px;
  margin: 2px 0;
`;

export const LikeButtonStyled = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  background: none;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CountLikedLabel = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${AppColors.white};
  margin-top: 2px;
`;

import { FC } from "react";
import { Container, Row, Title, TitleBold, Text, Divider, ListItem, BoldText, StyledList, StyledListItem } from "./Terms.style";

const Terms: FC = () => {
  return (
    <Container>
      <Row>
        <Title>Terms&nbsp;</Title><TitleBold>of use</TitleBold>
      </Row>
      <Text>Create a profile to subscribe to other accounts, withdraw your own video and get other features.</Text>
      <Divider />
      <Row>
        <ListItem>1</ListItem>
        <BoldText>Create a profile to subscribe</BoldText>
      </Row>
      <Text>
        Create a profile to subscribe to other accounts, withdraw your own video and get other features. Subscribe to other accounts, withdraw your own video and get other features.
      </Text>
      <Row>
        <ListItem>2</ListItem>
        <BoldText>Create a profile to subscribe</BoldText>
      </Row>
      <Text>Create a profile to subscribe to other accounts, withdraw your own video and get other features. Subscribe to other accounts, withdraw your own video and get other features.</Text>

      <BoldText style={{ marginTop: '26px' }}>June 2019</BoldText>
      <Text>
      This website is operated by Freepik Company, S.L., registered in the Commercial Registry of Málaga, volume 4994, sheet 217, page number MA-113059, with Tax Number B-93183366 and registered office at 13 Molina Lario St., 5th floor, 29015, Málaga, Spain (&rdquo;Company&rdquo;).
        <br />
        <br />
      This page contains important information about your legal rights and obligations when using any of the websites and services belonging to the Company. The following is a collection of the generally-applicable terms of use across our websites and services, whilst specific provisions are included in the terms of use of the relevant Company’s website or service and may be accessed via the links provided below:
      </Text>
      <StyledList>
        <StyledListItem>Flaticon Terms of Use</StyledListItem>
        <StyledListItem>Flaticon Terms of Use</StyledListItem>
        <StyledListItem>Flaticon Terms of Use</StyledListItem>
        <StyledListItem>Flaticon Terms of Use</StyledListItem>
      </StyledList>
      <Text>
        Should any conflict arise between the content of the terms as they are laid out below and the content of the specific terms of use of each Website or Service (displayed above), the specific terms of use of each Website or Service shall always prevail.
      </Text>
    </Container>
  )
}

export default Terms;

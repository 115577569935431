import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { CloseIcon, CustomInput, Container, MarkerIcon, SearchIcon } from './SearchInput.style';
import MarkerIconPath from 'assets/images/icon-marker.svg';
import SearchIconPath from 'assets/images/icon-search.svg';
import CloseIconPath from 'assets/images/icon-close.svg';
import { useNavigate, useLocation } from 'react-router';
import { ROUTES } from 'router/routes';
import { useDispatch, useSelector } from 'react-redux';
import { NftActions } from 'store/actions/nft';
import { useHeaderTheme } from 'shared/hooks/useHeaderTheme';
import { AppState } from 'store/reducers/store';
import { useTranslation } from 'react-i18next';
import './style.css';
import { useGoogleAutocomplete } from './useGoogleAutocomplete';
import { handleScriptLoad, loadScript } from './utils';
import { MapActions } from 'store/actions/map';
import {useOutsideClick} from "../../shared/hooks";

const ENTER_CODE = 13;

const SearchInput = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState('');
  const hasBenLoadedScript = useRef < boolean > (false);
  const dispatch = useDispatch();
  const { isLight } = useHeaderTheme();
  const listNft = useSelector((state: AppState) => state.nft.find.nftList);
  const autoCompleteRef = useRef(null);
  const { map, maps } = useSelector((state: AppState) => state.map);
  const searchInput = useRef(null);
  const [markers, setMarkers] = useState([]);

  const updateMarkers = useCallback((data) => setMarkers(data), []);
  const updateValue = useCallback((data) => setValue(data), []);

  useGoogleAutocomplete(map, maps, searchInput.current, updateValue, updateMarkers, markers);

  useEffect(() => {
    if (maps) {
      return maps.places.removeAllListeners();
    }
  }, []);

  const onChange = (e) => {
    const {
      target: { value },
    } = e;
    setValue(value);
    dispatch(NftActions.findNft({ text: value }));
  };

  const resetStateFoundedNfts = () => {
    setValue('');
    if (listNft.length) dispatch(NftActions.findNftResponse({ list: [], found: 0 }));
  };

  const onClear = () => {
    setValue('');
    navigate(ROUTES.main);
    resetStateFoundedNfts();
  };

  const onSearch = () => {
    navigate(ROUTES.search);
  };

  useEffect(() => {
    if (window.mapRef && !hasBenLoadedScript.current) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&language=en&libraries=places`,
        () =>
          handleScriptLoad(({ lat, lng, address }) => {
            setValue(address);
            dispatch(MapActions.onChangeMapCenter({ lat, lng }));
          }, autoCompleteRef),
      );
      hasBenLoadedScript.current = true;
    }
  }, [window.mapRef]);
  const onKeyPress = (e) => {
    if (e.charCode === ENTER_CODE && value) {
      navigate(ROUTES.search);
    }
  };

  useOutsideClick(searchInput, () => {
    if (value) {
      navigate(ROUTES.search);
    }
  });


  return (
    <Container isLight={isLight}>
      <MarkerIcon src={MarkerIconPath} />
      <CustomInput
        ref={searchInput}
        type="text"
        isLight={isLight}
        value={value}
        onKeyPress={(e) => onKeyPress(e)}
        onChange={(e) => onChange(e)}
        placeholder={t('txt_search_nft_here')}
      />
      {location.pathname === ROUTES.search ? (
        <CloseIcon src={CloseIconPath} onClick={onClear} />
      ) : (
        <SearchIcon src={SearchIconPath} onClick={onSearch} />
      )}
    </Container>
  );
};

export default memo(SearchInput);

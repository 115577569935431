
import {CSSProperties, FC} from 'react';

type IconEditNftProps = {
  fill?: string;
  style?: CSSProperties;
};

const IconEditNft: FC<IconEditNftProps> = ({fill}: IconEditNftProps) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="13" fill={fill} />
      <path d="M12.9395 8.76709C13.1784 10.3005 14.4229 11.4728 15.9674 11.6283" stroke="#F0F0F0" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.9846 11.6113L16.7291 10.8224C17.5181 9.98902 17.8736 9.03898 16.6458 7.87781C15.418 6.7222 14.4901 7.12778 13.7012 7.96115L9.13986 12.7891C8.96763 12.9725 8.80096 13.3336 8.76763 13.5836L8.56206 15.3837C8.48983 16.0337 8.95652 16.4782 9.601 16.3671L11.39 16.0615C11.64 16.0171 11.99 15.8337 12.1622 15.6448L13.7318 13.9475" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 18.1836H12.8613" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.334 18.1836H18.0007" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default IconEditNft;

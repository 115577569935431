import {NotificationManager} from 'react-notifications';

export enum Notification {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export const createNotification = (type: Notification, message?: string) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message);
      break;
    case 'success':
      NotificationManager.success(message, 'Success', 900);
      break;
    case 'warning':
      NotificationManager.warning(message, 'Warning');
      break;
    case 'error':
      NotificationManager.error(message, 'Error', 900);
      break;
  }
};

import styled from 'styled-components';
import {AppColors} from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

type ItemProps = {
  isActive: boolean;
};

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const PaginationIcon = styled.img``;

export const Item = styled.span.attrs((props: ItemProps) => {
  isActive: props.isActive;
})`
  width: 37px;
  height: 37px;
  background: ${({isActive}) => (isActive ? `${(props: { theme: ThemeObjType}) => props.theme.mainColor}` : `${AppColors.grayLight}`)};
  color: ${({isActive}) => (isActive ? `${AppColors.white}` : `${AppColors.textDefault}`)};
  border-radius: 10px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
	cursor: pointer;
  transition: .25s;
  :hover {
    background-color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
    color: ${AppColors.white};
    transition: .25s;
  }
`;

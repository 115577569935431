import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';
import IconCameraPath from 'assets/images/icon-camera.svg';
import IconEditPath from 'assets/images/icon-edit.svg';

type ImgProps = {src: string};

export const Div = styled.div``;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const NameContainer = styled.div`
  margin-top: 19px;
  margin-left: 18px;
`;

export const LoadingNameContainer = styled.div`
  transform: scale(0.4);
  margin-left: 40px;
  height: 22px;
  position: relative;
  bottom: 16px;
`;

export const Input = styled.input`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  border: none;
  padding: 0;
  color: ${AppColors.textDefault};
  :focus-visible {
    outline: none;
  }
  @media (max-width: ${AppMedia.sm}) {
    font-size: 20px;
  }
`;

export const Name = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${AppColors.textDefault};
  @media (max-width: ${AppMedia.sm}) {
    font-size: 20px;
  }
`;

export const IconCamera = styled.span`
  background-image: url(${IconCameraPath});
  background-repeat: no-repeat;
  background-position: center;
  width: 44px;
  height: 44px;
  position: absolute;
  cursor: pointer;
`;

export const Alias = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  color: ${AppColors.gray};
  margin-top: 3px;
  @media (max-width: ${AppMedia.sm}) {
    font-size: 13px;
  }
`;

export const ImgContainer = styled.div`
  position: relative;
  top: 3px;
`;

export const ImgSubstrate = styled.div`
  background: linear-gradient(215.93deg, #b02ad3 15.49%, #366ef9 83.29%);
  width: 68px;
  height: 68px;
  border-radius: 80%;
  position: absolute;
  top: 8px;
  left: 8px;
`;

export const ImgSubstrateBorder = styled.div`
  width: 84px;
  height: 84px;
  background: ${AppColors.grayLight};
  position: absolute;
  border-radius: 50%;
`;

export const LoadingContainer = styled.div`
  width: 83px;
  height: 73px;
  border-radius: 34px;
  position: absolute;
  transform: scale(0.4);
  transform-origin: top left;
  top: 3px;
  z-index: 4;
  left: 6px;
  background-color: #fff;
`;

export const InputUpload = styled.input`
  width: 43px;
  height: 41px;
  position: absolute;
  border-radius: 30px;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
`;

export const Img = styled.img`
  position: relative;
  background-repeat: no-repeat;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  margin: 9px 0 0 9px;
  background-color: ${AppColors.white};
`;

export const DefaultImg = styled.img`
  position: relative;
  width: 44px;
  height: 44px;
  top: 8px;
  right: -8px;
  background-color: #fff;
  border-radius: 50%;
  border: 11px solid #fff;
  max-height: 80px;
  overflow: hidden;
  margin: 1px 0 15px 1px;
`;

export const IconEdit = styled.div`
  background: url(${IconEditPath});
  margin-top: 23px;
  width: 17px;
  height: 20px;
  cursor: pointer;
`;

export const CheckIcon = styled.img`
  width: 18px;
  margin-top: 25px;
  cursor: pointer;
`;

import {all} from '@redux-saga/core/effects';
import nft from './nft';
import auth from './auth';
import map from './map';
import user from './user';
import wallet from './wallet';

function* staticSagas(): Generator {
  yield all([auth(), user(), nft(), map(), wallet()]);
}

export default staticSagas;

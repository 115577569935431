import Address from "modules/CreateNft/Address";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Loading from "shared/components/Loading";
import { ModalContainer } from "shared/components/Modal";
import { AppState } from "store/reducers/store";
import { NftType } from "store/types/nft";
import {
  ButtonsRow,
  BuyDate,
  Col,
  Container,
  EditIcon,
  HelperText,
  IconVector,
  InfoContainer,
  LoupeIcon,
  NftImage,
  NftName,
  NftNameCol,
  NftViewerRelativeContainer,
  Row,
  ShareIcon,
  StyledButton,
  TooltipText,
  UserAvatar,
  UserInfoCol,
  UserInfoRow,
  UserName,
  ViewerContainer
} from "./NftViewer.style";
import IconVectorPath from 'assets/images/icon-popup-vector.svg';
import { ReactComponent as LoupeIconPath } from 'assets/images/icon-loupe.svg';
import { UtilsActions } from "store/actions/utils";
import CreateNft from "modules/CreateNft";
import { MapActions } from "store/actions/map";
import { NftActions } from "store/actions/nft";
import { useTranslation } from 'react-i18next';
import './style.css';
import { AppColors } from "shared/constants/globalStyles";
import LikeButton from "./LikeButton";

type NftViewerItemProps = {
  nftItem: NftType;
}

const NftViewerItem: FC<NftViewerItemProps> = ({
  nftItem,
}: NftViewerItemProps) => {
  const [loaded, setLoaded] = useState(false);

  // const dispatch = useDispatch();
  // const { t } = useTranslation();
  // const navigate = useNavigate();
  // const shareTooltipRef = useRef < HTMLSpanElement > ();

  // const handleClickEditNft = (selectedNft: NftType) => {
  //   dispatch(NftActions.onSelectNftOnMap(selectedNft));
  //   dispatch(UtilsActions.openModal({ content: <CreateNft selectedNftId={Number(selectedNft.nftId)} /> }));
  //   dispatch(MapActions.onChangeNftAimState(true));
  // }

  // const handleClickViewingImg = (imgSrc: string) => {
  //   dispatch(UtilsActions.openImgViewer(imgSrc));
  // }

  // const onShare = () => {
  //   const copyhelper = document.createElement("input");
  //   copyhelper.className = 'copyhelper'
  //   document.body.appendChild(copyhelper);
  //   copyhelper.value = window.location.href;
  //   copyhelper.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(copyhelper);
  //   shareTooltipRef.current.innerHTML = t('btn_nft_card_share_tooltip_copied');
  // }

  // const onShareReset = () => {
  //   shareTooltipRef.current.innerHTML = t('btn_nft_card_share_tooltip');
  // }

  return (
    <NftViewerRelativeContainer>
      <NftImage
        src={nftItem.fileUrl}
        // ref={ref as any}
        onLoad={() => setLoaded(true)}
      />
      {loaded ?
        <InfoContainer>
          <LikeButton />
          <Address
            lat={Number(nftItem?.lat)}
            lng={Number(nftItem?.lng)}
            style={{ color: AppColors.white }}
          />
          <NftNameCol>
            <NftName>{nftItem?.name}</NftName>
          </NftNameCol>
          <HelperText>
            {nftItem?.description}
          </HelperText>
        </InfoContainer>
        : <Loading style={{ position: 'absolute' }}/>}
    </NftViewerRelativeContainer>
  )
}

export default NftViewerItem;

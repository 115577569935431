import { WalletType } from 'store/types/wallet';
import {Action, createAction, ActionsUnion} from 'store/helpers/redux';
import { GetWalletsResponse, WalletActionTypes, CreateWattetResponseType, ImportWalletRequest } from 'store/types/wallet';

export const WalletActions = {
  getWallets: (): Action<WalletActionTypes.GET_WALLET_REQUEST> =>
    createAction(WalletActionTypes.GET_WALLET_REQUEST),
  getWalletsResponse: (payload: GetWalletsResponse): Action<WalletActionTypes.GET_WALLET_SUCCESS, GetWalletsResponse> =>
    createAction(WalletActionTypes.GET_WALLET_SUCCESS, payload),
  setActiveWalletId: (payload: number): Action<WalletActionTypes.SET_ACTIVE_WALLET_ID, number> =>
    createAction(WalletActionTypes.SET_ACTIVE_WALLET_ID, payload),
  generateWallet: (): Action<WalletActionTypes.CREATE_WALLET_REQUEST> =>
    createAction(WalletActionTypes.CREATE_WALLET_REQUEST),
  generateWalletResponse: (payload: CreateWattetResponseType): Action<WalletActionTypes.CREATE_WALLET_SUCCESS, CreateWattetResponseType> =>
    createAction(WalletActionTypes.CREATE_WALLET_SUCCESS, payload),
  generateWalletError: (): Action<WalletActionTypes.CREATE_WALLET_ERROR> =>
    createAction(WalletActionTypes.CREATE_WALLET_ERROR),
  deleteMnemonic: (): Action<WalletActionTypes.DELETE_MNEMONIC_FROM_STORE> =>
    createAction(WalletActionTypes.DELETE_MNEMONIC_FROM_STORE),
  imoprtWallet: (payload: ImportWalletRequest): Action<WalletActionTypes.ADD_WALLET_REQUEST, ImportWalletRequest> =>
    createAction(WalletActionTypes.ADD_WALLET_REQUEST, payload),
  imoprtWalletSuccess: (payload: WalletType): Action<WalletActionTypes.ADD_WALLET_SUCCESS, WalletType> =>
    createAction(WalletActionTypes.ADD_WALLET_SUCCESS, payload),
  setWalletConnectAccounts: (payload: string[]): Action<WalletActionTypes.SET_WALLET_CONNECT_ACCOUNTS, string[]> =>
    createAction(WalletActionTypes.SET_WALLET_CONNECT_ACCOUNTS, payload),
  setWalletConnectChainId: (payload: string): Action<WalletActionTypes.SET_WALLET_CONNECT_CHAIN_ID, string> =>
    createAction(WalletActionTypes.SET_WALLET_CONNECT_CHAIN_ID, payload),
  setMnemonicPhrase: (payload: string): Action<WalletActionTypes.SET_MNEMONIC_PHRASE, string> =>
    createAction(WalletActionTypes.SET_MNEMONIC_PHRASE, payload),
};

export type WalletActionsUnion = ActionsUnion<typeof WalletActions>;

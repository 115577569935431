import {useEffect, useState} from 'react';
import {
  Container,
  Description,
  LoaderContainer,
  Title,
  DescriptionEmptyList,
  EmptyList,
  AttentionIcon,
  PaginationContainer,
  NftContainer,
} from './Search.style';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store/reducers/store';
import Loading from 'shared/components/Loading';
import NFTItem from './NFTItem';
import AttentionPath from 'assets/images/icon-attention.svg';
import Pagination from 'shared/components/Pagination';
import {PaginationConfigType} from 'shared/components/Pagination/utils';
import {NftActions} from 'store/actions/nft';
import { useTranslation } from 'react-i18next';

const Search = () => {
  const [paginationConfig, setPaginationConfig] = useState<PaginationConfigType>({
    currentPage: 0,
    limit: 0,
    quantityPages: 0,
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector((state: AppState) => state.nft.find.loading);
  const listNft = useSelector((state: AppState) => state.nft.find.nftList);
  const foundNfts = useSelector((state: AppState) => state.nft.find.found);
  const limit = useSelector((state: AppState) => state.nft.find.limit);
  const showPagination = foundNfts > limit;

  useEffect(() => {
    dispatch(NftActions.findNft({offset: paginationConfig.currentPage}));
  }, [paginationConfig.currentPage]);

  useEffect(() => {
    const calculatePages = Math.ceil(foundNfts / limit);
    setPaginationConfig({...paginationConfig, quantityPages: calculatePages, limit});
  }, [limit, foundNfts]);

  return (
    <Container>
      <Description>{t('txt_search_result')}</Description>
      <NftContainer>
        {loading && (
          <LoaderContainer>
            <Loading />
          </LoaderContainer>
        )}
        {!!listNft.length && listNft.map((nft) => <NFTItem key={nft.nftId} nft={nft} />)}
        {!listNft.length && !loading && (
          <EmptyList>
            <AttentionIcon src={AttentionPath} />
            <Title>{t('txt_search_empty1')} :(</Title>
            <DescriptionEmptyList>
              {t('txt_search_empty2')}
            </DescriptionEmptyList>
          </EmptyList>
        )}
        {showPagination && (
          <PaginationContainer>
            <Pagination paginationConfig={paginationConfig} updatePagination={setPaginationConfig} />
          </PaginationContainer>
        )}
      </NftContainer>
    </Container>
  );
};

export default Search;

import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "shared/components";
import { ModalContainer } from "shared/components/Modal";
import { useTranslation } from 'react-i18next';
import { WalletActions } from "store/actions/wallet";
import { AppState } from "store/reducers/store";
import { ButtonContainer, ModalTitle, StyledInput, StyledModal, WalletName } from "./MnemonicPhraseModal.style";

type MnemonicPhraseModalType = {
  onOk?: () => void;
}

const MnemonicPhraseModal: FC<MnemonicPhraseModalType> = ({ onOk }: MnemonicPhraseModalType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeWallet = useSelector((state: AppState) =>
    state.wallet.wallets.find((item) => item.id === state.wallet.activeWalletId)
  );
  const mnemonic = useSelector((state: AppState) => state.wallet.mnemonic);

  const onChangeMnemonic = (e) => {
    e.preventDefault();
    dispatch(WalletActions.setMnemonicPhrase(e.target.value));
  }

  const handleClickContinue = () => {
    onOk();
  }

  return (
    <StyledModal>
      <ModalContainer
        style={{ width: 'auto', padding: '21px 15px' }}
        isIconClose
      >
        <>
          <ModalTitle>{t('err_nft_publish_mnemonic')}</ModalTitle>
          <WalletName>
            <b>{activeWallet.walletName}</b>
            <br />
            {activeWallet.walletAddress}
          </WalletName>
          <StyledInput autoFocus onChange={onChangeMnemonic} value={mnemonic} />
          <ButtonContainer>
            <Button text={t('btn_nft_publish_continue')} onClick={handleClickContinue} />
          </ButtonContainer>
        </>
      </ModalContainer>
    </StyledModal>

  )
}

export default MnemonicPhraseModal;

import {OpenModalType, UtilsActionTypes} from 'store/types/utils';
import {Action, ActionsUnion, createAction} from 'store/helpers/redux';

export const UtilsActions = {
  openModal: (payload: OpenModalType): Action<UtilsActionTypes.OPEN_MODAL, OpenModalType> =>
    createAction(UtilsActionTypes.OPEN_MODAL, payload),
  closeModal: (): Action<UtilsActionTypes.CLOSE_MODAL> =>
    createAction(UtilsActionTypes.CLOSE_MODAL),
  openImgViewer: (payload: string): Action<UtilsActionTypes.OPEN_IMG_VIEWER, string> =>
    createAction(UtilsActionTypes.OPEN_IMG_VIEWER, payload),
  closeImgViewer: (): Action<UtilsActionTypes.CLOSE_IMG_VIEWER> =>
    createAction(UtilsActionTypes.CLOSE_IMG_VIEWER),
};

export type UtilsActionsUnion = ActionsUnion<typeof UtilsActions>;

import { ChangeEvent, FC, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { NftActions } from "store/actions/nft";
import {createNotification, Notification} from 'shared/components/Notify';
import { StyledFileUploader, UploaderContainer } from "./FileUploader.style";

export interface FileUploaderProps {
  children?: ReactNode;
  isOpacity: boolean;
}

const FileUploader: FC<FileUploaderProps> = ({children, isOpacity}) => {
  const dispatch = useDispatch();

  const handleClickUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file.size > 5242880) {
      createNotification(Notification.error, 'This file is more than 5 MB');
      return
    }
    dispatch(NftActions.uploadFile({file}));
  }

  return (
    <UploaderContainer isOpacity={isOpacity} onClick={(e) => { e.stopPropagation() }} >
      <input
        type="file"
        name='file'
        accept="image/*"
        multiple={false}
        max-size={10}
        onChange={(e) => {
          handleClickUploadFile(e);
          e.stopPropagation();
        }}
        onClick={(e: any) => {
          e.target.value = null;
          e.stopPropagation();
        }}
        onMouseDown={e => e.stopPropagation()}
      />
      <StyledFileUploader>
        {children}
      </StyledFileUploader>
    </UploaderContainer>
  )
}

export default FileUploader;

import { WalletActionTypes } from 'store/types/wallet';
import {INITIAL_STATE} from './initialState';
import {WalletState} from './declarations';

const wallet = (state = INITIAL_STATE, action): WalletState => {
  switch (action.type) {
    case WalletActionTypes.GET_WALLET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case WalletActionTypes.GET_WALLET_SUCCESS: {
      return {
        ...state,
        loading: false,
        wallets: action.payload,
      };
    }
    case WalletActionTypes.GET_WALLET_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case WalletActionTypes.SET_ACTIVE_WALLET_ID: {
      return {
        ...state,
        activeWalletId: action.payload,
      };
    }
    case WalletActionTypes.CREATE_WALLET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case WalletActionTypes.CREATE_WALLET_SUCCESS: {
      return {
        ...state,
        loading: false,
        wallets: [...state.wallets, action.payload.wallet],
        mnemonic: action.payload.mnemonic,
        activeWalletId: action.payload.id,
      };
    }
    case WalletActionTypes.DELETE_MNEMONIC_FROM_STORE: {
      return {
        ...state,
        mnemonic: '',
      };
    }
    case WalletActionTypes.SET_MNEMONIC_PHRASE: {
      return {
        ...state,
        mnemonic: action.payload,
      };
    }
    case WalletActionTypes.CREATE_WALLET_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case WalletActionTypes.ADD_WALLET_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case WalletActionTypes.ADD_WALLET_SUCCESS: {
      return {
        ...state,
        loading: false,
        wallets: [...state.wallets, action.payload],
        activeWalletId: action.payload.id,
      };
    }
    case WalletActionTypes.SET_WALLET_CONNECT_ACCOUNTS: {
      return {
        ...state,
        walletConnectAccounts: action.payload,
      };
    }
    case WalletActionTypes.SET_WALLET_CONNECT_CHAIN_ID: {
      return {
        ...state,
        walletConnectChainId: action.payload,
      };
    }


    default:
      return state;
  }
};

export default wallet;

import {FC} from 'react';
import {useSelector} from 'react-redux';
import Loading from 'shared/components/Loading';
import {AppState} from 'store/reducers/store';
import {Loader, LoaderContainer, LogoImg} from './MapLoader.style';
import Logo from 'assets/images/logo.png';
import {AppColors} from 'shared/constants/globalStyles';

type MapLoaderProps = {
  onClick: () => void;
};

export const MapLoader: FC<MapLoaderProps> = ({onClick}: MapLoaderProps) => {
  const {loader} = useSelector((state: AppState) => state.map);

  return (
    <LoaderContainer onClick={onClick}>
      {loader ? (
        <Loading style={{transform: 'scale(.5)', position: 'absolute', background: `${AppColors.strokeWhite}`, borderRadius: '50%'}} />
      ) : (
        <LogoImg src={Logo} />
      )}
      <Loader />
    </LoaderContainer>
  );
};

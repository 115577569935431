import styled from 'styled-components';
import {AppColors} from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

export const Title = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${AppColors.textDefault};
  text-align: center;
  margin: 0 0 20px 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.textDefault};
  margin-left: 20px;
  margin-bottom: 2px;
`;

export const Input = styled.input`
  background-color: ${AppColors.grayLight};
  border-radius: 30px;
  height: 48px;
  border: none;
  margin-bottom: 10px;
	padding: 20px;
	font-size: 14px;
	box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;
`;

export const Link = styled.button`
  color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
  text-decoration: none;
	border: none;
	font-size: 15px;
	line-height: 18px;
	font-weight: 500;
	background-color: transparent;
	cursor: pointer;
`;

export const Footer = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${AppColors.darkGrey};
  margin: 60px 0 0 0;
`;

export const HelpText = styled.p`
  margin: 0;
  padding: 0 15px;
  font-size: 14px;
  opacity: 0.6;
  color: red;
`

export const HelpTextContainer = styled.div`
	height: 30px;
`

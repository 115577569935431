declare type AnyFunction = (...args: any[]) => any;

export type ActionsUnion<A extends Record<string, AnyFunction>> = ReturnType<A[keyof A]>;

export type PickAction<T, U> = T extends { type: U } ? T : never;

export type Action<T, P = {}> = {type: T; payload?: P};

export function createAction<T extends string, P = {}>(type: T, payload?: P): Action<T, P> {
  return {type, payload};
}
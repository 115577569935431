import {FC} from 'react';
import {Item, PaginationContainer, PaginationIcon} from './Pagination.style';
import PaginationLeftIcon from 'assets/images/icon-pagination-arrow-left.svg';
import PaginationRightIcon from 'assets/images/icon-pagination-arrow-right.svg';
import {PaginationProps} from "./utils";

const Pagination: FC<PaginationProps> = ({paginationConfig, updatePagination}) => {
  const {quantityPages, currentPage} = paginationConfig;

  const onNext = () => {
    if (currentPage === (quantityPages - 1)) return;
    updatePagination({...paginationConfig, currentPage: currentPage + 1});
  };

  const onPrev = () => {
    if (currentPage === 0) return;
    updatePagination({...paginationConfig, currentPage: currentPage - 1});
  };

  const goToPage = (index: number) => {
    updatePagination({...paginationConfig, currentPage: index});
  };

  return (
    <PaginationContainer>
      <Item onClick={onPrev}>
        <PaginationIcon src={PaginationLeftIcon} />
      </Item>
      {Array.from({length: quantityPages}).map((_, index) => (
        <Item isActive={currentPage === index} key={index} onClick={() => goToPage(index)}>
          {index + 1}
        </Item>
      ))}
      <Item onClick={onNext}>
        <PaginationIcon src={PaginationRightIcon} />
      </Item>
    </PaginationContainer>
  );
};

export default Pagination;

import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';

export const StyledPopup = styled.div`
  width: 355px;
  height: auto;
  overflow-y: auto;
  background: ${AppColors.white};
  border-radius: 20px;
  position: absolute;
  right: calc(-50vw + 20px);
  top: calc(-50vh + 76px);
  padding: 16px 25px;
  z-index: 3;
  min-height: 600px;
  max-height: calc(100vh - 110px);
  //overflow: auto;
  @media (max-width: ${AppMedia.sm}) {
    box-sizing: border-box;
    max-width: calc(100vw - 10px);
    padding: 16px;
    right: calc(-50vw + 10px);
    min-height: 560px;
  }
`;

export const BackButtonRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
  margin: 20px 0 0 20px;
`;

export const IconRectangleVector = styled.img`
  top: -2px;
  z-index: 110;
  height: 3px;
  width: 9px;
  left: 23px;
  position: absolute;
  @media (max-width: ${AppMedia.md}) {
  	right: 45px;
		left: auto;
	}
`;
export const IconVector = styled.img`
  width: 12px;
  height: 8px;
  position: absolute;
  top: -8px;
  right: 197px;
  @media (max-width: ${AppMedia.md}) {
    right: 43px;
  }
`;

export const IconVectorWrapper = styled.div``;

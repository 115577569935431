import {ChangeEvent, FC, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AuthActions} from 'store/actions/auth';
import {ModalContainer} from 'shared/components/Modal';
import {Input, InputContainer, Label, Title, ButtonContainer, Footer, HelpText, Link, HelpTextContainer} from './Signup.style';
import Button from 'components/Button';
import {hasOneCapitalLatter, hasOneLowerCaseLatter, hasOneNumber} from './utils';
import {UtilsActions} from 'store/actions/utils';
import Login from '../Login';
import {AppState} from 'store/reducers/store';
import { useTranslation } from 'react-i18next';

type SignupProps = {};

const MIN_LENGTH = 6;
const MAX_LENGTH = 128;

const Signup: FC<SignupProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const {message} = useSelector((state: AppState) => state.auth.signup);

  const isValidPassword = useMemo(() => {
    return (
      password.length >= MIN_LENGTH &&
      password.length <= MAX_LENGTH &&
      hasOneCapitalLatter(password) &&
      hasOneNumber(password) &&
      hasOneLowerCaseLatter(password)
    );
  }, [password]);

  const onLogin = () => {
    dispatch(UtilsActions.openModal({content: <Login />}));
  };

  const onSignup = () => {
    if (!isValidPassword) return;
    dispatch(AuthActions.signup({name, login, password}));
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onChangeLogin = (e: ChangeEvent<HTMLInputElement>) => {
    setLogin(e.target.value);
    if (message) dispatch(AuthActions.signupError({message: ''}));
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <ModalContainer isIconClose style={{width: '370px'}}>
      <>
        <Title>{t('txt_signup_title')}</Title>
        <InputContainer>
          <Label>{t('txt_registration_caption_username')}</Label>
          <Input value={name} onChange={onChangeName} />
        </InputContainer>
        <InputContainer>
          <Label>{t('txt_login_caption_login')}</Label>
          <Input value={login} onChange={onChangeLogin} />
        </InputContainer>
        <InputContainer>
          <Label>{t('txt_login_caption_password')}</Label>
          <Input value={password} onChange={onChangePassword} />
        </InputContainer>
        <HelpTextContainer>
          {!isValidPassword && !message && (
            <HelpText>{t('err_login_password_web')}</HelpText>
          )}
          <HelpText>{!!message && message}</HelpText>
        </HelpTextContainer>
        <ButtonContainer>
          <Button
            variant="contained"
            onClick={onSignup}
            style={{padding: '11px 55px', margin: 'auto', border: '8px solid #F0F0F0'}}
            disabled={!name || !login || !isValidPassword}
          >
            {t('btn_login_signup')}
          </Button>
        </ButtonContainer>
        <Footer>
          {t('txt_auth_already_have_account_web')}&nbsp;
          <Link onClick={onLogin}>{t('btn_auth_enter_here')}</Link>
        </Footer>
      </>
    </ModalContainer>
  );
};
export default Signup;

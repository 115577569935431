import {AppColors} from 'shared/constants/globalStyles';
import {FC, CSSProperties, ReactNode} from 'react';
import styled from 'styled-components';
import { ThemeObjType } from 'store/types/user';

type BtnProps = {variant: string};

type ButtonVariant = 'text' | 'contained' | 'outlined';

export const StyledButton = styled.button.attrs((props: BtnProps) => ({
  variant: props.variant,
}))`
  background: ${(props) => (props.variant === 'contained' ? `${(props: { theme: ThemeObjType}) => props.theme.mainColor}` : `${AppColors.white}`)};
  border: ${(props) => (props.variant !== 'text' ? `1px solid ${(props: { theme: ThemeObjType}) => props.theme.mainColor}` : 'none')};
  border-radius: 30px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  color: ${(props) => (props.variant === 'contained' ? `${AppColors.white}` : `${(props: { theme: ThemeObjType}) => props.theme.mainColor}`)};
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  :hover {
    background: ${AppColors.white};
    color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
    cursor: pointer;
  }
  :disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: auto;
    border: none;
  }
`;

export interface ButtonProps {
  variant?: ButtonVariant;
  children?: ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({children, variant = 'filled', onClick, style, disabled}) => {
  return (
    <StyledButton variant={variant} onClick={onClick} style={style} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

export default Button;

import { FC } from "react";
import { CountLikedLabel, LikeButtonStyled, LikeButtonStyledBorder, LikeButtonStyledContainer } from "./LikeButton.style";
import IconLikeButton from "shared/Icons/IconLikeButton";
import { useSelector } from "react-redux";
import { AppState } from "store/reducers/store";
import { AppColors } from "shared/constants/globalStyles";

type LikeButtonProps = {
  handleClickLikeButton: () => void;
  isLiked: boolean;
  likedCount: number;
}

export const LikeButton: FC<LikeButtonProps> = ({
  handleClickLikeButton,
  isLiked,
  likedCount,
}: LikeButtonProps) => {
  const { theme } = useSelector((state: AppState) => state.user);

  return(
    <LikeButtonStyledContainer>
      <LikeButtonStyledBorder>
        <LikeButtonStyled onClick={handleClickLikeButton}>
          <IconLikeButton stroke={isLiked ? theme.mainColor : AppColors.white} />
        </LikeButtonStyled>
      </LikeButtonStyledBorder>
      <CountLikedLabel>
        {likedCount ? likedCount : ''}
      </CountLikedLabel>
    </LikeButtonStyledContainer>

  )
}

export default LikeButton;

import {combineReducers} from 'redux';
import {WalletState} from './wallet/declarations';
import {MapState} from './map/declarations';
import {AuthState} from './auth/declarations';
import {UserState} from './user/declarations';
import {UtilsState} from './utils/declarations';
import {NftState} from './nft/declarations';
import auth from './auth';
import user from './user';
import utils from './utils';
import nft from './nft';
import map from './map';
import wallet from './wallet';

export type AppState = {
  auth: AuthState;
  user: UserState;
  nft: NftState;
  map: MapState;
  utils: UtilsState;
  wallet: WalletState;
};

const rootReducer = combineReducers({
  auth,
  user,
  utils,
  nft,
  map,
  wallet,
});

export default rootReducer;

import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalContainer } from "shared/components/Modal";
import { IconVector, Container, MapTypeTitle, Row, MapTypeItem } from "./SelectMapTypeModal.style";
import IconVectorPath from 'assets/images/icon-popup-vector.svg';
import { AppState } from "store/reducers/store";
import { MapActions } from "store/actions/map";
import { useTranslation } from 'react-i18next';

type SelectMapTypeModal = {
  onCloseSelectModal: () => void;
}

const SelectMapTypeModal: FC<SelectMapTypeModal> = ({ onCloseSelectModal }: SelectMapTypeModal) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedMapType } = useSelector((state: AppState) => state.map);


  const mapTypes = [{
    name: t('btn_map_change_scheme'),
    mapTypeId: 'roadmap',
  },{
    name: t('btn_map_change_satellite'),
    mapTypeId: 'satellite',
  },{
    name: t('btn_map_change_hybrid'),
    mapTypeId: 'hybrid',
  }]

  const onCloseModal = () => {
    onCloseSelectModal();
  }

  const handleSelectMapType = (mapType: string) => {
    dispatch(MapActions.onChangeMapType(mapType));
  }

  return (
    <Container>
      <ModalContainer
        style={{ width: 'auto', padding: '21px 15px' }}
        onCloseModal={onCloseModal}
      >
        <>
          <MapTypeTitle>{t('txt_map_change_map_type_view')}</MapTypeTitle>
          <Row>
            {mapTypes.map((item, index) =>
              <MapTypeItem
                key={`${item.mapTypeId}-${index}`}
                isSelected={selectedMapType === item.mapTypeId ? true : false}
                onClick={() => handleSelectMapType(item.mapTypeId)}
              >
                {item.name}
              </MapTypeItem>
            )}
          </Row>
        </>
      </ModalContainer>
      <IconVector src={IconVectorPath} />
    </Container>
  )
}

export default SelectMapTypeModal;

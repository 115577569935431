import { AppBorders, AppColors, AppMedia } from 'shared/constants/globalStyles';
import styled from 'styled-components';
import IconMapPlus from 'assets/images/icon-map-plus.svg';
import IconMapMinus from 'assets/images/icon-map-minus.svg';
import IconMapType from 'assets/images/icon-select-map-type.svg';
import { ThemeObjType } from 'store/types/user';

type MapTypeButtonProps = {
  isActiveButton: boolean;
}

export const MapOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 28px;
  top: calc(50vh - 90px);
  margin: auto 0;
  @media (max-width: ${AppMedia.sm}) {
    right: 8px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${AppColors.darkGrey};
  border: ${AppBorders.strokeWhite};
  border-radius: 30px;
  margin: 2px 0;
  button {
    :active {
      background-color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
    }
  }
`;

export const ZoomButton = styled.button`
  cursor: pointer;
  height: 50px;
  width: 40px;
  background: none;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ZoomButtonPlus = styled(ZoomButton)`
  background-image: url(${IconMapPlus});
  border-radius: 25px 25px 0 0;
`;

export const ZoomButtonMinus = styled(ZoomButton)`
  background-image: url(${IconMapMinus});
  border-radius: 0 0 25px 25px;
`;

export const GeoButton = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  background: none;
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MapTypeButton = styled.button<MapTypeButtonProps>`
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
  background: none;
  background: ${props => props.isActiveButton ? (props: { theme: ThemeObjType}) => props.theme.mainColor : AppColors.darkGrey};
  background-image: url(${IconMapType});
  border: none;
  border-radius: 25px;
  background-repeat: no-repeat;
  background-position: center;
`;

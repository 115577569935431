import {CSSProperties, FC} from 'react';

type IconArrowDownProps = {
  fill?: string;
  style?: CSSProperties;
};

const IconArrowDown: FC<IconArrowDownProps> = ({fill, style}: IconArrowDownProps) => {
  return (
    <svg width="6" height="6" viewBox="0 0 6 6" fill={fill} style={style} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.83468 4.73501C3.43943 5.33402 2.56057 5.33402 2.16533 4.73501L0.559124 2.30074C0.120432 1.63589 0.597252 0.75 1.3938 0.75L4.6062 0.75C5.40275 0.75 5.87957 1.63589 5.44088 2.30074L3.83468 4.73501Z"
      />
    </svg>
  );
};

export default IconArrowDown;

import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';

type Props = {
  isLight: boolean;
};

export const Container = styled.div.attrs((props: Props) => {
  isLight: props.isLight;
})`
  box-sizing: border-box;
  padding: 20px 5px;
  background-color: ${AppColors.white};
  border-radius: 20px;
  top: 76px;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  width: calc(100vw - 30px);
  height: calc(100vh - 150px);
  margin: 0;
  z-index: 2;
  @media (max-width: ${AppMedia.sm}) {
    width: calc(100vw - 10px);
    padding: 30px 15px 15px 15px;
    height: calc(100vh - 90px);
    overflow-y: auto;
  }
`;

export const NFTs = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  overflow: auto;
  max-height: calc(100% - 60px);
  @media (max-width: ${AppMedia.lg}) {
  }
`;

export const LoadingContainer = styled.div`
  width: calc(100vw - 27px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

export const PaginationContainer = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const EmptyList = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 265px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const AttentionIcon = styled.img`
  width: 62px;
  height: 62px;
  margin-bottom: 30px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${AppColors.textDefault};
  margin: 0 0 5px 0;
`;
export const DescriptionEmptyList = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin: 0;
  color: ${AppColors.textDefault};
`;

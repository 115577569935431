import { FC } from "react";
import { PageTitle } from "../CreateWallet/CreateWallet.style";
import Popup from "../Popup";
import { BackButtonRow } from "../Popup/Popup.style";
import {ReactComponent as BackButtonIcon} from 'assets/images/icon-popup-back.svg';
import { useDispatch, useSelector } from "react-redux";
import { UtilsActions } from "store/actions/utils";
import ProfilePopup from "../ProfilePopup";
import { AppState } from "store/reducers/store";
import { AccountItem, AccountsContainer, ButtonContainer, Col, DotContainer, DotDisabled, DotLinearActive, UserLogin, UserName } from "./AddAccount.style";
import { Button } from "shared/components";
import AddAccountModal from "./AddAccountModal";
import { UserActions } from "store/actions/user";
import { useTranslation } from 'react-i18next';

const AddAccount: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userAccounts, user } = useSelector((state: AppState) => state.user);

  const handleClickBack = () => {
    dispatch(UtilsActions.openModal({content: <ProfilePopup />}));
  }

  const handleClickAddAccount = () => {
    dispatch(UtilsActions.openModal({content: <AddAccountModal />}));
  }

  const handleClickAccount = (id: number) => {
    const selectedAccount = userAccounts.find((item) => item.id === id);
    dispatch(UserActions.setUser(selectedAccount));
  }

  return (
    <Popup>
      <BackButtonRow onClick={handleClickBack}>
        <BackButtonIcon />
      </BackButtonRow>
      <PageTitle>{t('txt_my_accounts_title')}</PageTitle>
      <AccountsContainer>
        {userAccounts.map((item, index) => {
          return (
            <AccountItem key={`account-${item.id}-${index}`} onClick={() => handleClickAccount(item.id)}>
              {item.id === user.id
                ? <DotContainer>
                  <DotLinearActive />
                </DotContainer>
                : <DotDisabled />
              }
              <Col>
                <UserName>{item.name}</UserName>
                <UserLogin>{item.login}{item.login}</UserLogin>
              </Col>
            </AccountItem>
          )
        })}
      </AccountsContainer>
      <ButtonContainer>
        <Button text={t('btn_add_new_account')} onClick={handleClickAddAccount} />
      </ButtonContainer>
    </Popup>
  )
}

export default AddAccount;

import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';

export const Container = styled.div`
  background-color: ${AppColors.white};
  margin: 87px 25px 0 25px;
	@media(max-width: ${AppMedia.md}) {
    margin: 138px 25px 0 25px;
		text-align: center;
  }
`;

export const NftContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${AppColors.textDefault};
  margin: 0 0 20px 0;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

export const EmptyList = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 265px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const AttentionIcon = styled.img`
  width: 62px;
  height: 62px;
  margin-bottom: 30px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: ${AppColors.textDefault};
  margin: 0 0 5px 0;
`;
export const DescriptionEmptyList = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin: 0;
  color: ${AppColors.textDefault};
`;

export const PaginationContainer = styled.p`
  position: absolute;
  bottom: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

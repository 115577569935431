import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'store/reducers/store';
import {ModalWrapper} from './Modal.style';

type ModalContainerProps = {};

const Modal: FC<ModalContainerProps> = () => {
  const {content} = useSelector((state: AppState) => state.utils?.modal);

  if (!content) return null;

  return <ModalWrapper id="modal">{content}</ModalWrapper>;
};
export default Modal;

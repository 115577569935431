import { FC } from "react";
import Loading from "shared/components/Loading";
import { ClusterCircleOne, ClusterCircleThree, ClusterCircleTwo, ClusterContainer, ClusterCounts, ClusterPoint, ClusterSubContainer, MarkerArrow, PreviewMarkerImg } from "./Map.style";
import IconVectorPath from 'assets/images/icon-marker-vector.svg';
import { useDispatch } from "react-redux";
import { NftActions } from "store/actions/nft";
import NftViewer from "modules/NftViewer";
import { UtilsActions } from "store/actions/utils";
import { MapActions } from "store/actions/map";


type ClusterProps = {
  cluster: any;
  clusterLeavs: any;
  pointCount: number;
  lat: number;
  lng: number;
  zoom: number;
  onChangeMapZoom: (zoom: number) => void;
  onChangeMapCenter: (lat: number, lng: number) => void;
  mapRef: any;
  aimRef: any;
}

const Cluster: FC<ClusterProps> = ({
  lat,
  lng,
  cluster,
  clusterLeavs,
  pointCount,
  zoom,
  mapRef,
  aimRef,
  onChangeMapCenter,
  onChangeMapZoom,
}: ClusterProps) => {
  const dispatch = useDispatch();

  const handleClickCluster = () => {
    // onChangeMapCenter(lat, lng);
    // onChangeMapZoom(zoom + 2);
    dispatch(NftActions.onSelectClusterOnMap(clusterLeavs));
    dispatch(UtilsActions.openModal({content: <NftViewer />}));
    dispatch(MapActions.onChangeNftAimState(true));
    const marker = new google.maps.Marker({
      position: { lat: lat, lng: lng },
      map: mapRef.current,
      title: "",
      icon: "",
    });
    const overlay = new google.maps.OverlayView();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    overlay.draw = function() {};
    overlay.setMap(mapRef?.current as any);

    const proj = overlay.getProjection();
    const pos = marker.getPosition();
    const markerPosition = proj.fromLatLngToContainerPixel(pos);
    const { x, y } = (aimRef?.current as any).getBoundingClientRect();
    (mapRef.current as any).panBy(markerPosition.x - x, markerPosition.y - y);
    marker.setMap(null);
  }

  return (
    <>
      <ClusterContainer
        className="cluster-marker"
        onClick={handleClickCluster}
      >
        {clusterLeavs.map((clusterLeaf: { properties: { crimeId: number; }; fileUrl: string; publishId: number }, index: number) =>
          index < 3 || clusterLeavs.length <= 3
            ? <ClusterSubContainer key={`cluster-${cluster.id}-crime-${clusterLeaf.properties.crimeId}-${index}`}>
              <Loading
                style={{
                  transform: 'scale(0.2)',
                  position: 'absolute',
                  left: `${index * 38 - 16}px`,
                  top: '-20px',
                  zIndex: '-1',
                }}
              />
              <PreviewMarkerImg
                // onError={(e) => { (e.target as any).style.display = 'none' }}
                src={`${clusterLeaf.fileUrl}-prev`}
                isMonochrome={clusterLeaf.publishId ? false : true}
              />
            </ClusterSubContainer>
            : <div key={`${cluster.id}-crime-${clusterLeaf.properties.crimeId}-${index}`} />
        )}
        {clusterLeavs.length <= 3
          ? <></>
          : <ClusterCounts>+{pointCount - 3}</ClusterCounts>
        }
      </ClusterContainer>
      <MarkerArrow src={IconVectorPath} />
      <ClusterCircleThree>
        <ClusterCircleTwo width={clusterLeavs.length > 8 ? 184 : 120}>
          <ClusterCircleOne>
            <ClusterPoint />
          </ClusterCircleOne>
        </ClusterCircleTwo>
      </ClusterCircleThree>
    </>
  )
}

export default Cluster;

import styled from 'styled-components';

type UploaderContainerProps = {
  isOpacity: boolean;
}

export const UploaderContainer = styled.label<UploaderContainerProps>`
  position: absolute;
  opacity: ${props => props.isOpacity ? 0 : 1};
  width: 100%;
  max-width: 350px;
  height: 100%;
  max-height: 200px;
  transition: 0.5s;
  z-index: 5;
  :hover {
    opacity: ${props => props.isOpacity ? 0.7 : 1};;
  }
  & input[type="file"] {
      display: none;
  }
`;

export const StyledFileUploader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { AppColors } from 'shared/constants/globalStyles';
import styled from 'styled-components';

export const PresentationContainer = styled.div`
  width: calc(100vw);
  height: calc(100vh);
  padding-top: 32;
  display: flex;
  align-items: center;
  background: ${AppColors.fullDark};
  .carousel-root {
    display: block;
    justify-content: center;
    align-items: center;
    .thumbs-wrapper {
      display: none;
    }
    .carousel-status {
      display: none;
    }
  }
  .carousel-slider {
    /* padding: 100px 100px 50px 100px; */
    /* width: calc(100vw - 200px);
    height: calc(100vh - 150px); */
    .control-dots {
      position: fixed;
      bottom: 24px;
    }
  }
  .control-arrow {
    width: 70px;
  }
`;

export const StyledSlideItem = styled.img`
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 150px);
  object-fit: contain;
`;

export const PresentationContainerMobile = styled.div`
  position: fixed;
  width: calc(100vw);
  height: calc(100vh - 130px);
  display: flex;
  align-items: flex-start;
  background: ${AppColors.fullDark};
  padding: 130px 0;
  overflow: auto;
`;

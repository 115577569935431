import {ReactElement} from 'react';

export enum UtilsActionTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  OPEN_IMG_VIEWER = 'OPEN_IMG_VIEWER',
  CLOSE_IMG_VIEWER = 'CLOSE_IMG_VIEWER',
}

export type OpenModalType = {
  content: ReactElement;
  onClose?: () => void;
};

import styled from 'styled-components';
import {AppColors, AppMedia} from 'shared/constants/globalStyles';

type ContainerProps = {
  isLight: boolean;
}

export const Container = styled.div.attrs((props: ContainerProps) => {
  isLight: props.isLight;
})`
  width: 355px;
  height: auto;
  background: #fff;
  box-shadow:${({isLight}) => (isLight ? 'none' : '6px 34px 12px rgba(0, 0, 0, 0.4)')};
	border: ${({isLight}) => (isLight ? `1px solid ${AppColors.gray}` : 'none')};
  border-radius: 20px;
  position: absolute;
  top: 76px;
  right: 20px;
  padding: 16px;
	z-index: 2;
  @media (max-width: ${AppMedia.sm}) {
		box-sizing: border-box;
		width: calc(100vw - 10px);
		left: 5px;
  }
`;

export const Release = styled.p`
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: ${AppColors.textDefault};
  margin-top: 15px;
  margin-left: 2px;
`;

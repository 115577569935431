import {Button} from 'shared/components';
import walletIcon from 'assets/images/icon-wallet.svg';
import billIcon from 'assets/images/icon-bill.svg';
import {
  Description,
  Icon,
  Container,
  Flex,
  DotLinearActive,
  DotDisabled,
  DotContainer,
  DescriptionText,
  Hash,
  TextContainer,
  BillIcon,
  WalletContainer,
  HelperText,
} from './Wallet.style';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect } from 'react';
import { WalletActions } from 'store/actions/wallet';
import { AppState } from 'store/reducers/store';
import { UtilsActions } from 'store/actions/utils';
import CreateWallet from 'modules/Profile/Popup/CreateWallet';
import { useTranslation } from 'react-i18next';

const Wallet: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {isAuth} = useSelector((state: AppState) => state.auth);
  const {wallets, activeWalletId} = useSelector((state: AppState) => state.wallet);

  useEffect(() => {
    if (!wallets.length) {
      dispatch(WalletActions.getWallets());
    }
  }, [])

  const handleSelectWallet = (walletId: number) => {
    dispatch(WalletActions.setActiveWalletId(walletId));
  }

  const handleClickAddWallet = () => {
    dispatch(UtilsActions.openModal({content: <CreateWallet />}));
  }

  if (!isAuth) {
    return(
      <div />
    )
  }

  return (
    <WalletContainer>
      <Container>
        <Icon src={walletIcon} />
        <Flex>
          <Description>{t('btn_profile_select_crypto_wallet')}</Description>
          {wallets.length ? <Button text={t('btn_profile_add_new_wallet')} onClick={handleClickAddWallet} /> : <div />}
        </Flex>
      </Container>
      {!wallets.length
        ? <>
          <HelperText>
            {t('txt_profile_wallets_empty')}
          </HelperText>
          <Button
            style={{ margin: 'auto' }}
            text={t('btn_profile_add_new_wallet')}
            onClick={handleClickAddWallet}
          />
        </>
        : <div />
      }
      {wallets.map((item, index) => {
        return(
          <Container
            key={`wallet-${item.id}-${index}`}
            onClick={() => handleSelectWallet(item.id)}
          >
            {activeWalletId === item.id
              ? <DotContainer>
                <DotLinearActive />
              </DotContainer>
              : <DotDisabled />
            }
            <Flex>
              <TextContainer>
                <DescriptionText>{item.walletName}</DescriptionText>
                <Hash>{item.walletAddress}</Hash>
              </TextContainer>
              <BillIcon src={billIcon} onClick={() => {navigator.clipboard.writeText(item.walletAddress)}} />
            </Flex>
          </Container>
        )
      })}
    </WalletContainer>
  );
};
export default Wallet;

export enum AppColors {
  violGrad = 'linear-gradient(215.93deg, #B02AD3 15.49%, #366EF9 83.29%)',
  fullDark = '#000000',
  darkGrey = '#111111',
  textDefault = '#565656',
  gray = '#A4A4A4',
  gray1 = '#C1C1C1',
  grayLight = '#F0F0F0',
  white = '#FFFFFF',
  strokeWhite = 'rgba(255, 255, 255, 0.15)',
  red = '#FF1744',
  pink = '#FA17FF',
  violett = '#6F17FF',
  cyan = '#1758FF',
  blue = '#17ACFF',
  lightBlue = '#17F1FF',
  green = '#17FF82',
  lightGreen = '#B5FF17',
  yellow = '#FFBE17',
  orange = '#FF7917',
};

export enum AppShadows {
  darkShadow = '6px 34px 12px rgba(0, 0, 0, 0.4)',
}

export enum AppMedia {
  xs = '320px',
  sm = '600px',
  md = '960px',
  lg = '1280px',
  xl = '1920px',
}

export enum AppBorders {
  strokeWhite = '8px solid rgba(255, 255, 255, 0.15)',
  grayLight = '8px solid #F0F0F0',
}

import {FC} from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import googleMapStyles from "./map_style.json";
import { SearchNftResponseType } from "store/types/nft";
import { AddNftButtonContainer, AddNftButtonIcon, HorisontalLine, MapContainer, NftAim, NftAimPoint, VerticalLine } from "./Map.style";
import { GeolocationButton, SelectedMapTypeButton, ZoomButton } from "./MapOptions/MapOptions";
import { MapOptionsContainer } from "./MapOptions/MapOptions.style";
import { Button } from "shared/components";
import Marker from "./Marker";
import Cluster from "./Cluster";
import Info from "./Info";
import { MapLoader } from "./MapOptions/MapLoader";
import { useTranslation } from 'react-i18next';
import {MapActions} from "store/actions/map";
import {useDispatch} from "react-redux";


type MapProps = {
  isAuth: boolean;
  aimRef: any;
  mapRef: any;
  onChange: (e: {zoom: number, bounds: any}) => void;
  onChangeMapZoom: (zoom: number) => void;
  nftList: SearchNftResponseType;
  bounds: Array<number>;
  zoom: number;
  center: { lat: number, lng: number };
  selectedMapType: string;
  handleAddNft: () => void;
  isNftAimState: boolean;
  isDisableScroll: boolean;
  handleClickNft: (nftId: number) => void;
  onChangeMapCenter: (lat: number, lng: number) => void;
  handleClickMapLoader: () => void;
};

const Map: FC<MapProps> = ({
  onChange, onChangeMapZoom, nftList, bounds, zoom, handleAddNft, isNftAimState, isAuth, handleClickNft, center, onChangeMapCenter, selectedMapType, isDisableScroll, aimRef, mapRef, handleClickMapLoader,
}: MapProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const points = nftList.map(crime => ({
    type: "Feature",
    properties: { cluster: false, crimeId: crime.nftId },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(crime.lng),
        parseFloat(crime.lat)
      ]
    },
    ...crime,
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 150, maxZoom: 20 }
  });

  return (
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY , libraries: ['places']}}
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => {
          mapRef.current = map;
          dispatch(MapActions.onLoadMap({map, maps}))
        }}
        onChange={onChange}
        options={{
          styles: googleMapStyles,
          scaleControl: false,
          zoomControl: false,
          mapTypeId: selectedMapType,
          clickableIcons: false,
          scrollwheel: !isDisableScroll,
          gestureHandling: 'greedy',
          fullscreenControl: false,
          disableDefaultUI: true,
        }}
      >
        {clusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;
          if (isCluster) {
            const clusterLeavs = supercluster.getLeaves(cluster.id);
            return (
              <Cluster
                key={`cluster-${cluster.id}-${index}`}
                lat={latitude}
                lng={longitude}
                clusterLeavs={clusterLeavs}
                cluster={cluster}
                pointCount={pointCount}
                zoom={zoom}
                mapRef={mapRef}
                aimRef={aimRef}
                onChangeMapZoom={onChangeMapZoom}
                onChangeMapCenter={onChangeMapCenter}
              />
            );
          } else {
            return (
              <Marker
                key={`crime-${cluster.properties.crimeId}`}
                lat={latitude}
                lng={longitude}
                handleClickNft={handleClickNft}
                cluster={cluster}
              />
            );
          }
        })}
      </GoogleMapReact>

      <NftAim ref={aimRef} style={{ opacity: isNftAimState ? '1' : '0' }}>
        <VerticalLine />
        <HorisontalLine />
        <NftAimPoint />
      </NftAim>

      <MapOptionsContainer>
        <SelectedMapTypeButton />
        <ZoomButton zoom={zoom} onChangeMapZoom={onChangeMapZoom} />
        <GeolocationButton setCenter={onChangeMapCenter} />
      </MapOptionsContainer>

      {isAuth
        ? <AddNftButtonContainer>
          <Button
            icon={<AddNftButtonIcon />}
            text={t('btn_map_add_nft')}
            style={{border: 'none', fontWeight: 400}}
            onClick={handleAddNft}
          />
        </AddNftButtonContainer>
        : <div />
      }

      <MapLoader onClick={handleClickMapLoader} />

      <Info />

    </MapContainer>
  );
}

export default Map;

import { AppColors } from "shared/constants/globalStyles";
import styled from "styled-components";

export const AccountsContainer = styled.div`

`;

export const AccountItem = styled.div`
  display: flex;
  width: 100%;
  margin: 12px 0;
  cursor: pointer;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 27px;
`;

export const DotDisabled = styled.div`
  width: 22px;
  height: 22px;
  background: ${AppColors.gray1};
  border: 5px solid ${AppColors.grayLight};
  border-radius: 20px;
  cursor: pointer;
`;

export const DotLinearActive = styled.div`
  background: ${AppColors.white};
  width: 14px;
  height: 14px;
  border-radius: 20px;
`;

export const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: linear-gradient(215.93deg, #b02ad3 15.49%, #366ef9 83.29%);
  border: 5px solid ${AppColors.grayLight};
  border-radius: 20px;
  cursor: pointer;
`;

export const UserName = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${AppColors.fullDark};
`;

export const UserLogin = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${AppColors.textDefault};
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
`;

const initialZoom = Number(localStorage.getItem('map_zoom'));
const initialTopLeftLat = Number(localStorage.getItem('map_coordinates_topLeftLat'));
const initialTopLeftLng = Number(localStorage.getItem('map_coordinates_topLeftLng'));
const initialBotRightLat = Number(localStorage.getItem('map_coordinates_botRightLat'));
const initialBotRightLng = Number(localStorage.getItem('map_coordinates_botRightLng'));
const initialMapCenterLat = Number(localStorage.getItem('map_coordinates_center_lat'));
const initialMapCenterLng = Number(localStorage.getItem('map_coordinates_center_lng'));

export const INITIAL_STATE = {
  topLeftLat: initialTopLeftLat || 51.797721761270026,
  topLeftLng: initialTopLeftLng || -0.7640356624023354,
  botRightLat: initialBotRightLat || 51.22982624373364,
  botRightLng: initialBotRightLng || 0.49321226240232363,
  zoom: initialZoom || 13,
  center: { lat: initialMapCenterLat || 51.514659, lng: initialMapCenterLng || -0.1354117 },
  isNftAimState: false,
  selectedMapType: 'roadmap',
  loader: false,
  map: null,
  maps: null,
};

import {
  Route, Routes, useLocation,
  useNavigate,
  useParams
} from 'react-router';
import Main from 'pages/Main';
import Search from 'pages/Search';
import Terms from 'pages/Terms';
import { ROUTES } from './routes';
import OAuth2RedirectHandler from 'shared/components/OAuth2RedirectHandler';
import { TOKEN } from 'shared/constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from 'store/actions/auth';
import { AppState } from "store/reducers/store";
import Presentation from 'pages/Presentation';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const RoutesMap = withRouter(() => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state: AppState) => state.auth.isAuth);
  const isToken = localStorage.getItem(TOKEN);

  if (isToken && !isAuth) {
    dispatch(AuthActions.setIsAuth(true));
  }

  return (
    <Routes>
      <Route path={ROUTES.search} element={<Search />} />
      <Route path={ROUTES.terms} element={<Terms />} />
      <Route path={ROUTES.presentation} element={<Presentation />} />
      <Route path={ROUTES.oAuth} element={<OAuth2RedirectHandler />} />
      <Route path={ROUTES.nft} element={<Main />} />
      <Route path={ROUTES.main} element={<Main />} />
    </Routes>
  );
});

export default RoutesMap;

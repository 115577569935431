import {CSSProperties, FC, useEffect, useRef, useState} from 'react';
import {Container, CloseIcon, StyledImg, ClickDisabler} from './ImgViewer.style';
import closeIcon from 'assets/images/icon-close.svg';
import {useDispatch, useSelector} from 'react-redux';
import {UtilsActions} from 'store/actions/utils';
import { AppState } from 'store/reducers/store';
import Loading from '../Loading';

type ImgViewerProps = {
  style?: CSSProperties;
  onCloseViewer?: (e?: any) => void;
};

const ImgViewer: FC<ImgViewerProps> = ({
  style,
  onCloseViewer = () => { return },
}: ImgViewerProps) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [loadingImg, setLoadingImg] = useState(true);

  const { imgViewer } = useSelector((state: AppState) => state.utils);

  useEffect(() => {
    setLoadingImg(true);
  }, [imgViewer])

  const onClose = () => {
    onCloseViewer();
    dispatch(UtilsActions.closeImgViewer());
  };

  const handleLoadingImg = () => {
    setLoadingImg(false);
  }

  const handleClick = (e?: any) => {
    if (!e.target.parentNode.closest('#img-viewer-container')) {
      onClose();
    }
  }


  if (!imgViewer) return null;

  return (
    <ClickDisabler onClickCapture={handleClick} id="img-viewer-container">
      <Container ref={ref} style={style} id="img-viewer">
        <CloseIcon src={closeIcon} onClick={onClose} />
        <StyledImg src={imgViewer} onLoad={handleLoadingImg} />
        {loadingImg && <Loading style={{ position: 'absolute', transform: 'scale(1.5)' }} />}
      </Container>
    </ClickDisabler>

  );
};
export default ImgViewer;

export enum NftActionTypes {
  SEARCH_NFT_REQUEST = 'SEARCH_NFT_REQUEST',
  SEARCH_NFT_SUCCESS = 'SEARCH_NFT_SUCCESS',
  SEARCH_NFT_ERROR = 'SEARCH_NFT_ERROR',
  MY_NFT_REQUEST = 'MY_NFT_REQUEST',
  MY_NFT_SUCCESS = 'MY_NFT_SUCCESS',
  MY_NFT_ERROR = 'MY_NFT_ERROR',
  UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR',
  UPLOAD_FILE_RESPONSE = 'UPLOAD_FILE_RESPONSE',
  CREATE_NFT_REQUEST = 'CREATE_NFT_REQUEST',
  CREATE_NFT_SUCCESS = 'CREATE_NFT_SUCCESS',
  CREATE_NFT_ERROR = 'CREATE_NFT_ERROR',
  CREATE_NFT_RESPONSE = 'CREATE_NFT_RESPONSE',
  CLEAR_LAST_UPLOADED_FILE = 'CLEAR_LAST_UPLOADED_FILE',
  ON_SELECT_NFT_ON_MAP = 'ON_SELECT_NFT_ON_MAP',
  ON_SELECT_CLUSTER_ON_MAP = 'ON_SELECT_CLUSTER_ON_MAP',
  FIND_NFT_REQUEST = 'FIND_NFT_REQUEST',
  FIND_NFT_SUCCESS = 'FIND_NFT_SUCCESS',
  HIDE_NFT_REQUEST = 'HIDE_NFT_REQUEST',
  HIDE_NFT_SUCCESS = 'HIDE_NFT_SUCCESS',
  HIDE_NFT_ERROR = 'HIDE_NFT_ERROR',
  PUBLISH_NFT_REQUEST = 'PUBLISH_NFT_REQUEST',
  PUBLISH_NFT_SUCCESS = 'PUBLISH_NFT_SUCCESS',
  PUBLISH_NFT_RESPONSE = 'PUBLISH_NFT_RESPONSE',
  EDIT_NFT_REQUEST = 'EDIT_NFT_REQUEST',
  EDIT_NFT_SUCCESS = 'EDIT_NFT_SUCCESS',
  EDIT_NFT_ERROR = 'EDIT_NFT_ERROR',
  GET_NFT = 'GET_NFT',
  GET_NFT_RESPONSE = 'GET_NFT_RESPONSE',
  GET_NFT_ERROR = 'GET_NFT_ERROR',
  RESET_ERROR_LOADED_NFT = 'RESET_ERROR_LOADED_NFT',
}

export type SearchNftRequestType = {
  topLeftLat: number;
  topLeftLng: number;
  botRightLat: number;
  botRightLng: number;
};

export type NftType = {
  attributes: string;
  blockchain: string;
  description: string;
  fileIpfs: string;
  fileUrl: string;
  lat: string;
  lng: string;
  name: string;
  nftId: string;
  fileHash?: string;
  createTime?: string;
  userId?: string;
  userName?: string;
  userAvatar?: string;
  isHide?: boolean;
  publishTime?: string;
};

export type SearchNftResponseType = NftType[];

export type UploadFileRequestType = {
  file: File;
};

export type UploadFileApiResponseType = {
  data: {
    bucket: string;
    fullName: string;
    hash: string;
    name: string;
  };
};

export type UploadFileResponseType = {
  bucket: string;
  fullName: string;
  hash: string;
  name: string;
};

export type CreateNftRequestType = {
  nftId?: number;
  userId: string;
  userAvatar: string;
  publishTime: string;
  isHide?: boolean;
};

export type FindType = {
  loading: boolean;
  nftList: NftType[];
  found: number;
  text: string;
  limit: number;
  offset: number;
};

export type FindNftRequest = {
  text?: string;
  limit?: number;
  offset?: number;
};

export type FindNftResponse = {
  list: NftType[];
  found: number;
};

export type NftHideRequest = {
  nftId: number;
  lat: number;
  lng: number;
  fileUrl: string;
  fileIpfs: string;
  fileHash: string;
  name: string;
  description: string;
  attributes: string;
  blockchain: string;
  isHide: boolean;
};

export type NftHideResponse = NftType;

export type PublishNftType = {
  nftId: string;
  walletId: number;
  isLocalWallet?: boolean,
  mnemonic?: string,
};

export type PublishNftResponseType = {
  addressContract?: string;
  txData?: string;
  value?: string;
  txHash?: string;
};

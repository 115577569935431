import { FC, ReactNode } from "react"
import { useSelector } from "react-redux";
import { AppState } from "store/reducers/store";
import { ThemeProvider } from "styled-components"

export interface AppThemeProviderProps {
  children?: ReactNode;
}

const AppThemeProvider: FC<AppThemeProviderProps> = ({children}: AppThemeProviderProps) => {
  const { theme } = useSelector((state: AppState) => state.user);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default AppThemeProvider;

export enum UserActionTypes {
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  RENAME_USER_REQUEST = 'RENAME_USER_REQUEST',
  RENAME_USER_SUCCESS = 'RENAME_USER_SUCCESS',
  UPLOAD_AVATAR_REQUEST = 'UPLOAD_AVATAR_REQUEST',
  UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS',
  CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR',
  USER_INFO_DELETE = 'USER_INFO_DELETE',
  CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR',
}

export type UserData = {
  email: string;
  id: number;
  imageUrl: string;
  login: string;
  name: string;
  token?: string;
  activeWalletId?: number;
};

export type GetUserResponse = {
  data: UserData;
};

export type ChangeUser = Partial<UserData>;

export type ChangePasswordRequest = {
  password: string;
  oldPassword: string;
};

export type ThemeObjType = {
  mainColor: string;
  name?: string;
}

import styled from 'styled-components';
import {AppColors} from 'shared/constants/globalStyles';
import { ThemeObjType } from 'store/types/user';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${AppColors.darkGrey};
	cursor: pointer;
	:hover {
		color: ${(props: { theme: ThemeObjType}) => props.theme.mainColor};
	}
`;

export const Icon = styled.img`
  margin-right: 15px;
`;

export const ArrowRightIcon = styled.img`
`;

import { FC } from 'react';
import {
  NftContainer,
  Container,
  NftImg,
  TitleContainer,
  Description,
  Title,
  Address,
  Coordinates,
  EllipsisIcon,
  Flex,
  Border,
  ArrowRightIcon,
  LoadingContainer,
  LoupeIcon,
} from './NFTItem.style';
import EllipsisIconPath from 'assets/images/icon-ellipsis.svg';
import ArrowRightIconPath from 'assets/images/icon-arrow-right.svg';
import LoupeIconPath from 'assets/images/icon-loupe.svg';
import { NftType } from 'store/types/nft';
import { UtilsActions } from 'store/actions/utils';
import { useDispatch } from 'react-redux';
import { NftActions } from 'store/actions/nft';
import NftViewer from 'modules/NftViewer';
import { MapActions } from 'store/actions/map';
import { ROUTES } from 'router/routes';
import { useNavigate } from 'react-router';

type NFTItemProps = {
  nft: NftType;
};

const NFTItem: FC<NFTItemProps> = ({ nft }: NFTItemProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickLupe = (e) => {
    e.stopPropagation();
    dispatch(UtilsActions.openImgViewer(nft.fileUrl));
  };

  const onGoToNft = () => {
    navigate(`nft/${nft.nftId}`);
    // dispatch(NftActions.onSelectClusterOnMap([nft]));
    // dispatch(UtilsActions.openModal({content: <NftViewer />}));
    // dispatch(MapActions.onChangeNftAimState(true));
  };

  return (
    <NftContainer onClick={onGoToNft}>
      <Container>
        <LoadingContainer>
          <NftImg src={nft.fileUrl} />
          <LoupeIcon src={LoupeIconPath} onClick={onClickLupe} />
        </LoadingContainer>
        <TitleContainer>
          <Title>{nft.name}</Title>
          {/* <Address>London Baker street 221b</Address> */}
          <Flex>
            <Coordinates>
              {nft.lat}, {nft.lng}
            </Coordinates>
            <EllipsisIcon src={EllipsisIconPath} />
          </Flex>
        </TitleContainer>
      </Container>
      <Description>{nft.description}</Description>
      <Border />
      <ArrowRightIcon src={ArrowRightIconPath} />
    </NftContainer>
  );
};
export default NFTItem;

import {MapActionTypes, OnChangeMapZoom, OnChangeCoordinates, OnChangeMapCenter, OnLoadMap} from 'store/types/map';
import {Action, createAction, ActionsUnion} from 'store/helpers/redux';

export const MapActions = {
  onLoadMap: (payload: OnLoadMap): Action<MapActionTypes.ON_LOAD_MAP, OnLoadMap> =>
    createAction(MapActionTypes.ON_LOAD_MAP, payload),
  onChangeMapZoom: (payload: OnChangeMapZoom): Action<MapActionTypes.ON_CHANGE_MAP_ZOOM, OnChangeMapZoom> =>
    createAction(MapActionTypes.ON_CHANGE_MAP_ZOOM, payload),
  onChangeCoordinates: (payload: OnChangeCoordinates): Action<MapActionTypes.ON_CHANGE_COORDINATES, OnChangeCoordinates> =>
    createAction(MapActionTypes.ON_CHANGE_COORDINATES, payload),
  onChangeNftAimState: (payload: boolean): Action<MapActionTypes.ON_CHANGE_NFT_AIM_STATE, boolean> =>
    createAction(MapActionTypes.ON_CHANGE_NFT_AIM_STATE, payload),
  onChangeMapCenter: (payload: OnChangeMapCenter): Action<MapActionTypes.ON_CHANGE_MAP_CENTER, OnChangeMapCenter> =>
    createAction(MapActionTypes.ON_CHANGE_MAP_CENTER, payload),
  onChangeMapType: (payload: string): Action<MapActionTypes.ON_CHANGE_MAP_TYPE, string> =>
    createAction(MapActionTypes.ON_CHANGE_MAP_TYPE, payload),
  addMapLoader: (): Action<MapActionTypes.ADD_MAP_LOADER> =>
    createAction(MapActionTypes.ADD_MAP_LOADER),
  deleteMapLoader: (): Action<MapActionTypes.DELETE_MAP_LOADER> =>
    createAction(MapActionTypes.DELETE_MAP_LOADER),
};

export type MapActionsUnion = ActionsUnion<typeof MapActions>;

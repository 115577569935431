import {FC, useEffect, useState} from 'react';
import FileUploader from 'modules/CreateNft/FileUploader';
import Input from 'shared/components/Input';
import Label from 'shared/components/Label';
import {ModalContainer} from 'shared/components/Modal';
import Loading from 'shared/components/Loading';
import {
  AddPhotoButton,
  AddPhotoButtonIcon,
  UpdateButton,
  Description,
  InputContainer,
  PreviewNft,
  PreviewNftContainer,
  NftImage,
} from './EditNft.style';
import {useDispatch, useSelector} from 'react-redux';
import {NftActions} from 'store/actions/nft';
import {AppState} from 'store/reducers/store';
import {NftType} from 'store/types/nft';
import Address from './Address';
import {UtilsActions} from 'store/actions/utils';
import { useTranslation } from 'react-i18next';

type EditNftProps = {
  nft: NftType;
};

const EditNft: FC<EditNftProps> = ({nft}: EditNftProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [updatedNft, setUpdatedNft] = useState(nft);
  const {lastUploadedFileData, loadingFile} = useSelector((store: AppState) => store.nft);
  const nftImagePath = lastUploadedFileData.fullName
    ? `https://api.geonftapp.com/api/v1/files/${lastUploadedFileData.fullName}`
    : nft?.fileUrl;

  useEffect(() => {
    setUpdatedNft({...updatedNft, fileUrl: `https://api.geonftapp.com/api/v1/files/${lastUploadedFileData.fullName}`});
  }, [lastUploadedFileData]);

  const onSave = () => {
    dispatch(NftActions.editNft(updatedNft));
  };

  const onChange = (key: string, value: string) => {
    setUpdatedNft({...updatedNft, [key]: value});
  };

  const onClose = () => {
    dispatch(UtilsActions.closeModal());
  };

  return (
    <ModalContainer
      isIconClose
      isCloseOutsideClick={false}
      onCloseModal={onClose}
      style={{
        minWidth: '366px',
        border: '1px solid #000',
        height: 'calc(100vh - 50px)',
        overflow: 'auto',
        maxHeight: '700px',
      }}
    >
      <>
        <Address lat={Number(nft.lat)} lng={Number(nft.lng)} />
        <PreviewNftContainer>
          <PreviewNft>
            <NftImage src={nftImagePath} />
            {loadingFile ? (
              <Loading style={{position: 'absolute', zIndex: 10}} />
            ) : (
              <FileUploader isOpacity={!!(lastUploadedFileData.fullName || updatedNft?.fileUrl)}>
                <AddPhotoButton>
                  <AddPhotoButtonIcon />
                  {t('btn_map_add_nft')}
                </AddPhotoButton>
              </FileUploader>
            )}
          </PreviewNft>
        </PreviewNftContainer>
        <InputContainer>
          <Label>Title</Label>
          <Input onChange={(e) => onChange('name', e.target.value)} value={updatedNft.name} />
        </InputContainer>
        <InputContainer>
          <Label>Description</Label>
          <Description onChange={(e) => onChange('description', e.target.value)} value={updatedNft.description} />
        </InputContainer>
        <UpdateButton
          text={'Update'}
          style={{margin: '51px auto 0 auto', fontWeight: 500}}
          onClick={onSave}
          disabled={!updatedNft.name || !updatedNft.description}
        />
      </>
    </ModalContainer>
  );
};

export default EditNft;

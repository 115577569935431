export const useGoogleAutocomplete = (map, maps, searchInput, updateAddress, updateMarkers, markers) => {
  if (!map || !maps || !searchInput) return;

  const searchBox = new maps.places.SearchBox(searchInput);

  map.addListener('bounds_changed', () => {
    searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
  });

  const handler = () => {
    const places = searchBox.getPlaces();

    if (places.length == 0) return;

    updateAddress(places.formatted_address);

    // Clear out the old markers.
    markers.forEach((marker) => {
      marker.setMap(null);
    });

    updateMarkers([]);

    // For each place, get the icon, name and location.
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        console.log('Returned place contains no geometry');
        return;
      }

      const icon = {
        url: place.icon as string,
        size: new maps.Size(71, 71),
        origin: new maps.Point(0, 0),
        anchor: new maps.Point(17, 34),
        scaledSize: new maps.Size(25, 25),
      };

      // Create a marker for each place.
      markers.push(
        new maps.Marker({
          map,
          icon,
          title: place.name,
          position: place.geometry.location,
        }),
      );

      updateMarkers((prev) => prev.concat(markers));

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  }
  searchBox.addListener('places_changed', handler);
};

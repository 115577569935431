import { AppColors } from 'shared/constants/globalStyles';
import styled from 'styled-components';

const Input = styled.input`
  background-color: ${AppColors.grayLight};
  border-radius: 30px;
  height: 48px;
  border: none;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 14px;
  box-sizing: border-box;
`;

export default Input;

export enum HeaderTheme {
  light = 'light',
  dark = 'dark',
}

export const TOKEN = 'token';

export enum WalletPlatforms {
  local = 'INTERNAL_ETH',
  imported = 'ETHEREUM'
}
